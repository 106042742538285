import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { VisibilityOutlined } from '@material-ui/icons';
import { Button } from '@castiron/components';
import { kebabCase, toQueryString, useTracking } from '@castiron/utils';
import { createOpenShopButtonClickedEvent } from '../../../lib/events/commonEvents';
import { useAppSelector } from '../../../hooks';

type Props = {
  onClick?: () => void;
  addQueryParams?: any;
  subdirectory?: string;
  size?: 'small' | null;
  text?: string;
  variant?: 'text' | 'outlined' | 'contained';
  eventTextOverride?: string;
  buttonTextOverride?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  mobileIcon: {
    color: theme.branding.v2.gray[400],
  },
}));

const ViewShopButton: React.FC<Props> = (props: Props) => {
  const { onClick, addQueryParams, size, subdirectory, text, variant = 'outlined', eventTextOverride, buttonTextOverride } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const { shop, me } = useAppSelector(state => ({
    shop: state.shops.shop,
    me: state.users.me,
  }));

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let event;
  let buttonText;
  switch (subdirectory) {
    case 'gallery':
      event = 'View Gallery Button Clicked';
      buttonText = 'View My Gallery';
      break;
    case 'signup':
      event = 'Open Signup Button Clicked';
      buttonText = 'View My Sign Up Page';
      break;
    case 'home':
      event = 'View Home Page Button Clicked';
      buttonText = 'Preview';
      break;
    case 'order-forms':
      event = 'View Quotes Page Button Clicked';
      buttonText = 'Preview';
      break;
    case 'events':
      event = 'View Events Page Button Clicked';
      buttonText = 'Preview';
      break;
    case 'about':
      event = 'View About Page Button Clicked';
      buttonText = 'Preview';
      break;
    case 'availability':
      event = 'View Availability Page Button Clicked';
      buttonText = 'Preview';
      break;
    case 'contact':
      event = 'View Contact Page Button Clicked';
      buttonText = 'Preview';
      break;
    case 'faq':
      event = 'View Faq Page Button Clicked';
      buttonText = 'Preview';
      break;
    case 'custom':
      event = 'View Custom Page Button Clicked';
      buttonText = 'Preview';
      break;
    case '/':
      event = 'View Shop Page Button Clicked';
      buttonText = 'Preview';
      break;
    default:
      event = 'Open Shop Button Clicked';
      buttonText = `${shop?.status === 'active' ? 'V' : 'Prev'}iew My Shop`;
  }

  const handleViewShop = () => {
    trackEvent(eventTextOverride || event, createOpenShopButtonClickedEvent(shop, me));
    const cacheBypass = nanoid();
    let queryParams = {
      [cacheBypass]: true,
    };
    if (shop.status === 'prelaunch') {
      queryParams = { ...queryParams, previewMode: true };
    }
    if (addQueryParams) {
      queryParams = {...queryParams, ...addQueryParams}
    }
    window.open(
      `${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}${
        subdirectory === 'custom'
          ? `/${kebabCase(shop?.shopSubpageData?.custom?.title || '')}`
          : subdirectory
          ? `/${subdirectory}`
          : ''
      }?${toQueryString(queryParams)}`,
      '_blank',
    );
    onClick && onClick();
  };

  return isMobile ? (
    <VisibilityOutlined onClick={handleViewShop} className={classes.mobileIcon} />
  ) : (
    <Button
      variant={variant}
      onClick={handleViewShop}
      size={size || 'large'}
      style={size === 'small' ? { height: 48 } : {}}
    >
      {text ? (
        text
      ) : (
        <>
          <VisibilityOutlined />
          &nbsp;&nbsp; {buttonTextOverride || buttonText}
        </>
      )}
    </Button>
  );
};

export default ViewShopButton;
