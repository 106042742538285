export type TemplateType =
  // Basic Templates
  | 'sms-signup'
  | 'new-product'
  | 'custom-order-available'
  | 'new-presale'
  | 'ticketed-event-announcement'
  // Seasonal Templates
  | 'graduation-announcement'
  | 'graduation-reminder'
  | 'graduation-presale'
  | 'graduation-custom'
  | 'graduation-last-call'
  | 'easter-announcement'
  | 'easter-reminder'
  | 'easter-last-call'
  | 'stpatricks-announcement'
  | 'stpatricks-last-call';

export interface Template {
  pageTitle: string;
  pageTitleMobile: string;
  step1TitleUnselected: string;
  step1TitleSelectedPrefix: string;
  subjectLine: string;
  bodyText: string;
  buttonText: string;
  postSendModalTitlePrefix: string;
  showStandardProducts: boolean;
  showCustomProducts: boolean;
  showPresales: boolean;
  showTicketedEvents: boolean;
  eventName: string; // Used for the AI generated email content
  eventTagFilter: string; // Use if you need to filter products by eventTag
  utmCampaign: string;
}

export const templates = {
  // Basic Templates
  'sms-signup': {
    pageTitle: 'Sign Up for SMS',
    pageTitleMobile: 'Sign Up for SMS',
    step1TitleUnselected: '',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Sign Up for Exclusive SMS Updates!',
    bodyText:
      "<p>Hi there!</p><p><br></p><p>Stay in the loop with our latest offerings and exclusive promotions by joining our new SMS list! Opt-in today to receive instant updates right to your phone.</p><p><br></p><p>Don't miss out—be the first to know!</p>",
    buttonText: 'Sign Up',
    postSendModalTitlePrefix: 'Sign Up for SMS Email',
    showStandardProducts: false,
    showCustomProducts: false,
    showPresales: false,
    showTicketedEvents: false,
    eventName: '',
    eventTagFilter: '',
    utmCampaign: '',
  },
  'new-product': {
    pageTitle: 'Product Announcement Email',
    pageTitleMobile: 'Announcement',
    step1TitleUnselected: 'Product to Announce',
    step1TitleSelectedPrefix: '',
    subjectLine: 'A New Product Just Dropped!',
    bodyText:
      'We are excited to announce our latest product! Click on the button below to learn more and place an order.',
    buttonText: 'Order Now',
    postSendModalTitlePrefix: 'Product Announcement',
    showStandardProducts: true,
    showCustomProducts: true,
    showPresales: false,
    showTicketedEvents: true,
    eventName: '',
    eventTagFilter: '',
    utmCampaign: '',
  },
  'custom-order-available': {
    pageTitle: 'New Availability Email',
    pageTitleMobile: 'Availability',
    step1TitleUnselected: 'Which Product Does This Apply To?',
    step1TitleSelectedPrefix: 'New Dates For',
    subjectLine: 'New Dates Available For Custom Orders!',
    bodyText:
      'I just opened up new dates on my calendar. Fill out a request at the link below to request a custom order and secure a date on my calendar!',
    buttonText: 'Submit a Request',
    postSendModalTitlePrefix: 'Availability Announcement',
    showStandardProducts: false,
    showCustomProducts: true,
    showPresales: false,
    showTicketedEvents: false,
    eventName: '',
    eventTagFilter: '',
    utmCampaign: '',
  },
  'new-presale': {
    pageTitle: 'Presale Announcement Email',
    pageTitleMobile: 'Announcement',
    step1TitleUnselected: 'Presale To Announce',
    step1TitleSelectedPrefix: '',
    subjectLine: 'The Presale You’ve Been Waiting for is Live!',
    bodyText:
      'We are excited to announce our newest presale is live! Click on the button below to see everything we have available and shop the presale while you can.',
    buttonText: 'Shop the Presale',
    postSendModalTitlePrefix: 'Presale Announcement',
    showStandardProducts: false,
    showCustomProducts: false,
    showPresales: true,
    showTicketedEvents: false,
    eventName: '',
    eventTagFilter: '',
    utmCampaign: '',
  },
  'ticketed-event-announcement': {
    pageTitle: 'Event Announcement Email',
    pageTitleMobile: 'Announcement',
    step1TitleUnselected: 'Event To Announce',
    step1TitleSelectedPrefix: '',
    subjectLine: '👋 Will We See You There?',
    bodyText: 'Join us for our upcoming event! Grab your tickets now before they sell out.',
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Event Announcement',
    showStandardProducts: false,
    showCustomProducts: false,
    showPresales: false,
    showTicketedEvents: true,
    eventName: '',
    eventTagFilter: '',
    utmCampaign: 'ticketed_event_announcement',
  },
  // Seasonal Templates
  'graduation-announcement': {
    pageTitle: 'Graduation Announcement',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Time for Pomp and Circumstance! 🎓',
    bodyText: `<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Graduation season is upon us! Don’t miss the grade - or the opportunity to celebrate the grad in your life with delicious artisanal goodies!</span></p>`,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Graduation Email',
    showStandardProducts: true,
    showCustomProducts: true,
    showPresales: true,
    showTicketedEvents: true,
    eventName: 'Graduation',
    eventTagFilter: '',
    utmCampaign: 'graduation',
  },
  'graduation-reminder': {
    pageTitle: 'Graduation Reminder',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Make Grad Parties Memorable! 🎉',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Celebrate your grad’s achievements by gifting artisanal handcrafted items - made with love!</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Graduation Email',
    showStandardProducts: true,
    showCustomProducts: true,
    showPresales: true,
    showTicketedEvents: true,
    eventName: 'Graduation',
    eventTagFilter: '',
    utmCampaign: 'graduation',
  },
  'graduation-presale': {
    pageTitle: 'Graduation Pre-Sale',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Presale',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Graduation Pre-Sale Live!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Don’t miss your chance to be part of my graduation pre-sale. Order today!</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Graduation Email',
    showStandardProducts: false,
    showCustomProducts: false,
    showPresales: true,
    showTicketedEvents: false,
    eventName: 'Graduation',
    eventTagFilter: '',
    utmCampaign: 'graduation',
  },
  'graduation-custom': {
    pageTitle: 'Graduation Custom Order',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Add a Touch of Class with Custom!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Looking for something custom in school colors, mascots, or others? Look no further! Place your order today!</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Graduation Email',
    showStandardProducts: false,
    showCustomProducts: true,
    showPresales: false,
    showTicketedEvents: false,
    eventName: 'Graduation',
    eventTagFilter: '',
    utmCampaign: 'graduation',
  },
  'graduation-last-call': {
    pageTitle: 'Graduation Last-Call',
    pageTitleMobile: 'Graduation',
    step1TitleUnselected: 'Featured Product or Presale',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Graduation Orders: Last Call 🎓',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Grab artisanal treats today before your grad walks across the stage. Don’t miss your chance to order!</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Graduation Email',
    showStandardProducts: true,
    showCustomProducts: true,
    showPresales: true,
    showTicketedEvents: true,
    eventName: 'Graduation',
    eventTagFilter: '',
    utmCampaign: 'graduation',
  },
  'easter-announcement': {
    pageTitle: 'Easter Announcement',
    pageTitleMobile: 'Easter',
    step1TitleUnselected: 'Featured Product or Presale',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Celebrate Easter with Egg-Cellent Goods!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hop into the Easter season with handcrafted treats to stuff your basket with! 💐</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Easter Email',
    showStandardProducts: true,
    showCustomProducts: true,
    showPresales: true,
    showTicketedEvents: true,
    eventName: 'Easter',
    eventTagFilter: '',
    utmCampaign: 'easter',
  },
  'easter-reminder': {
    pageTitle: 'Easter Reminder',
    pageTitleMobile: 'Easter',
    step1TitleUnselected: 'Featured Product or Presale',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Add a Touch of Spring to Your Easter!',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Think beyond flowers and wrapped chocolate - add a touch of love with artisanal goods this Easter! 🐣</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Easter Email',
    showStandardProducts: true,
    showCustomProducts: true,
    showPresales: true,
    showTicketedEvents: true,
    eventName: 'Easter',
    eventTagFilter: '',
    utmCampaign: 'easter',
  },
  'easter-last-call': {
    pageTitle: 'Last-Call for Easter Orders',
    pageTitleMobile: 'Easter',
    step1TitleUnselected: 'Featured Product or Presale',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Don’t Let this Offer Hop Away! 🐇',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Easter is almost here, grab some egg-cellent treats before the holiday arrives!</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: 'Easter Email',
    showStandardProducts: true,
    showCustomProducts: true,
    showPresales: true,
    showTicketedEvents: true,
    eventName: 'Easter',
    eventTagFilter: '',
    utmCampaign: 'easter',
  },
  'stpatricks-announcement': {
    pageTitle: 'St. Patrick’s Day Announcement',
    pageTitleMobile: 'St. Patrick’s',
    step1TitleUnselected: 'Featured Product or Presale',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Check Out These Lucky Treats! ☘️',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there!</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Want to step up from boring chocolate coins? Check out these tasty handmade St. Patty’s Day treats! Order today ☘️</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: "St. Patrick's Day Email",
    showStandardProducts: true,
    showCustomProducts: true,
    showPresales: true,
    showTicketedEvents: true,
    eventName: "St. Patrick's Day",
    eventTagFilter: '',
    utmCampaign: 'stpatricksday',
  },
  'stpatricks-last-call': {
    pageTitle: 'Last-Call for St. Patrick’s Day Orders',
    pageTitleMobile: 'St. Patrick’s',
    step1TitleUnselected: 'Featured Product or Presale',
    step1TitleSelectedPrefix: '',
    subjectLine: 'Grab Your Pot-of-Gold Today! 🌟',
    bodyText: `
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Hi there,</span>
      </p><p><br></p>
      <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Don’t let these sweet treats disappear like a rainbow! Shop now and order today before time runs out! 🌈</span></p>
    `,
    buttonText: 'Shop Now',
    postSendModalTitlePrefix: "St. Patrick's Day Email",
    showStandardProducts: true,
    showCustomProducts: true,
    showPresales: true,
    showTicketedEvents: true,
    eventName: "St. Patrick's Day",
    eventTagFilter: '',
    utmCampaign: 'stpatricksday',
  },
};
