import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Fuse from 'fuse.js';
import _ from 'lodash';
import moment from 'moment-timezone';
import { DialogContent, Grid, Theme, useMediaQuery, useTheme, ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { ButtonV2, Card, Typography, Pill, SearchInput, Spinner } from '@castiron/components';
import { TicketedEvent } from '@castiron/domain';
import { useTracking, defaultTimeZone } from '@castiron/utils';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { transactionRepository, customerRepository } from '../../../domain';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import ModalHeader from '../../RootModal/ModalHeader';
import ModalActions from '../../RootModal/ModalActions';
import HeaderTabs, { tabProps } from '../../Layout/Header/HeaderTabs';
import AttendeeActionsDropdown from './AttendeeActionsDropdown';

export type Props = {
  event: TicketedEvent;
};

const searchService = getService('orders', 'search');

const useStyles = makeStyles((theme: Theme) => ({
  attendeeContainer: {
    border: `1px solid ${theme.branding.v2.gray[300]}`,
    borderRadius: '12px',
    padding: '16px 16px 16px 24px',
  },
  copyButton: {
    color: theme.branding.v2.blue[500],
    '&:hover': {
      color: theme.branding.v2.blue[700],
    },
  },
  detailsCard: {
    gap: '8px',
    width: '100%',
  },
  modalWrapper: {
    [theme.breakpoints.up('md')]: {
      height: 844,
      width: 531,
    },
  },
  tabContent: {
    padding: '24px',
  },
  truncate: {
    whiteSpace: 'nowrap',
    wrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tabNav: {
    '& .MuiTabs-flexContainer': {
      borderBottom: `1px solid ${theme.branding.gray[300]}`,
    },
    backgroundColor: theme.branding.gray[100],
    position: 'sticky',
    top: 0,
    zIndex: 50,
  },
}));

const EventListingModal: React.FC<Props> = (props: Props) => {
  const { event } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [shopTimeZone, setShopTimeZone] = useState<string>(shop?.config?.timeZone || defaultTimeZone);
  const [copiedTooltipOpen, setCopiedTooltipOpen] = useState(false);
  const [overrideTabValue, setOverrideTabValue] = useState('');
  const [unitsSold, setUnitsSold] = useState<number>(0);
  const [eventAttendees, setEventAttendees] = useState([]);
  const [filteredAttendees, setFilteredAttendees] = useState([]);
  const [emptySearch, setEmptySearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [attendeesLoading, setAttendeesLoading] = useState(true);

  const fuseOptions = {
    keys: ['name', 'customer.name', 'orderNumber'],
    threshold: 0.2,
    useExtendedSearch: true,
  };

  const eventDate = moment.unix(event?.eventDetails?.date?.startTime).format('L');
  const eventTime = {
    start: moment
      .unix(event?.eventDetails?.date?.startTime)
      .tz(shopTimeZone || defaultTimeZone)
      .format('LT')
      .toString(),
    end: moment
      .unix(event?.eventDetails?.date?.endTime)
      .tz(shopTimeZone || defaultTimeZone)
      .format('LT')
      .toString(),
  };
  const eventLocation = event?.eventDetails?.location;
  const isVirtual = !!eventLocation?.meetingUrl;

  const curateAttendees = async (shopId, eventId) => {
    const attendees = [];

    const transactions = await transactionRepository.findByShopId(shopId);
    const eventTransactions = transactions.filter(
      tx => tx.transactionStatus === 'succeeded' && tx.order.items.some(item => item.id === eventId),
    );

    for (const tx of eventTransactions) {
      const orderProducts = tx.order.items;

      const { customerObj, customer: customerId } = tx;

      let customer = customerObj;
      if (!customer && customerId) {
        customer = await customerRepository.get(customerId);
      }

      orderProducts?.forEach(p => {
        if (p.id !== eventId || _.isEmpty(p.attendees)) return;

        p.attendees.forEach(att => {
          const enrichedAttendee = {
            transactionId: tx.id,
            orderNumber: tx.order.orderNumber,
            name: att.name,
            email: att.email || '',
            mobileNumber: att.mobileNumber || '',
            customer: {
              id: customer?.id,
              name: [customer?.firstName, customer?.lastName]?.filter(x => !!x)?.join(' '),
              email: customer?.email || '',
              mobileNumber: customer?.mobileNumber || customer?.messagingPreferences?.sms?.smsPhoneNumber || '',
            },
          };

          attendees.push(enrichedAttendee);
        });
      });
    }

    const sortedAttendees = _.sortBy(attendees, ['name', 'customer.name']);

    return sortedAttendees;
  };

  const getTicketsSold = orders => {
    return orders?.reduce((totalQuantity, order) => {
      return (
        totalQuantity +
        order?.items?.reduce((orderQuantity, product) => {
          if (product?.productId !== event?.id) return orderQuantity + 0;
          return orderQuantity + (product?.quantity || 0);
        }, 0)
      );
    }, 0);
  };

  const search = async () => {
    if (event?.id && shop?.id) {
      const results = await searchService({
        stage: 'order',
        productId: event.id,
        transactionStatus: 'succeeded',
        transactionType: 'transaction',
      });

      const ticketsSold = getTicketsSold(results?.orders);
      const attendees = await curateAttendees(shop.id, event.id);

      setUnitsSold(ticketsSold);
      setEventAttendees(attendees);
      setFilteredAttendees(attendees);

      setAttendeesLoading(false);
    }
  };

  useEffect(() => {
    search();
  }, [event, shop]);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleIconClose = () => {
    trackEvent('Calendar Modal View Closed', {
      action: 'Icon Clicked',
      type: 'event',
      id: event.id,
    });
    handleClose();
  };

  const handleBackgroundClose = () => {
    trackEvent('Calendar Modal View Closed', {
      action: 'Background Clicked',
      type: 'event',
      id: event.id,
    });
    handleClose();
  };

  const goToEventDetails = () => {
    trackEvent('Calendar View Item Clicked', {
      type: 'event',
      id: event.id,
    });
    handleClose();
    history.push(`/events/edit/${event?.id}`);
  };

  const formatTime = time => _.toLower(time).replace(' ', '');
  const handleCopy = () => {
    navigator.clipboard.writeText(isVirtual ? eventLocation?.meetingUrl : eventLocation?.address?.fullAddress);
    setCopiedTooltipOpen(true);
    setTimeout(() => {
      setCopiedTooltipOpen(false);
    }, 2000);
  };

  const areTicketsLimited = !event?.unlimitedInventory && event?.inventory > 0;
  const generalContent = (
    <Grid container direction="column" className={classes.tabContent} style={{ gap: '24px' }}>
      <Card title="Details" noMargin>
        <Grid container item direction="column" wrap="nowrap" className={classes.detailsCard}>
          <Typography variant="body1" className={classes.truncate}>
            {event?.title}
          </Typography>
          {eventLocation?.name && <Typography variant="body1">{eventLocation.name}</Typography>}
          <Grid container item justify="space-between">
            <Typography variant="body1" className={classes.truncate}>
              {isVirtual ? eventLocation?.meetingUrl : eventLocation?.address?.addressLine1}
            </Typography>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              open={copiedTooltipOpen}
              disableFocusListener
              disableHoverListener
              placement="top"
              arrow
              title="Copied!"
            >
              <ButtonBase disableRipple onClick={handleCopy} className={classes.copyButton}>
                <FileCopyOutlinedIcon />
              </ButtonBase>
            </Tooltip>
          </Grid>
          <Typography variant="body1">
            {eventDate} {formatTime(eventTime.start)} - {formatTime(eventTime.end)}
          </Typography>
          {isVirtual && (
            <ButtonV2
              variant="outlined"
              onClick={() => {
                const meetingUrl = eventLocation?.meetingUrl?.includes('http')
                  ? eventLocation.meetingUrl
                  : `https://${eventLocation?.meetingUrl}`;
                window.open(meetingUrl, '_blank');
              }}
              style={{ width: '100%' }}
            >
              <Grid container wrap="nowrap" style={{ gap: '8px', width: '100%' }}>
                <LaunchOutlinedIcon />
                <Typography variant="body1">Join Event Now</Typography>
              </Grid>
            </ButtonV2>
          )}
        </Grid>
      </Card>
      <Card title="Additional Information" noMargin>
        <Grid container item direction="column" wrap="nowrap" style={{ gap: '24px' }}>
          {(!!event.maximumInventory || !areTicketsLimited) && (
            <Grid container item justify="space-between">
              <Typography variant="body1">Tickets Listed</Typography>
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {areTicketsLimited ? event.maximumInventory : 'Unlimited'}
              </Typography>
            </Grid>
          )}
          <Grid container item justify="space-between">
            <Typography variant="body1">Tickets Sold</Typography>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              {unitsSold}
            </Typography>
          </Grid>
          {areTicketsLimited && (
            <Grid container item justify="space-between">
              <Typography variant="body1">Tickets Remaining</Typography>
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {event.inventory}
              </Typography>
            </Grid>
          )}
          <ButtonV2
            variant="outlined"
            onClick={async () => {
              const recipientList = eventAttendees.map(att => {
                return {
                  name: att.name,
                  email: !!att.email ? att.email : att.customer.email,
                  mobileNumber: !!att.mobileNumber ? att.mobileNumber : att.customer.mobileNumber || '',
                };
              });

              await dispatch(
                openModal({
                  modalType: 'BULK_CONTACT_MODAL',
                  modalProps: {
                    show: true,
                    recipientList,
                    sendToEventAttendees: true,
                  },
                }),
              );
            }}
            style={{ width: '100%' }}
          >
            Message Attendees
          </ButtonV2>
          <ButtonV2 variant="outlined" onClick={() => setOverrideTabValue('Attendees')} style={{ width: '100%' }}>
            View Attendee List
          </ButtonV2>
        </Grid>
      </Card>
    </Grid>
  );

  const handleSearchChange = (event: any) => {
    setEmptySearch(false);
    const fuse = new Fuse(eventAttendees, fuseOptions);

    if (event.target.value === '') {
      setFilteredAttendees(eventAttendees);
    } else {
      const result = fuse.search(event.target.value);

      if (_.isEmpty(result)) {
        setEmptySearch(true);
        setSearchValue(event.target.value);
      } else {
        const orderedAttendees = result.sort(i => i.refIndex).map(i => i.item);
        setFilteredAttendees(orderedAttendees);
      }
    }
  };

  const attendeesContent = (
    <Grid container direction="column" className={classes.tabContent} style={{ gap: '16px' }}>
      <SearchInput
        onChange={handleSearchChange}
        placeholder="Search Attendees"
        disabled={attendeesLoading}
        iconAtStart
        noMargin
      />
      {attendeesLoading ? (
        <Spinner show={true} size={'relative'} />
      ) : (
        <Grid container item direction="column" wrap="nowrap" style={{ gap: '12px' }}>
          {filteredAttendees.map((att, key) => {
            return (
              <Grid
                container
                item
                wrap="nowrap"
                justify="space-between"
                className={classes.attendeeContainer}
                key={`event-attendees-${key}`}
              >
                <Grid container item direction="column" wrap="nowrap" justify="center" style={{ gap: '4px' }}>
                  <Grid container item wrap="nowrap" alignItems="center" style={{ gap: '12px' }}>
                    <ButtonBase
                      disableRipple
                      onClick={() => {
                        history.push(`/orders/edit/${att.transactionId}`);
                        handleClose();
                      }}
                      style={{ padding: 0 }}
                    >
                      <Typography variant="caption" style={{ color: theme.branding.v2.blue[500] }}>
                        #{att.orderNumber}
                      </Typography>
                    </ButtonBase>
                    <Typography variant="caption4" style={{ color: theme.branding.v2.gray[500] }}>
                      {att.customer?.name}
                    </Typography>
                  </Grid>
                  <Typography variant="subtitle1">{att.name}</Typography>
                </Grid>
                <AttendeeActionsDropdown attendee={att} onClose={handleClose} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );

  const tabs: tabProps[] = [
    {
      value: 'General',
      content: generalContent,
    },
    {
      value: 'Attendees',
      content: attendeesContent,
    },
  ];

  return (
    <ModalWrapper
      fullScreen={isMobile}
      size="lg"
      show={true}
      paperClass={classes.modalWrapper}
      onClose={handleBackgroundClose}
    >
      <ModalHeader
        title={
          <Grid container direction="row" justify="flex-start" alignItems="center" style={{ gap: '8px' }}>
            <Grid item>
              <Typography variant="h3">Event Details</Typography>
            </Grid>
            <Grid item>
              <Pill content={isVirtual ? 'Virtual' : 'In-Person'} variant={isVirtual ? 'lightPurple' : 'deepPurple'} />
            </Grid>
          </Grid>
        }
        handleClose={handleIconClose}
      />
      <DialogContent style={{ padding: 0 }}>
        <HeaderTabs
          initialTabValue="General"
          tabs={tabs}
          overrideTabValue={overrideTabValue}
          tabNavClassName={classes.tabNav}
          centerTabs
        />
      </DialogContent>
      <ModalActions>
        <ButtonV2 onClick={goToEventDetails} variant="contained">
          View Event
        </ButtonV2>
      </ModalActions>
    </ModalWrapper>
  );
};

export default EventListingModal;
