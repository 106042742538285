import React, { ReactNode, useCallback } from 'react';
import FileUploader from '../FileUploader';
import SvgIcon from '../SvgIcon';
import TrashIcon from '../assets/trash.svg';
import { ButtonBase, makeStyles, Theme } from '@material-ui/core';
import ProductImage from '../ProductImage';
import InputLabel from '../InputLabel';

type Props = {
  preview?: any;
  onFileDrop: (files) => void;
  onDeleteClick?: () => void;
  primaryLabel?: string;
  height?: string;
  width?: string;
  secondaryLabel?: ReactNode;
  browseButtonRef?: React.MutableRefObject<any>;
  dropZoneText?: string;
  imageInProduct?: boolean;
  maxFiles?: number;
  maxFilesError?: boolean;
  closeToMax?: boolean;
  maxSize?: number | '100%';
  children?: ReactNode;
};

const ImageUploader: React.FC<Props> = (props: Props) => {
  const { onFileDrop, preview, onDeleteClick, primaryLabel, secondaryLabel, browseButtonRef, dropZoneText, imageInProduct, maxFiles, maxFilesError, closeToMax, maxSize } = props;

  const maxDropzoneSize = maxSize || 340;

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      // maxHeight: 350,
    },
    imageContainer: {
      position: 'relative',
      width: '100%',
      borderRadius: 6,
    },
    iconButton: {
      zIndex: 20,
      top: 8,
      backgroundColor: theme.palette.common.white,
      right: 8,
      position: 'absolute',
      borderRadius: 4,
      width: 32,
      height: 32,
    },
    dropzone: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    fileUploaderContainer: {
      position: 'relative',
      width: '100%',
      minHeight: 125,
      maxWidth: maxDropzoneSize,
      maxHeight: maxDropzoneSize,
    },
    icon: {
      color: theme.branding.castiron,
      width: 38,
      height: 38,
    },
    image: {
      position: 'relative',
      width: '100%',
      maxWidth: maxDropzoneSize,
      maxHeight: maxDropzoneSize,
      height: '100%',
      objectFit: 'contain',
      overflow: 'hidden',
    },
    label: {
      fontSize: 14,
      fontWeight: 700,
    },
    productDropzone: {
      paddingBottom: 0,
      width: '100%',
      height: 150,
    },
    subLabel: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.branding.graphite,
    },
  }));

  const classes = useStyles();

  const handleFileDrop = useCallback(
    async file => {
      if (!file || !file[0]) return;

      onFileDrop(file);
    },
    [onFileDrop],
  );

  return (
    <div className={classes.container}>
      {(primaryLabel || secondaryLabel) && <InputLabel primaryLabel={primaryLabel} secondaryLabel={secondaryLabel} />}
      {preview ? (
        <div className={`${imageInProduct ? classes.productDropzone : ''} ${classes.fileUploaderContainer} `}>
          <ButtonBase className={classes.iconButton} onClick={onDeleteClick}>
            <SvgIcon className={classes.icon}>
              <TrashIcon />
            </SvgIcon>
          </ButtonBase>
          <ProductImage alt="product image" src={preview} height={props.height} width={props.width} backgroundColor="inherit" />
        </div>
      ) : (
        <FileUploader
          maxFilesError={maxFilesError}
          closeToMax={closeToMax}
          imageInProduct={imageInProduct}
          dropzoneText={dropZoneText || "DROP IMAGE HERE"}
          containerClass={`${imageInProduct ? classes.productDropzone : ''} ${classes.fileUploaderContainer}`}
          dropzoneClass={classes.dropzone}
          fileType="image/jpeg, image/png"
          onFileDrop={handleFileDrop}
          browseButtonRef={browseButtonRef}
          maxFiles={maxFiles}
        />
      )}
    </div>
  );
};

export default ImageUploader;
