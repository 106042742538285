import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dinero from 'dinero.js';
import moment from 'moment';
import { Grid, useMediaQuery, useTheme, Chip } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Button, Typography } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Difference from './Difference';
import { checkLastStripeConnection, findNextPayoutDate } from '../Store/Payments/Payouts';
import { getService } from '../../firebase';

const searchService = getService('orders', 'search');

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    gap: 8,
  },
  box: {
    borderRadius: 12,
    gap: 16,
    padding: 16,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  stripeStatusChip: {
    border: `1px solid ${theme.branding.v2.gray[100]}`,
    padding: '4px 8px',
    height: 28,
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  value: {
    textAlign: 'center',
    //adjust to align with new h3
    fontSize: '28px',
    fontWeight: 700,
  },
}));

const Stats: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { account, shop, stripeStatus } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    stripeStatus: state.shops.stripe.status,
  }));

  // allow user to access if stripe status is incomplete (locked for 15 minutes after last attempt), not connected, or error
  const isStripeProcessing =
    stripeStatus === 'PROCESSING' || (stripeStatus === 'INCOMPLETE' && !checkLastStripeConnection(account));

  const nextPayoutDate = moment(findNextPayoutDate(account)).format('MMM D');

  const [thisWeekStats, setThisWeekStats] = useState({
    count: 0,
    total: 0,
  });
  const [lastWeekStats, setLastWeekStats] = useState({
    count: 0,
    total: 0,
  });
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);


  useEffect(() => {
    const getTransactions = async () => {
      await setIsTransactionsLoading(true);
      if (shop && shop.id) {
        const thisWeekResponseP = searchService({
          stage: 'order',
          transactionStatus: 'succeeded',
          transactionTypes: ['transaction', 'sub-transaction'],
          createDateRange: {
            startDate: moment()
              .startOf('isoWeek')
              .unix(),
            endDate: moment()
              .endOf('isoWeek')
              .unix(),
          },
        });
        const lastWeekResponseP = searchService({
          stage: 'order',
          transactionStatus: 'succeeded',
          transactionTypes: ['transaction', 'sub-transaction'],
          createDateRange: {
            startDate: moment()
              .subtract(1, 'weeks')
              .startOf('isoWeek')
              .unix(),
            endDate: moment()
              .subtract(1, 'weeks')
              .endOf('isoWeek')
              .unix(),
          },
        });
        const [thisWeekResponse, lastWeekResponse] = await Promise.all([thisWeekResponseP, lastWeekResponseP]);
        setThisWeekStats({
          count: thisWeekResponse?.total || 0,
          total: thisWeekResponse?.totalSalesMinusRefunds || 0,
        });
        setLastWeekStats({
          count: lastWeekResponse?.total || 0,
          total: lastWeekResponse?.totalSalesMinusRefunds || 0,
        });
      }
      setIsTransactionsLoading(false);
    };

    getTransactions();
  }, [shop]);

  const statusChipColors =
    stripeStatus === 'READY'
      ? theme.branding.v2.green[500]
      : isStripeProcessing
      ? theme.branding.v2.yellow[900]
      : theme.branding.v2.red[500];

  return (
    <Grid container direction={isMobile ? 'column' : 'row'} wrap="nowrap" className={classes.container}>
      <Grid container item xs={12} md={4} justify="center" className={classes.box}>
        <Grid container item direction="row" justify="space-between">
          <Typography variant="button">Next Payout</Typography>
          <Chip
            variant="outlined"
            label={
              <Grid container direction="row" alignItems="center" wrap="nowrap" style={{ gap: 4, padding: 0 }}>
                <FiberManualRecordIcon style={{ fontSize: '9px', color: statusChipColors }} />
                <Typography variant="button2" style={{ color: statusChipColors }}>
                  {stripeStatus === 'READY' ? 'Connected' : isStripeProcessing ? 'Pending' : 'Not Connected'}
                </Typography>
              </Grid>
            }
            className={classes.stripeStatusChip}
          />
        </Grid>
        {stripeStatus === 'READY' ? (
          <Grid>
            <Typography variant="h1" className={classes.value}>
              {nextPayoutDate}
            </Typography>
          </Grid>
        ) : (
          <Button
            fullWidth
            variant="contained"
            disabled={isStripeProcessing}
            style={{ padding: '12px 16px' }}
            onClick={() => history.push('/store/payments')}
          >
            <Typography variant="button" style={{ color: theme.branding.v2.gray[0] }}>
              {isStripeProcessing ? 'Connection Pending' : 'Connect to Get Paid'}
            </Typography>
          </Button>
        )}
      </Grid>

      <Grid container item xs={12} md={4} justify="center" className={classes.box}>
        <Grid container item direction="row" justify="space-between">
          <Typography variant="button">Weekly Revenue</Typography>
          {!isTransactionsLoading && (
            <Difference oldValue={lastWeekStats.total} currentValue={thisWeekStats.total} unit="$" />
          )}
        </Grid>
        <Typography variant="h1" className={classes.value}>
          {Dinero({ amount: thisWeekStats.total }).toFormat('$0.00')}
        </Typography>
      </Grid>

      <Grid container item xs={12} md={4} justify="center" className={classes.box}>
        <Grid container item direction="row" justify="space-between">
          <Typography variant="button">Weekly Sales</Typography>
          {!isTransactionsLoading && (
            <Difference
              oldValue={lastWeekStats.count}
              currentValue={thisWeekStats.count}
            />
          )}
        </Grid>
        <Typography className={classes.value} variant="h1">
          {thisWeekStats.count}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Stats;
