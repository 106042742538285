import {GalleryPhoto, Shop} from './index';
import {Profile} from "../profile";
import moment from "moment/moment";
import {Account} from "../account";
import {Address} from "../address";

export const shopToEventModel = (shop: Shop) => ({
  id: shop.id,
  websiteUrl: shop.websiteUrl,
  businessName: shop.businessName,
  owner: shop.owner?.firstName ? `${shop.owner.firstName} ${shop.owner.lastName}` : 'Unknown',
  tags: shop.tags,
  isICP: !!shop.tags?.includes('ICP'),
});

export const galleryPhotoToEventModel = (photo: GalleryPhoto) => ({
  url: photo.photo.downloadUrl,
  caption: photo.caption,
  category: photo.category,
});


export const shopToProfile = (shop: Shop, account: Account): Profile => ({
  ownerName: shop.owner?.firstName ? {
    firstName: shop.owner.firstName,
    lastName: shop.owner.lastName,
    fullName: `${shop.owner.firstName} ${shop.owner.lastName}`
  } :  undefined,
  shopId: shop.id,
  taxonomy: shop.taxonomy,
  biography: `<h3>${shop.aboutTitle}</h3><div>${shop.description}</div>`,
  claimedAt: moment().unix(),
  isClaimed: true,
  status: ['active', 'prelaunch'].includes(shop.status) ? 'active' : 'inactive',
  createdAt: moment().unix(),
  hashtags: [],
  emails: [shop.email],
  profilePic: shop.profileImageObj,
  isPublic: ['active', 'prelaunch'].includes(shop.status),
  slug: shop.websiteUrl,
  serviceArea: shop.serviceArea?.postalCodes,
  location: account.billingAddress,
  socialAccounts: {
    instagram: shop.socialMedia?.instagramLink ? {
      url: shop.socialMedia?.instagramLink
    } : undefined,
    facebook: shop.socialMedia?.facebookLink ? {
      url: shop.socialMedia?.facebookLink
    } : undefined,
    tiktok: shop.socialMedia?.tiktokLink ? {
      url: shop.socialMedia?.tiktokLink
    } : undefined,
  }
});

const array = a => a || [];

const getAddress = (shop: Shop, account: Account): Address => {
  if (account.billingAddress) {
    return account.billingAddress;
  }

  if (shop.seoMetadata?.address) {
    return {
      city: shop.seoMetadata.address.addressLocality,
      region: shop.seoMetadata.address.addressRegion,
      postalCode: shop.seoMetadata.address.postalCode,
      country: shop.seoMetadata.address.addressCountry
    }
  }

  return undefined;
}

export const shopToESDoc = (shop: Shop, account: Account) => ({
  id: account.id,
  businessName: shop.businessName,
  websiteUrl: shop.websiteUrl,
  email: shop.email,
  owner: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
  aboutTitle: shop.aboutTitle,
  description: shop.description,
  categories: shop.categories?.map(c => c.name),
  tags: shop.tags,
  checklistCompletions: shop.checklistCompletions,
  billingAddress: account.billingAddress,
  badges: [
    shop.badges?.made,
    ...array(shop.badges?.certifications),
    ...array(shop.badges?.specialDiets),
    ...array(shop.badges?.more),
    shop.badges?.award,
  ].filter(b => b !== undefined),
  onboardingQuestions: account.onboardingQuestions,
  subscription: {
    name: account.subscription?.plan?.name,
    planId: account.subscription?.plan?.id,
    tierId: account.tier.id,
    subscribedOn: account.subscription?.startDate,
    inTrail: !account.subscription?.isTrialOver
  },
  taxonomy: {
    top: shop.taxonomy?.map(t => t.top) || [],
    mid: shop.taxonomy?.map(t => t.mid) || [],
    leaf: shop.taxonomy?.map(t => t.leaf) || [],
  }
});