import React, { useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Dinero from 'dinero.js';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import moment from 'moment';
import { useAppSelector } from '../../hooks';
import { getService } from '../../firebase';

const searchService = getService('orders', 'search');

type Props = {
  period: number;
};

type TransactionStats = {
  length: number;
  transactionTotal: number;
  transactionAvg: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  statsContainer: {
    marginTop: 32,
    gap: 16,
    [theme.breakpoints.down('sm')]: {
      gap: 8,
    },
  },
  box: {
    padding: 36,
    border: '1px solid #E7E7E7',
    borderRadius: 16,
    gap: 24,
    [theme.breakpoints.down('md')]: {
      padding: 24,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 34,
    },
  },
  infoText: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
    fontSize: 12,
  },
  largeInfoText: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: 12,
  },
  payoutLabel: {
    color: theme.branding.gray[700],
    fontSize: 14,
  },
  payoutDate: {
    fontSize: 14,
    fontWeight: 700,
  },
  payoutTitle: {
    marginBottom: '.5em',
    fontSize: 14,
    fontWeight: 700,
  },
  payoutBoxInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  smallBox: {
    padding: 16,
    border: '1px solid #E7E7E7',
    borderRadius: 16,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  smallBoxContainer: {
    display: 'flex',
    gap: 12,
    [theme.breakpoints.down('sm')]: {
      gap: 8,
    },
  },
  smallStatBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  statBoxMobile: {
    width: '100%',
    border: '1px solid #E7E7E7',
    borderRadius: 12,
    padding: 14,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  statBoxSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  value: {
    color: '#171717',
    lineHeight: '48px',
    fontWeight: 700,
    fontFamily: 'Roboto',
    [theme.breakpoints.up('md')]: {
      fontSize: 54,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 48,
    },
  },
  smallValue: {
    color: '#171717',
    lineHeight: '40px',
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontSize: 28,
  },
  smallValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sign: {
    fontFamily: 'Roboto',
    verticalAlign: 'super',
    color: '#868686',
    fontSize: 29,
  },
  subTitle: {
    color: theme.branding.gray[800],
    fontSize: 14,
    lineHeight: '16px',
  },
  subTitlePushed: {
    color: theme.branding.gray[800],
    fontSize: 14,
    lineHeight: '16px',
  },
  success: {
    color: theme.palette.success.dark,
  },
  error: {
    color: theme.palette.error.dark,
  },
  circleSucces: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
  },
  circleError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  arrow: {
    borderRadius: 100,
    padding: 8,
    width: 35,
    height: 35,
    marginRight: 8,
  },
  arrowContainer: {
    height: 48,
    width: 48,
  },
  arrowIcon: {
    width: 38,
    height: 38,
  },
}));

const Stats: React.FC<Props> = (props: Props) => {
  const { period } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { shop, amount, payoutFrequency } = useAppSelector(state => ({
    shop: state.shops.shop,
    amount: state.balance.amount,
    payoutFrequency: state.shops?.account?.tier?.payoutFrequency,
  }));

  const [currentTransactionStats, setCurrentTransactionStats] = useState<TransactionStats>({
    length: 0,
    transactionTotal: 0,
    transactionAvg: 0,
  });
  const [previousTransactionStats, setPreviousTransactionStats] = useState<TransactionStats>({
    length: 0,
    transactionTotal: 0,
    transactionAvg: 0,
  });
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);

  useEffect(() => {
    const calculateTransactionStats = response => {
      const length = response?.total || 0;
      const transactionTotal = response?.totalSalesMinusRefunds || 0;
      const transactionAvg = length !== 0 ? Math.ceil(transactionTotal / length) : 0;
      return {
        length,
        transactionTotal,
        transactionAvg,
      };
    };

    const getTransactionStats = async () => {
      setIsTransactionsLoading(true);
      if (shop) {
        const currentTransactionsP = searchService({
          stage: 'order',
          transactionStatus: 'succeeded',
          transactionTypes: ['transaction', 'sub-transaction'],
          createDateRange: {
            startDate: moment()
              .subtract(period, 'days')
              .startOf('day')
              .unix(),
            endDate: moment()
              .endOf('day')
              .unix(),
          },
        });

        const previousTransactionsP = searchService({
          stage: 'order',
          transactionStatus: 'succeeded',
          transactionTypes: ['transaction', 'sub-transaction'],
          createDateRange: {
            startDate: moment()
              .subtract(period * 2, 'days')
              .startOf('day')
              .unix(),
            endDate: moment()
              .subtract(period + 1, 'days')
              .endOf('day')
              .unix(),
          },
        });

        const [currentTransactions, previousTransactions] = await Promise.all([
          currentTransactionsP,
          previousTransactionsP,
        ]);
        setCurrentTransactionStats(calculateTransactionStats(currentTransactions));
        setPreviousTransactionStats(calculateTransactionStats(previousTransactions));
      }
      setIsTransactionsLoading(false);
    };

    getTransactionStats();
  }, [shop, period]);

  const getDifference = (current, previous, unit = '$') => {
    let isZero = false;
    if (!current || current === 0) isZero = true;

    const changeValue = isZero ? 0 : Math.abs(current - previous);
    const valueIncreased = current >= previous;

    return { valueIncreased, changeValue };
  };

  const revenueDifference = getDifference(
    currentTransactionStats.transactionTotal,
    previousTransactionStats.transactionTotal,
  );
  const salesDifference = getDifference(currentTransactionStats.length, previousTransactionStats.length, '');
  const averagePerSaleDifference = getDifference(
    currentTransactionStats.transactionAvg,
    previousTransactionStats.transactionAvg,
  );

  return (
    <Grid className={classes.statsContainer} container direction={isMobile ? 'column' : 'row'} wrap="nowrap">
      <Grid xs={12} lg={8} container alignItems="center" justify="space-around" className={classes.box} wrap="nowrap">
        <Grid
          container
          direction="column"
          alignItems="center"
          justify='center'
          xs={12}
        >
          <Typography className={classes.value}>
            {Dinero({ amount: currentTransactionStats.transactionTotal }).toFormat('$0.00')}
          </Typography>

          <Grid container alignItems="center" justify="center" style={{ marginTop: 8 }}>
            {revenueDifference.valueIncreased ? (
              <ArrowUpward className={`${classes.arrow} ${classes.circleSucces}`} />
            ) : (
              <ArrowDownward className={`${classes.arrow} ${classes.circleError}`} />
            )}
            <Grid >
              <Typography className={classes.subTitlePushed}>Gross sales</Typography>
              {!isTransactionsLoading && (
                <Typography
                  className={`${classes.largeInfoText} ${revenueDifference.valueIncreased ? classes.success : classes.error
                    }`}
                >
                  {Dinero({ amount: revenueDifference.changeValue }).toFormat('$0.00')} (last {period} days{')'}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classes.smallBoxContainer}
        container
        direction="column"
        justify="center"
        xs={12}
        lg={4}
        wrap="nowrap"
      >
        <Grid container alignItems="center" className={classes.smallBox}>
          <Box className={classes.smallStatBox}>
            <div className={classes.smallValueContainer}>
              <Typography className={classes.subTitle}>Total sales</Typography>
              <Typography className={classes.smallValue}>{currentTransactionStats.length}</Typography>
            </div>

            {!isTransactionsLoading && (
              <Typography
                className={`${classes.infoText} ${salesDifference.valueIncreased ? classes.success : classes.error}`}
              >
                {salesDifference.changeValue} {'('}last {period} days{')'}
                {salesDifference.valueIncreased ? (
                  <ArrowUpward className={`${classes.arrow} ${classes.circleSucces}`} />
                ) : (
                  <ArrowDownward className={`${classes.arrow} ${classes.circleError}`} />
                )}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid container alignItems="center" className={classes.smallBox}>
          <Box className={classes.smallStatBox}>
            <div className={classes.smallValueContainer}>
              <Typography className={classes.subTitle}>Avg Rev/Sale</Typography>
              <Typography className={classes.smallValue}>
                {Dinero({ amount: currentTransactionStats.transactionAvg }).toFormat('$0.00')}
              </Typography>
            </div>
            {!isTransactionsLoading && (
              <Typography
                className={`${classes.infoText} ${averagePerSaleDifference.valueIncreased ? classes.success : classes.error
                  }`}
              >
                {Dinero({ amount: averagePerSaleDifference.changeValue }).toFormat('$0.00')} {'('}last {period} days{')'}
                {averagePerSaleDifference.valueIncreased ? (
                  <ArrowUpward className={`${classes.arrow} ${classes.circleSucces}`} />
                ) : (
                  <ArrowDownward className={`${classes.arrow} ${classes.circleError}`} />
                )}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Stats;
