import { BoltIconOutlined, Card } from '@castiron/components';
import { Attribution } from '@castiron/domain';
import { Grid, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import LanguageIcon from '@material-ui/icons/Language';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  attribution?: Attribution;
};

const useStyles = makeStyles((theme: Theme) => ({
  cta: {
    color: theme.branding.v2.green[500],
    cursor: 'pointer',
  },
  ctaBlock: {
    background: theme.branding.v2.green[50],
    borderRadius: 16,
    padding: 16,
  },
  data: {
    marginTop: '4px',
  },
  icon: {
    width: 16,
    height: 16,
    color: theme.branding.v2.gray[400],
    marginRight: 8,
  },
}));

const orderSourceContent = {
  ticketed_event_announcement: {
    source: 'Ticketed Event Announcement',
    route: '/marketing/single-send-email?type=ticketed-event-announcement',
  },
  ticketed_event_promo: {
    source: 'Ticketed Event Promo',
    route: '/marketing/single-send-email?type=ticketed-event-promo',
  },
  product_announcement_email: {
    source: 'Product Announcement',
    route: '/marketing/single-send-email?type=new-product',
  },
  product_announcement_sms: {
    source: 'Product Promo',
    route: '/marketing/single-send-sms?type=product-promo',
  },
  presale_announcement_email: {
    source: 'Presale Announcement',
    route: '/marketing/single-send-email?type=new-presale',
  },
  presale_announcement_sms: {
    source: 'Presale Promo',
    route: '/marketing/single-send-sms?type=presale-promo',
  },
  availability_announcement: {
    source: 'Custom Order Availability',
    route: '/marketing/single-send-email?type=custom-order-available',
  },
  coupon_announcement: {
    source: 'Coupon Promo',
    route: '/marketing/single-send-sms?type=coupon-promo',
  },
  event_announcement: {
    source: 'Event Promo',
    route: '/marketing/single-send-sms?type=event-promo',
  },
  sms_signup: {
    source: 'Subscribe to SMS Request',
    route: '/marketing/single-send-email?type=sms-signup',
  },
  easter: {
    source: 'Easter',
    route: '/marketing/email',
  },
  easter_sms: {
    source: 'Easter',
    route: '/marketing/sms',
  },
  stpatricksday: {
    source: "St. Patrick's Day",
    route: '/marketing/email',
  },
  stpatricksday_sms: {
    source: "St. Patrick's Day",
    route: '/marketing/sms',
  },
  mothersday: {
    source: "Mother's Day",
    route: '/marketing/email',
  },
  '4thofjuly': {
    source: '4th of July',
    route: '/marketing/email',
  },
  '4th_of_july_template': {
    source: '4th of July',
    route: '/marketing/email',
  },
  '4th_of_july_announcement': {
    source: '4th of July',
    route: '/marketing/sms',
  },
  graduation: {
    source: 'Graduation',
    route: '/marketing/email',
  },
  graduation_sms: {
    source: 'Graduation',
    route: '/marketing/sms',
  },
  fathersday: {
    source: "Father's Day",
    route: '/marketing/email',
  },
  fathers_day_announcement: {
    source: "Father's Day",
    route: '/marketing/sms',
  },
  backtoschool: {
    source: 'Back to School',
    route: '/marketing/email',
  },
  backtoschool_sms: {
    source: 'Back to School',
    route: '/marketing/sms',
  },
  fall: {
    source: 'Fall',
    route: '/marketing/email',
  },
  fall_sms: {
    source: 'Fall',
    route: '/marketing/sms',
  },
  halloween: {
    source: 'Halloween',
    route: '/marketing/email',
  },
  halloween_sms: {
    source: 'Halloween',
    route: '/marketing/sms',
  },
  thanksgiving: {
    source: 'Thanksgiving',
    route: '/marketing/email',
  },
  thanksgiving_sms: {
    source: 'Thanksgiving',
    route: '/marketing/sms',
  },
  black_friday: {
    source: 'Black Friday',
    route: '/marketing/email',
  },
  black_friday_sms: {
    source: 'Black Friday',
    route: '/marketing/sms',
  },
  small_business_saturday: {
    source: 'Small Business Saturday',
    route: '/marketing/email',
  },
  small_business_saturday_sms: {
    source: 'Small Business Saturday',
    route: '/marketing/sms',
  },
  cyber_monday: {
    source: 'Cyber Monday',
    route: '/marketing/email',
  },
  cyber_monday_sms: {
    source: 'Cyber Monday',
    route: '/marketing/sms',
  },
  christmas: {
    source: 'Christmas',
    route: '/marketing/email',
  },
  christmas_sms: {
    source: 'Christmas',
    route: '/marketing/sms',
  },
  hanukkah: {
    source: 'Hanukkah',
    route: '/marketing/email',
  },
  hanukkah_sms: {
    source: 'Hanukkah',
    route: '/marketing/sms',
  },
  kwanzaa: {
    source: 'Kwanzaa',
    route: '/marketing/email',
  },
  kwanzaa_sms: {
    source: 'Kwanzaa',
    route: '/marketing/sms',
  },
  nye: {
    source: "New Year's Eve",
    route: '/marketing/email',
  },
  nye_sms: {
    source: "New Year's Eve",
    route: '/marketing/sms',
  },
  valentinesday: {
    source: "Valentine's Day",
    route: '/marketing/email',
  },
  valentinesday_sms: {
    source: "Valentine's Day",
    route: '/marketing/sms',
  },
  thebiggame: {
    source: "The Big Game",
    route: '/marketing/email',
  },
  thebiggame_sms: {
    source: "The Big Game",
    route: '/marketing/sms',
  },
  'shop-newsletter': {
    source: 'Newsletter',
    route: '/marketing/automations',
  },
  hs_email: {
    source: 'Newsletter',
    route: '/marketing/automations',
    isAutomation: true,
  },
  Newsletter: {
    source: 'Newsletter',
    route: '/marketing/automations',
    isAutomation: true,
  },
  newsletter_series_1: {
    source: 'Newsletter',
    route: '/marketing/automations',
    isAutomation: true,
  },
  newsletter_series_2: {
    source: 'Newsletter',
    route: '/marketing/automations',
    isAutomation: true,
  },
  'subscriber-coupon': {
    source: 'Welcome Series',
    route: '/marketing/automations',
    isAutomation: true,
  },
  welcome_series_new_subscriber: {
    source: 'Welcome Series',
    route: '/marketing/automations',
    isAutomation: true,
  },
  welcome_series_new_social: {
    source: 'Welcome Series',
    route: '/marketing/automations',
    isAutomation: true,
  },
  welcome_series_new_products: {
    source: 'Welcome Series',
    route: '/marketing/automations',
    isAutomation: true,
  },
  thank_you_coupon: {
    source: 'Thank You Offer',
    route: '/marketing/automations',
    isAutomation: true,
  },
  customer_standard_order_receipt: {
    source: 'Order Confirmation',
    isAutomation: true,
  },
  'customer-custom-order-updated': {
    source: 'Custom Quote Updated',
    isAutomation: true,
  },
  artisan_quote_notification: {
    source: 'New Custom Request - Seller',
    isAutomation: true,
  },
  'quote-payment-reminder': {
    source: 'Custom Quote Payment Reminder',
    isAutomation: true,
  },
  artisan_custom_order_notification: {
    source: 'New Custom Request - Seller',
    isAutomation: true,
  },
  'customer-order-fulfilled': {
    source: 'Order Fulfilled',
    isAutomation: true,
  },
  customer_quote_ready: {
    source: 'Custom Quote',
    isAutomation: true,
  },
  'artisan-custom-order-ready': {
    source: 'Custom Quote - Seller',
    isAutomation: true,
  },
  'customer-custom-order-rejected': {
    source: 'Custom Request Rejected',
    isAutomation: true,
  },
  'artisan-custom-order-rejected': {
    source: 'Custom Request Rejected - Seller',
    isAutomation: true,
  },
  'artisan-upload-subscriber': {
    source: 'Subscribe to Email Request',
    isAutomation: true,
  },
  'launch-announcement': {
    source: 'Shop Launch',
    isAutomation: true,
  },
  facebook: {
    source: 'Facebook',
    isExternal: true,
  },
  instagram: {
    source: 'Instagram',
    isExternal: true,
  },
  'theknot.com': {
    source: 'The Knot',
    isExternal: true,
  },
  adwords: {
    source: 'Google Ads',
    isExternal: true,
  },
  zola: {
    source: 'Zola',
    isExternal: true,
  },
  castiron_marketplace_specials: {
    source: 'Castiron Marketplace',
    isExternal: true,
  },
  fb: {
    source: 'Facebook',
    isExternal: true,
  },
  ig: {
    source: 'Instagram',
    isExternal: true,
  },
  google: {
    source: 'Google',
    isExternal: true,
  },
  gmc: {
    source: 'Google',
    isExternal: true,
  },
};

const OrderSource: React.FC<Props> = (props: Props) => {
  const { attribution } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const identifiers = [...Object.keys(orderSourceContent), 'product_announcement', 'presale_announcement'];
  const campaign = attribution?.utmParams?.campaign;
  const source = attribution?.utmParams?.source || attribution?.source;
  const identifier = identifiers.includes(campaign) ? campaign : identifiers.includes(source) ? source : null;
  if (!identifier) return null;
  const medium = ['ppc', 'paid']?.includes(attribution?.utmParams?.medium)
    ? 'Paid Ads'
    : identifier === 'castiron_marketplace_specials'
    ? 'Referral'
    : attribution?.utmParams?.medium === 'sms'
    ? 'SMS'
    : (source === 'google' && (attribution.entryType === 'seo' || attribution?.entryType === 'organic')) ||
      source === 'gmc'
    ? 'Organic'
    : _.capitalize(attribution?.utmParams?.medium);

  let content: { source: string; route?: string; isAutomation?: boolean; isExternal?: boolean };
  if (['product_announcement', 'presale_announcement'].includes(identifier)) {
    content = orderSourceContent[`${identifier}_${_.lowerCase(medium)}`];
  } else {
    content = orderSourceContent[identifier];
  }

  const icon = content.isAutomation ? (
    <BoltIconOutlined className={classes.icon} />
  ) : content.isExternal ? (
    <LanguageIcon className={classes.icon} />
  ) : medium === 'Email' ? (
    <MailOutlineIcon className={classes.icon} />
  ) : medium === 'SMS' ? (
    <ChatBubbleOutlineIcon className={classes.icon} />
  ) : null;

  const ctaBlockText = content.isAutomation
    ? 'Automations nurture customer relationships and drive sales automatically.'
    : 'Connecting with customers through email and SMS marketing drives sales.';
  const ctaText = content.isAutomation
    ? 'Manage Automations'
    : medium === 'Email'
    ? 'Start New Email'
    : medium === 'SMS'
    ? 'Start New Text'
    : '';
  const ctaRoute = content.route;
  const ctaBlock = ctaBlockText && ctaText && ctaRoute && (
    <Grid container item direction="column" className={classes.ctaBlock}>
      <Typography variant="body2">{ctaBlockText}</Typography>
      <Typography variant="button" className={classes.cta} onClick={() => history.push(ctaRoute)}>
        {ctaText}
      </Typography>
    </Grid>
  );

  return (
    <Card title="Order Source">
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="subtitle2">Medium</Typography>
          <Grid container item wrap="nowrap" alignItems="center" className={classes.data}>
            {icon}
            <Typography variant="body1">{medium}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">Source</Typography>
          <Typography variant="body1" className={classes.data}>
            {content.source}
          </Typography>
        </Grid>
        {ctaBlock}
      </Grid>
    </Card>
  );
};

export default OrderSource;
