import {Hidden} from '@material-ui/core';
import React from 'react';
import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';

type Props = {
  isAuth?: boolean;
  stickyFooterClass?: string;
};

declare global {
  interface Window {
    Beacon(method: string, id?: any): void;
    zE(widget: string, action: string, jwtCallback?: (callback) => void, loginCallback?: (error) => void): void;
  }
}

const Footer: React.FC<Props> = (props: Props) => {
  const { isAuth, stickyFooterClass } = props;

  return (
    <footer>
      <Hidden mdUp>
        <FooterMobile stickyFooterClass={stickyFooterClass} />
      </Hidden>
      <Hidden smDown>
        <FooterDesktop isAuth={isAuth} stickyFooterClass={stickyFooterClass} />
      </Hidden>
    </footer>
  );
};

export default Footer;
