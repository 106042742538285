import { Switch, SwitchProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props extends SwitchProps {
  label?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
  },
  rootWithLabel: {
    width: 42,
    height: 26,
    padding: 0,
    marginRight: 10,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.branding.v2.gray[0],
      '& + $track': {
        backgroundColor: theme.branding.v2.blue[500],
        opacity: 1,
        border: 'none',
      },
      '&$disabled': {
        '& + $track': {
          backgroundColor: `${theme.branding.v2.gray[500]}`,
          opacity: '0.5 !important',
        },
      },
    },
    '&$focusVisible $thumb': {
      color: theme.branding.v2.blue[500],
      border: `6px solid ${theme.branding.v2.gray[0]}`,
    },
    '&$disabled': {
      '& + $track': {
        backgroundColor: `${theme.branding.v2.gray[500]}`,
        opacity: '0.5 !important',
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: '13px',
    border: `1px solid ${theme.branding.v2.gray[400]}`,
    backgroundColor: theme.branding.v2.gray[50],
    opacity: 1,
  },
  checked: {},
  disabled: {},
  focusVisible: {},
}));

const CustomSwitch: React.FC<Props> = (props: Props) => {
  const { className, label, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: label ? classes.rootWithLabel : classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...restOfProps}
    />
  );
};

export default CustomSwitch;
