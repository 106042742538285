import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { Banner, DiscardButton, SaveButton, Typography } from '@castiron/components';
import {
  addressSchema,
  backendStateToFrontendState,
  calculateTotals,
  Customer,
  enrichLineItemWithCustomProductInformation,
  FrontendTransactionState,
  FulfillmentOption,
  getProductStatus,
  LineItem,
  Order,
  SubLineItem,
  SubscriberOrigination,
  Transaction,
  updateOrderTotals,
} from '@castiron/domain';
import { defaultTimeZone, removeEmpty, removeEmptyStrings, useTracking } from '@castiron/utils';
import { customerRepository, shopRepository, transactionRepository } from '../../../domain';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createCustomerAction, updateCustomerAction } from '../../../store/reducers/customers';
import { getProductsAction } from '../../../store/reducers/products';
import AdminForm from '../../AdminForm';
import { LayoutPageProps } from '../../Layout';
import Spinner from '../../Spinner';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import QuoteActionsDropdown from '../QuoteActionsDropdown';
import { prepareQuoteSegmentData, quoteSendErrors, validateQuote } from '../QuoteUtils';
import QuoteCustomer from './QuoteCustomer/QuoteCustomer';
import QuoteDetails from './QuoteDetails';
import QuoteExpire from './QuoteExpire';
import QuoteFulfillment from './QuoteFulfillment';
import QuotePayment from './QuotePayment';
import DepositPayment from './DepositPayment';
import QuoteRequestDetails from './QuoteRequestDetails';
import SendButton from './SendButton';
import firebase from 'firebase/compat/app';
import LogFormikErrors from '@castiron/components/build/Forms/LogFormikErrors';
import QuoteNotes from './QuoteNotes';
import HeaderTabs from '../../Layout/Header/HeaderTabs';
import { trackHubSpotContactPage } from '../../../lib/trackHubSpotContactEvent';
import moment from 'moment-timezone';
import { getRefundProps } from '../../Refunds/refundUtils';
import RefundDetails from '../../Refunds/RefundDetails';
import RefundBanner from '../../Refunds/RefundBanner';

const sendQuoteUpdatedService = getService('orders', 'sendquoteupdatedemail');
const createCustomOrderService = getService('orders', 'createcustomorderv2');

const useStyles = makeStyles((theme: Theme) => ({
  bannerMargin: {
    marginBottom: '16px',
  },
  contentDesktop: {
    paddingTop: 16,
  },
  contentMobile: {
    padding: 16,
  },
  error: {
    display: 'inline',
    color: theme.branding.v2.deepOrange[400],
  },
  errorContainer: {
    marginBottom: '16px',
  },
  errorHeading: {
    fontWeight: 600,
  },
  viewOrderLink: {
    color: 'inherit',
    fontWeight: 700,
    '&:hover': {
      color: 'inherit',
    },
  },
}));

export interface SubTransactionValues {
  hasSubTransactions?: boolean;
  amount?: number;
  amountType?: string;
  paymentSentDuePeriod?: '24hours' | '0day' | '7days' | '14days' | 'none';
  paymentDueDate?: number;
  percentageAmount?: number;
}

export interface QuoteValues {
  order: Order & { expirationPeriod: 'none' | 'date' };
  customer: Customer;
  redirectAfterSave: boolean;
  markedAsPaid: boolean;
  subTransactions?: SubTransactionValues;
}

const orderSchema = yup.object().shape({
  order: yup.object().shape({
    orderNumber: yup.string(),
    type: yup.string(),
    items: yup.array<LineItem>().of(
      yup.object().shape({
        id: yup.string(),
        title: yup.string(),
        description: yup.string(),
        category: yup.object().shape({
          id: yup.string(),
          name: yup.string(),
        }),
        price: yup.number(),
        quantity: yup.number(),
        subLineItems: yup.array<SubLineItem>().of(
          yup.object().shape({
            id: yup.string().nullable(),
            title: yup.string().nullable(),
            price: yup.number().nullable(),
            quantity: yup.number().nullable(),
            notes: yup.string().nullable(),
          }),
        ),
        subtotal: yup.number(),
        total: yup.number(),
        type: yup.string(),
        policies: yup.string(),
        notes: yup.string(),
      }),
    ),
    fulfillmentOption: yup.object().shape({
      status: yup.string().oneOf(['active', 'inactive']),
      type: yup.string(),
      displayName: yup.string(),
      description: yup.string(),
      id: yup.string(),
      afterPurchaseDetails: yup.string().nullable(),
      recipient: yup.string().nullable(),
      address: addressSchema(false),
      fee: yup.number().nullable(),
      minimum: yup.number().nullable(),
      date: yup.number().nullable(),
      useBusinessAddress: yup.boolean(),
    }),
    orderTotal: yup.number().nullable(),
    origination: yup.string().nullable(),
    paymentSentDuePeriod: yup
      .string()
      .oneOf(['0day', '24hours', '7days', '14days', 'none'])
      .nullable(),
    paymentDueDate: yup.number().nullable(),
    expirationPeriod: yup
      .string()
      .oneOf(['none', 'date'])
      .nullable(),
    expirationDate: yup.number().nullable(),
    sendPickupReminderEmail: yup.boolean().nullable(),
  }),
  customer: yup.object().shape({
    email: yup.string().nullable(),
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    mobileNumber: yup.string().nullable(),
    notes: yup.string().nullable(),
    shopId: yup.string().nullable(),
    subscribed: yup.boolean().nullable(),
  }),
  redirectAfterSave: yup.boolean().default(true),
  subTransaction: yup.object().shape({
    hasSubTransactions: yup.boolean().nullable(),
    amount: yup.number().nullable(),
    percentage: yup.number().nullable(),
    amountType: yup.string().nullable(),
    paymentSentDuePeriod: yup
      .string()
      .oneOf(['24hours', '0days', '7days', '14days', 'none'])
      .nullable(),
    paymentDueDate: yup.number().nullable(),
    percentageAmount: yup
      .number()
      .nullable()
      .min(1, 'Number must be at least 1')
      .max(99, 'Number must be less than or equal to 99'),
  }),
});

const EditQuote: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const {
    setPageTitle,
    setBackLocation,
    setHeaderCTAs,
    setFooterCTAs,
    setHeaderTransaction,
    setHeaderTransactionContext,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [order, setOrder] = useState<Order>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [transaction, setTransaction] = useState<Transaction>();
  const [subTransactions, setSubTransactions] = useState<Transaction[]>();
  const [hasSubTransactions, setHasSubTransactions] = useState<boolean>(false);
  const [transactionState, setTransactionState] = useState<FrontendTransactionState>();
  const [transactionChanged, setTransactionChanged] = useState(false);
  const [showSavingSpinner, setShowSavingSpinner] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const formikRef = useRef<FormikProps<QuoteValues>>();
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [isInvoice, setIsInvoice] = useState<boolean>(false);
  const [isOwnerInitiated, setIsOwnerInitiated] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('Quote');
  const [refundTotal, setRefundTotal] = useState<number>(0);
  const [amountAvailableToRefund, setAmountAvailableToRefund] = useState(0);

  const location = useLocation<{ markedAsPaid: boolean }>();
  const { search } = useLocation();
  const { markedAsPaid } = location?.state || {};
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const errorCode = query.get('error');

  const { shop, isProductsLoading, customers, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    isProductsLoading: state.products.loading,
    customers: state.customers.customers,
    products: state.products.products,
  }));

  const timeZone = shop.config?.timeZone || defaultTimeZone;
  const initialDummyFulfillmentKey = '--Initial--';
  const initialDummyCustomerKey = '--Initial--';
  const initialPopulatedAddress =
    (transaction?.shippingInfo?.address?.fullAddress && transaction.shippingInfo.address) ||
    (transaction?.order?.fulfillmentOption?.address?.fullAddress && transaction.order.fulfillmentOption.address) ||
    (transaction?.order?.requestedFulfillment?.address?.fullAddress && transaction.order.requestedFulfillment.address);
  const initialValues: QuoteValues = {
    order: {
      orderNumber: transaction?.order.orderNumber || 'unknown',
      stage: 'quote',
      type: 'custom',
      items: [
        {
          id: transaction?.order.items[0]?.id || (isInvoice ? 'invoice' : ''),
          title: transaction?.order.items[0]?.title || '',
          description: transaction?.order.items[0]?.description || '',
          category: {
            id: transaction?.order.items[0]?.category?.id || '',
            name: transaction?.order.items[0]?.category?.name || '',
          },
          price: transaction?.order.items[0]?.price || 0,
          quantity: transaction?.order.items[0]?.quantity || 1,
          subLineItems: transaction?.order.items[0]?.subLineItems || [],
          subtotal: transaction?.order.items[0]?.subtotal || 0,
          total: transaction?.order.items[0]?.total || 0,
          type: transaction?.order.items[0]?.type || (isInvoice ? 'invoice' : 'custom'),
          policies: transaction?.order.items[0]?.policies || '',
          notes: transaction?.order.items[0]?.notes || '',
        },
      ],
      fulfillmentOption: {
        status: transaction?.order.fulfillmentOption?.status || 'active',
        type: transaction?.order.fulfillmentOption?.type || 'delivery',
        displayName: transaction?.order.fulfillmentOption?.displayName || initialDummyFulfillmentKey,
        description: transaction?.order.fulfillmentOption?.description || '',
        id: transaction?.order.fulfillmentOption?.id,
        afterPurchaseDetails: transaction?.order.fulfillmentOption?.afterPurchaseDetails || '',
        recipient: transaction?.shippingInfo?.recipientName,
        address: initialPopulatedAddress || {
          fullAddress: transaction?.shippingInfo?.address?.fullAddress || '',
          addressLine1: transaction?.shippingInfo?.address?.addressLine1 || '',
          addressLine2: transaction?.shippingInfo?.address?.addressLine2 || '',
          city: transaction?.shippingInfo?.address?.city || '',
          region: transaction?.shippingInfo?.address?.region || '',
          regionName: transaction?.shippingInfo?.address?.regionName || '',
          postalCode: transaction?.shippingInfo?.address?.postalCode || '',
          country: transaction?.shippingInfo?.address?.country || '',
        },
        fee: transaction?.order.fulfillmentOption?.fee || 0,
        minimum: transaction?.order.fulfillmentOption?.minimum || 0,
        notes: transaction?.order.fulfillmentOption?.notes || '',
        //@ts-ignore
        date: transaction?.order.fulfillmentOption?.date || null,
        schedule: transaction?.order.fulfillmentOption?.schedule || null,
        sendPickupReminderEmail: transaction?.order.fulfillmentOption?.sendPickupReminderEmail || false,
        useBusinessAddress: !!transaction?.order?.fulfillmentOption?.useBusinessAddress || false,
      },
      orderTotal: transaction?.order.orderTotal || 0,
      origination: transaction?.order.origination || '',
      paymentSentDuePeriod: transaction?.order.paymentSentDuePeriod || '24hours',
      paymentDueDate: transaction?.order.paymentDueDate || null,
      expirationPeriod: transaction?.order?.expirationDate ? 'date' : 'none',
      expirationDate: transaction?.order?.expirationDate || null,
      requestedFulfillment: {
        address: transaction?.order.requestedFulfillment?.address || null,
        type: transaction?.order.requestedFulfillment?.type || null,
        date: transaction?.order.requestedFulfillment?.date || null,
      },
      notes: {
        text: transaction?.order?.notes?.text || '',
        images: transaction?.order?.notes?.images || [],
      },
    },
    customer: {
      email: transaction?.customerObj?.email || initialDummyCustomerKey,
      firstName: transaction?.customerObj?.firstName || '',
      id: transaction?.customerObj?.id || undefined,
      lastName: transaction?.customerObj?.lastName || '',
      mobileNumber: transaction?.customerObj?.mobileNumber || '',
      notes: transaction?.customerObj?.notes || '',
      shopId: shop.id,
      subscribed: transaction?.customerObj?.subscribed || false,
    },
    redirectAfterSave: true,
    markedAsPaid: false,
    subTransactions: {
      hasSubTransactions: subTransactions?.length > 0 ? true : false,
      amount: subTransactions?.length > 0 ? subTransactions[0]?.totals?.subtotal || 0 : 0,
      amountType: subTransactions?.length > 0 ? subTransactions[0]?.order?.amountType || 'dollars' : 'dollars',
      paymentSentDuePeriod:
        subTransactions?.length > 0 ? subTransactions[0]?.order.paymentSentDuePeriod || '24hours' : '24hours',
      paymentDueDate: subTransactions?.length > 0 ? subTransactions[0].order.paymentDueDate || null : null,
      percentageAmount: subTransactions?.length > 0 ? subTransactions[0]?.order.percentageAmount || 0 : 0,
    },
  };

  const getOrder = async id => {
    const response = await transactionRepository.get(id);
    if (response == null) history.push('/quotes');
    if (!initialTransaction.current) {
      initialTransaction.current = response;
    }
    setTransaction(response);
    const subTransactions = await response.getSubTransactions();
    setSubTransactions(subTransactions);
    setHasSubTransactions(subTransactions.length > 0);
    setOrder(response.order);

    const customProducts = products.filter(product => {
      const status = getProductStatus(product, timeZone);
      return (
        (product.type === 'custom' && (status === 'active' || status === 'scheduled')) ||
        product.id === _.head(response?.order?.items)?.id
      );
    });
    setIsInvoice(response?.order?.items[0]?.type === 'invoice' || (customProducts && customProducts.length === 0));

    let hereTitle = title;
    if (response?.order?.origination === 'artisan') {
      setIsOwnerInitiated(true);
      setTitle('Invoice');
      hereTitle = 'Invoice';
    }
    setPageTitle(`${hereTitle} ${response.order.orderNumber}`);
    setHeaderTransaction(response);
    setHeaderTransactionContext('quote');
    setBackLocation(true);
  };

  const { id } = useParams<{ id: string }>();
  const initialTransaction = useRef<Transaction>();

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrder(id);

    return () => {
      setPageTitle('');
      setBackLocation(false);
      setHeaderTransaction(null);
      setHeaderTransactionContext(null);
    };
  }, [products]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrder(id);
  }, [errorCode]);

  useEffect(() => {
    if (transaction) {
      setIsPaid(transaction.transactionStatus === 'succeeded' || transaction.transactionStatus === 'partially-paid');
      setTransactionState(backendStateToFrontendState(transaction, 'quote'));

      const { refundTotal, refundInfo } = getRefundProps(transaction, subTransactions);
      setAmountAvailableToRefund(refundInfo?.refundableTotal);
      setRefundTotal(refundTotal);
    }

    if (!_.isEqual(initialTransaction.current, transaction)) {
      setTransactionChanged(true);
    }

    const params = Object.fromEntries(new URLSearchParams(location?.search));

    setHeaderCTAs([
      <QuoteActionsDropdown
        transaction={transaction}
        formikRef={formikRef}
        editing
        hasSubTransactions={hasSubTransactions}
      />,
    ]);

    return () => {
      setHeaderCTAs([]);
    };
  }, [transaction, subTransactions, hasSubTransactions]);

  useEffect(() => {
    !isPaid && transactionState !== 'canceled' && transactionState !== 'archived' && transactionState !== 'rejected'
      ? setFooterCTAs([
          <DiscardButton isSubmitting={submitting} backLocation="/quotes" />,
          <SaveButton
            isSubmitting={submitting}
            formikState={formikRef.current}
            customName={transactionState === 'pending' ? 'Save and Send' : 'Save'}
          />,
          transactionState !== 'pending' && (
            <SendButton
              transaction={transaction}
              editing={true}
              disabled={submitting}
              formikContext={formikRef.current}
            />
          ),
        ])
      : setFooterCTAs([]);

    return () => {
      setFooterCTAs([]);
    };
  }, [transactionState, submitting, formikRef]);

  useEffect(() => {
    const getProducts = async id => {
      dispatch(getProductsAction(id));
    };

    if (shop?.id) {
      getProducts(shop.id);
    }
  }, [shop]);

  const handleSubmit = (sendUpdateEmailAfterSave: boolean) => async (values: QuoteValues) => {
    setSubmitting(true);

    //Manually doing error checking since yup is not enforcing

    if (
      values?.subTransactions?.amountType == 'percentage' &&
      (values?.subTransactions?.percentageAmount <= 0 || values?.subTransactions?.percentageAmount >= 100)
    ) {
      await formikRef.current.setFieldError('subTransactions.percentageAmount', 'Percentage must be between 0 and 100');
      setSubmitting(false);
      return;
    }
    if (values?.order?.paymentSentDuePeriod == undefined && !values?.order?.paymentDueDate) {
      await formikRef.current.setFieldError('order.paymentDueDate', 'Need Date');
      setSubmitting(false);
      return;
    }
    if (values?.subTransactions?.paymentSentDuePeriod == undefined && !values?.subTransactions?.paymentDueDate) {
      await formikRef.current.setFieldError('subTransactions.paymentDueDate', 'Need Date');
      setSubmitting(false);
      return;
    }
    if (values?.order?.expirationPeriod == 'date' && !values?.order?.expirationDate) {
      await formikRef.current.setFieldError('order.expirationDate', 'Need Date');
      setSubmitting(false);
      return;
    }

    if (
      (values.customer.firstName ||
        values.customer.lastName ||
        values.customer.lastName ||
        values.customer.mobileNumber ||
        values.customer.notes ||
        values.customer.subscribed) &&
      (!values.customer.email || values.customer.email === initialDummyCustomerKey)
    ) {
      history.push(`/quotes/edit/${transaction.id}?error=missingEmail`);
      window.scrollTo(0, 0);
      return;
    }

    let updatedCustomer: Customer;

    if (values.customer.email && values.customer.email != initialDummyCustomerKey) {
      setShowSavingSpinner(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });

      const newCustomerData = removeEmpty(
        removeEmptyStrings({
          ...values.customer,
          name: undefined,
          existingCustomers: undefined,
          subscriberOrigination: values.customer.subscribed
            ? ('admin-update-customer-form' as SubscriberOrigination)
            : undefined,
        }),
      );

      const response = values.customer.id
        ? await dispatch(updateCustomerAction(newCustomerData))
        : await dispatch(createCustomerAction(newCustomerData as Customer));
      updatedCustomer = await customerRepository.get(values.customer.id ? values.customer.id : response.payload['id']);

      if (values.customer.id) {
        trackEvent(`Customer Updated`, {
          action: 'update',
          url: window.location.href,
          customer: response.payload,
          shopId: shop.id,
        });
      }

      if (!values.order.fulfillmentOption.recipient && updatedCustomer?.firstName) {
        // if we don't have a fulfillment recipient, set it while we're updating customer
        values.order.fulfillmentOption.recipient =
          updatedCustomer.firstName + (updatedCustomer.lastName ? ` ${updatedCustomer.lastName}` : '');
      }
    }

    try {
      /* HACK: for some reason js is not letting us remove attribution or any properties of attribution, causing errors in removeEmpty below
       * just omit it out here for now
       */
      const unsafeCustomerObj = updatedCustomer
        ? updatedCustomer
        : values.customer.email === initialDummyCustomerKey
        ? undefined
        : customers.find(cust => cust.id === values.customer.id);

      const customerObj = _.omit(unsafeCustomerObj, 'attribution');

      //only allow send pickup reminder if there is a pickup address
      const sendPickupReminder =
        !!!values.order?.fulfillmentOption?.address || values.order?.fulfillmentOption?.address?.fullAddress === ''
          ? false
          : values.order?.fulfillmentOption?.sendPickupReminderEmail;

      const orderValues = _.omit(values.order, 'expirationPeriod');
      /* transaction in state isn't updated when the formik form is updated so compare the orders directly */
      const newTransaction = {
        ...transaction,
        order: {
          ...orderValues,
          fulfillmentOption: {
            ...values.order.fulfillmentOption,
            sendPickupReminder,
          },
        },
        customerObj,
        totals: calculateTotals({
          order: orderValues,
          paymentSettings: shop.paymentSettings,
        }),
      };

      const hasSubTransactionsChanged = !_.isEqual(initialValues.subTransactions, values.subTransactions);

      if (
        transactionChanged ||
        !_.isEqual(initialTransaction.current.order, orderValues) ||
        !_.isEqual(initialTransaction.current.customerObj, updatedCustomer) ||
        hasSubTransactionsChanged
      ) {
        if (!showSavingSpinner) setShowSavingSpinner(true);
        trackEvent('Quote Action Clicked', {
          action: 'save',
          url: window.location.href,
          ...prepareQuoteSegmentData(newTransaction),
        });
        const previousStatus = backendStateToFrontendState(initialTransaction.current, 'quote');
        const newStatus = previousStatus === 'pending' ? 'agreed' : 'proposed';
        const newFrontendStatus = newStatus === 'agreed' ? 'pending' : 'draft';

        const formItem = values.order.items[0];
        const txItem = transaction.order?.items[0] || {};
        const combinedItem = { ...txItem, ...formItem };
        const enrichedLineItem = enrichLineItemWithCustomProductInformation(combinedItem, products);

        /* forgive me this sin, best way I can come up with to avoid saving the default data that isn't displayed to the user */
        const fulfillmentOption: FulfillmentOption =
          values.order.fulfillmentOption?.displayName === initialDummyFulfillmentKey
            ? undefined
            : {
                ...values.order.fulfillmentOption,
                /* since none of these are actual fulfillment options from the subcollection,
                 * just set the status to 'active' always
                 * this will help with transition from array property to subcollection
                 */
                status: 'active',
                /* id is set here instead of initialValues to avoid formik reinitializing initial values (i.e. clearing data) when useBusinessAddress is true and shop has no physicalAddress */
                id: values?.order?.fulfillmentOption?.id || nanoid(),
              };

        const order = {
          ...orderValues,
          items: !!enrichedLineItem?.id ? [enrichedLineItem] : [],
          fulfillmentOption,
          paymentSentDuePeriod: values?.order?.paymentSentDuePeriod,
          paymentDueDate: values?.order?.paymentDueDate,
        };

        const totals = calculateTotals({ order, paymentSettings: shop.paymentSettings });
        const customerName = customerObj?.firstName ? `${customerObj.firstName} ${customerObj.lastName}` : undefined;

        if (values?.subTransactions?.amountType == 'dollars' && values?.subTransactions?.amount > totals?.subtotal) {
          await formikRef.current.setFieldError(
            'subTransactions.amount',
            "Deposit amount can't be greater than balance",
          );
          setSubmitting(false);
          setShowSavingSpinner(false);
          return;
        }

        const updatedOrder = updateOrderTotals(order);

        const txUpdateProps = removeEmpty({
          order: updatedOrder,
          shippingInfo: {
            recipientName: values.order.fulfillmentOption.recipient
              ? values.order.fulfillmentOption.recipient
              : customerName,
            address: values.order.fulfillmentOption.address?.fullAddress
              ? values.order.fulfillmentOption.address
              : undefined,
          },
          totals,
          status: newStatus,
          customer: customerObj ? customerObj.id : firebase.firestore.FieldValue.delete(),
          customerObj:
            initialTransaction?.current?.customerObj && !customerObj.id
              ? firebase.firestore.FieldValue.delete()
              : customerObj,
        });

        await transactionRepository.updateProps(transaction.id, txUpdateProps);

        var subTransactionTotal = 0;

        if (values.subTransactions.amountType == 'dollars') {
          subTransactionTotal = values.subTransactions.amount;
        } else {
          subTransactionTotal = Math.round((values.subTransactions.percentageAmount / 100) * totals.subtotal);
        }

        const calclatedSubTransactionTotals = calculateTotals({
          order: orderValues,
          paymentSettings: shop.paymentSettings,
          subTransactionTotal: subTransactionTotal,
        });

        if (
          hasSubTransactionsChanged ||
          !_.isEqual(initialTransaction.current.order, orderValues) ||
          !_.isEqual(initialTransaction.current.customerObj, updatedCustomer)
        ) {
          console.debug('in sub transaction conditional');

          const recipientName = values.order.fulfillmentOption.recipient || customerName;
          const address = values.order.fulfillmentOption.address?.fullAddress
            ? values.order.fulfillmentOption.address
            : undefined;

          const updatedSubTransactionOrder = {
            ...updatedOrder,
            paymentSentDuePeriod: values?.subTransactions?.paymentSentDuePeriod,
            paymentDueDate: values?.subTransactions?.paymentDueDate,
            amountType: values?.subTransactions?.amountType,
            percentageAmount: values?.subTransactions?.percentageAmount,
          };

          const updatedSubTransaction = removeEmpty({
            type: 'sub-transaction',
            parentId: transaction?.id,
            order: updatedSubTransactionOrder,
            shippingInfo: {
              recipientName,
              address,
            },
            totals: calclatedSubTransactionTotals,
            status: newStatus,
            customer: customerObj ? customerObj.id : firebase.firestore.FieldValue.delete(),
            customerObj:
              initialTransaction?.current?.customerObj && !customerObj.id
                ? firebase.firestore.FieldValue.delete()
                : customerObj,
          });

          try {
            if (subTransactions?.length > 0) {
              if (values?.subTransactions?.hasSubTransactions) {
                await transactionRepository.updateProps(subTransactions[0].id, updatedSubTransaction);
              } else {
                // Remove parent relationship this is added back if they use depsosits again
                await transactionRepository.updateProps(subTransactions[0].id, { parentId: null });
              }
            } else {
              if (values?.subTransactions?.hasSubTransactions) {
                const allOrderTransactions = await transactionRepository.find({
                  where: [
                    { field: 'shopId', operator: '==', value: transaction.shopId },
                    { field: 'order.orderNumber', operator: '==', value: transaction.order.orderNumber },
                  ],
                });
                const existingTransaction = allOrderTransactions.filter(t => t.id !== transaction.id);
                if (existingTransaction.length == 1) {
                  // Reenable deposit relationship if did exist
                  await transactionRepository.updateProps(existingTransaction[0].id, updatedSubTransaction);
                } else {
                  const body = {
                    id: shop.id,
                    status: newStatus,
                    origination: 'artisan',
                    type: 'sub-transaction',
                  };
                  const createCustomOrderResponse = await createCustomOrderService(body);
                  console.debug('Create sub transaction custom product order response: ', createCustomOrderResponse);
                  const { id } = createCustomOrderResponse;
                  await transactionRepository.updateProps(id, updatedSubTransaction);
                }
              }
            }
          } catch (error) {
            console.error('Error handling sub-transaction:', error);
          }
        }

        if (previousStatus !== newFrontendStatus) {
          trackEvent('Quote Status Changed', {
            url: window.location.href,
            previousStatus,
            newStatus: newFrontendStatus,
            ...prepareQuoteSegmentData(newTransaction),
          });
        }

        if (values.order.expirationDate) {
          trackEvent('Quote Expiration Date Added', {
            previousExpirationDate: initialValues.order.expirationDate,
            newExpirationDate: values.order.expirationDate,
            hasDeposit: !!values?.subTransactions?.hasSubTransactions,
            ...prepareQuoteSegmentData(newTransaction),
          });
        }

        if (!values.markedAsPaid && sendUpdateEmailAfterSave) {
          if (validateQuote(transaction).length > 0) {
            history.push(`/quotes/edit/${transaction.id}?error=missingRequired`);
            window.scrollTo(0, 0);
            return;
          }
          const subtotal = totals?.subtotal || 0;
          if (subtotal < 50) {
            history.push(`/quotes/edit/${transaction.id}?error=illegalTotal`);
            window.scrollTo(0, 0);
            return;
          }

          const sendQuote = await sendQuoteUpdatedService({ transactionId: transaction.id });
          /* if quote send is successful, update hubspot */
          if (sendQuote.response) {
            trackHubSpotContactPage(
              {
                email: shop.email,
                last_quote_sent: moment().unix(),
              },
              '/quotes',
            );
          }
        }

        /* we are saving dates, let's check and see if a timezone is set in the config */
        if (!shop?.config?.timeZone) {
          /* if not, set one */
          await shopRepository.updateProps(shop.id, {
            config: {
              ...shop?.config,
              timeZone: defaultTimeZone,
            },
          });
        }
        setShowSavingSpinner(false);
      }

      setSubmitting(false);
      if (values.redirectAfterSave) {
        history.push('/quotes');
      }
    } catch (error) {
      console.error(error);
      setShowSavingSpinner(false);
      setSubmitting(false);
    }
  };

  const paidStateBanner =
    refundTotal > 0 ? (
      <RefundBanner refundTotal={refundTotal} refundableAmount={amountAvailableToRefund} transaction={transaction} />
    ) : markedAsPaid ? (
      <Banner variant="success" className={classes.bannerMargin}>
        <Typography variant="body2" style={{ color: 'inherit' }}>
          This quote has been paid in full and is ready for fulfillment.{' '}
          <Link to={`/orders/edit/${transaction?.id}`} className={classes.viewOrderLink}>
            View Order
          </Link>
        </Typography>
      </Banner>
    ) : (
      <></>
    );

  const depositsBanner = transaction?.transactionStatus == 'partially-paid' && (
    <Banner variant="success-no-icon" className={classes.bannerMargin}>
      <Typography variant="body2" style={{ color: 'inherit' }}>
        This quote has been partially paid and now has an associated order.{' '}
        <Link to={`/orders/edit/${transaction?.id}`} className={classes.viewOrderLink}>
          View Order
        </Link>
      </Typography>
    </Banner>
  );

  const quoteErrors = errorCode && (
    <Grid item className={classes.errorContainer}>
      <Typography className={`${classes.error} ${classes.errorHeading}`}>Error:&nbsp;</Typography>
      <Typography className={classes.error}>
        {quoteSendErrors[errorCode]} {errorCode === 'missingRequired' && validateQuote(transaction).join(', ')}
      </Typography>
    </Grid>
  );

  const mobileQuotesContent = transactionState && (
    <Grid container item direction="column">
      <QuoteCustomer transaction={transaction} defaultExpanded={transactionState !== 'draft'} />
      {order?.items[0]?.selections?.length > 0 && (
        <QuoteRequestDetails order={order} title={title} collapsable defaultExpanded={transactionState !== 'draft'} />
      )}
      <QuoteDetails
        order={order}
        transaction={transaction}
        subTransaction={subTransactions ? subTransactions[0] : null}
        isPaid={isPaid}
        isInvoice={isInvoice}
        setIsInvoice={setIsInvoice}
        title={title}
        defaultExpanded={transactionState !== 'draft'}
        refundTotal={refundTotal}
      />
      <QuoteFulfillment
        order={order}
        shippingInfo={transaction?.shippingInfo}
        isPaid={isPaid}
        state={transactionState}
        isInvoice={isInvoice}
        defaultExpanded={transactionState !== 'draft'}
      />
      {isPaid ? (
        <DepositPayment
          transaction={transaction}
          subTransaction={subTransactions ? subTransactions[0] : null}
          defaultExpanded={transactionState !== 'draft'}
        />
      ) : (
        <QuotePayment
          order={order}
          isPaid={isPaid}
          title={title}
          defaultExpanded={transactionState !== 'draft'}
          setHasSubTransactions={setHasSubTransactions}
        />
      )}
      {refundTotal > 0 && (
        <RefundDetails
          transaction={transaction}
          subTransaction={!_.isEmpty(subTransactions) ? subTransactions[0] : null}
          amountAvailableToRefund={amountAvailableToRefund}
          defaultExpanded
          standardMargin
        />
      )}
      <QuoteExpire isPaid={isPaid} defaultExpanded={transactionState !== 'draft'} />
    </Grid>
  );

  const desktopQuotesContent = (
    <Grid container item justify="space-between" spacing={2}>
      <Grid container item direction="column" xs={6}>
        <QuoteDetails
          order={order}
          transaction={transaction}
          subTransaction={subTransactions ? subTransactions[0] : null}
          isPaid={isPaid}
          isInvoice={isInvoice}
          setIsInvoice={setIsInvoice}
          title={title}
          defaultExpanded
          refundTotal={refundTotal}
        />
        {order?.items[0]?.selections?.length > 0 && (
          <QuoteRequestDetails order={order} title={title} collapsable defaultExpanded />
        )}
        {isPaid ? (
          <DepositPayment
            transaction={transaction}
            subTransaction={subTransactions ? subTransactions[0] : null}
            defaultExpanded={transactionState !== 'draft'}
          />
        ) : (
          <QuotePayment
            order={order}
            isPaid={isPaid}
            title={title}
            defaultExpanded
            setHasSubTransactions={setHasSubTransactions}
          />
        )}
        {refundTotal > 0 && (
          <RefundDetails
            transaction={transaction}
            subTransaction={!_.isEmpty(subTransactions) ? subTransactions[0] : null}
            amountAvailableToRefund={amountAvailableToRefund}
            defaultExpanded
            standardMargin
          />
        )}
        <QuoteExpire isPaid={isPaid} defaultExpanded />
      </Grid>
      <Grid container item direction="column" xs={6}>
        <QuoteCustomer transaction={transaction} defaultExpanded />
        <QuoteFulfillment
          order={order}
          shippingInfo={transaction?.shippingInfo}
          isPaid={isPaid}
          state={transactionState}
          isInvoice={isInvoice}
          defaultExpanded
        />
        {/* {order?.items[0]?.selections && <QuotePayment order={order} isPaid={isPaid} title={title} defaultExpanded />} */}
      </Grid>
    </Grid>
  );

  const tabs = [
    {
      value: 'Details',
      content: (
        <Grid container className={isMobile ? classes.contentMobile : classes.contentDesktop}>
          {depositsBanner}
          {paidStateBanner}
          {quoteErrors}
          {isMobile ? mobileQuotesContent : desktopQuotesContent}
        </Grid>
      ),
    },
    {
      value: 'My Notes',
      content: transaction && (
        <Grid container className={isMobile ? classes.contentMobile : classes.contentDesktop}>
          <QuoteNotes transaction={transaction} />
        </Grid>
      ),
    },
  ];

  return (
    <Grid container direction="column">
      <Spinner
        show={isProductsLoading || showSavingSpinner}
        label={`${showSavingSpinner ? 'Saving' : 'Loading'} ${title}`}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={orderSchema}
        onSubmit={handleSubmit(transactionState === 'pending')}
        enableReinitialize
        innerRef={formikRef}
        validateOnMount={true}
      >
        {(formikProps: FormikProps<QuoteValues>) => (
          <AdminForm>
            <LogFormikErrors />
            {!formikProps.isSubmitting && (
              <UnsavedChangesPrompt
                when={formikProps.dirty && !isPaid && transactionState !== 'canceled'}
                allowSamePage
              />
            )}
            <HeaderTabs initialTabValue={'Details'} tabs={tabs} />
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default EditQuote;
