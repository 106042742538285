import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import _ from 'lodash';
import moment from 'moment';
import {
  BaseDocument,
  BaseRepository, BaseSegmentedDocument,
  BaseSegmentedRepository,
  Condition,
  FieldFunctions,
  Order
} from '../base/repository';
import { Asset } from '../asset/model';
import { Address } from '../address';
import { TakeRateLevel } from '../tier';
import dataOnly from '../_lib/dataOnly';
import {
  AvailabilitySubtype,
  CalendarEvent,
  CalendarEventType,
  FulfillmentOptionSchedule,
  ShopSeoMetadata,
} from './model';
import { CalendarEventRepositoryFactory } from './calendarEvent';
import { stripHtml } from '../_lib/textUtils';
import { TaxonomyCategory } from '../taxonomy';
import calendarEsSchemaJSON from './calendar.es.schema.json';
import shopEsSchemaJSON from './shop.es.schema.json';
import { BaseProduct } from '../product';

export const calendarEsSchema = calendarEsSchemaJSON;
export const shopEsSchema = shopEsSchemaJSON;

const SITE_CONFIG_COLLECTION = 'site_configs';
const GALLERY_PHOTOS_COLLECTION = 'gallery_photos';
const FULFILLMENT_OPTIONS_COLLECTION = 'fulfillment_options';
const SHOP_FAQ_COLLECTION = 'shop_faq';

export type FulfillmentType = 'pickup' | 'delivery' | 'shipping' | 'custom' | 'inperson' | 'virtual';

export type MarketplaceIdentifier = 'castiron' | 'nourysh';

export enum ChecklistValues {
  AddCreditCard = 'ADD_CREDIT_CARD',
  Brand = 'BRAND',
  Pages = 'PAGES',
  Customize = 'CUSTOMIZE',
  ProductAdded = 'PRODUCT_ADDED',
  FulfillmentAdded = 'FULFILLMENT_ADDED',
  StripeConnection = 'STRIPE_CONNECTION',
  SelectPlan = 'PLAN_SELECTED',
  GoLive = 'GO_LIVE',
  ShareShopLink = 'SHARE_SHOP_LINK',
  CustomerList = 'CUSTOMER_LIST',
  AnnounceNewShop = 'ANNOUNCE_NEW_SHOP',
  GalleryPhotoAdded = 'GALLERY_PHOTO_ADDED',
  VisitHelpCenter = 'VISIT_HELP_CENTER',
  JoinCommunity = 'JOIN_COMMUNITY',
  UserflowChecklist = 'USERFLOW_CHECKLIST',
  /* TODO: remove deprecated values after migration script is run */
  StoreName = 'STORE_NAME',
  SignUpPage = 'SIGN_UP_PAGE',
  ProfilePhoto = 'PROFILE_PHOTO',
  AboutInfo = 'ABOUT_INFO',
  Logo = 'LOGO',
  SocialLinks = 'SOCIAL_LINKS',
  AllergenInfo = 'ALLERGEN_INFO',
  EmailMarketing = 'EMAIL_MARKETING',
  EmailMarketingSingleSend = 'EMAIL_MARKETING_SINGLE_SEND',
  SmsMarketingSingleSend = 'SMS_MARKETING_SINGLE_SEND',
  CouponCreate = 'COUPON_CREATE',
}

export interface Category {
  id: string;
  name: string;
}

export interface SubCategory {
  name: string;
}

export type Allergens =
  | 'wheat'
  | 'milk'
  | 'eggs'
  | 'fish'
  | 'shellfish'
  | 'treeNuts'
  | 'peanuts'
  | 'soyBeans'
  | 'sesame';

export interface SocialMediaInfo {
  facebookLink?: string;
  instagramLink?: string;
  tiktokLink?: string;
}

export interface Owner {
  firstName: string;
  lastName: string;
}

export interface PaymentSettings {
  takeRateLevels?: TakeRateLevel[];
  castironTakeRate: number;
  customerRate: number;
  isCustomerPayingStripeFee: boolean;
  taxRate?: number;
  paymentAccountId?: string;
}
export interface Banner {
  enabled: boolean;
  message: string;
}

export interface TippingPresets {
  tipPercentage: number;
  tipMultiplier: number;
}

export type ThemeNameOption =
  | 'classic-castiron'
  | 'pink-lemonade'
  | 'tropical-macaron'
  | 'sugar-cookie'
  | 'strawberry-sorbet'
  | 'sweet-patisserie'
  | 'lavender-latte'
  | 'rose-bakery'
  | 'paris-cafe'
  | 'blueberry-crisp';

export type ThemeColorOption = {
  header: string;
  background: string;
  accent: {
    primary: string;
    secondary: string;
  };
};

export type ButtonColorOption =
  | 'blue'
  | 'indigo'
  | 'deepPurple'
  | 'lightBlue'
  | 'cyan'
  | 'teal'
  | 'lightGreen'
  | 'lime'
  | 'pink'
  | 'deepOrange'
  | 'gray'
  | 'green'
  | 'lightRed';
export type ButtonRoundnessOption = '12px' | '100px' | '0px';

export type PrimaryFontOption =
  | 'syne'
  | 'montserrat'
  | 'abril-fatface'
  | 'ultra'
  | 'jost'
  | 'pacifico'
  | 'playfair-display'
  | 'dela-gothic-one'
  | 'nunito-sans'
  | 'yeseva-one'
  | 'philosopher'
  | 'shrikhand'
  | 'lemon'
  | 'lobster'
  | 'sail';
export type SecondaryFontOption =
  | 'inter'
  | 'open-sans'
  | 'poppins'
  | 'pt-serif'
  | 'jost'
  | 'lato'
  | 'roboto'
  | 'nunito-sans'
  | 'josefin-sans'
  | 'mulish'
  | 'lora'
  | 'cambay'
  | 'montserrat';
export type ShopFontOption = {
  primary: PrimaryFontOption;
  secondary: SecondaryFontOption;
};

export interface ShopTheme {
  name: ThemeNameOption;
  themeColors: ThemeColorOption;
  shopButtonColor: ButtonColorOption;
  shopButtonRoundness: ButtonRoundnessOption;
  shopFont: ShopFontOption;
}

export type Popups = {
  email?: {
    showPopup: boolean;
  };
  sms?: {
    showPopup: boolean;
  };
  holidays?: {
    showPopup: boolean;
  }
};

export type Marketing = {
  thankYouCoupon?: {
    enabled: boolean;
    sendToOrdersWithin60Days: boolean;
    couponId: string;
  };
  welcomeSeriesCoupon?: {
    enabled: boolean;
    couponId: string;
  };
  newsletterSeries?: {
    enabled: boolean;
  };
};

export type DomainVerificationStatus = 'pending' | 'verified' | 'failed';
export interface ShopConfig {
  banner?: Banner;
  timeZone?: string;
  tipping?: boolean;
  tippingPresetPercentages?: TippingPresets[];
  domains?: string[];
  freeCustomDomain?: string;
  domainRenew?: boolean;
  domainRequestedAt?: number;
  domainStatus?: DomainVerificationStatus;
  popups?: Popups;
  shopTheme?: ShopTheme;
  testQuoteSent?: boolean;
  marketing?: Marketing;
}

export type MadeBadges = 'home' | 'commercial';
export type CertificationBadges = 'licensed-cotttage' | 'licensed-establishment' | 'certified-food-handler';
export type SpecialDietsBadges = 'gluten-free' | 'allergen-friendly' | 'plant-based';
export type MoreBadges = 'minority-owned' | 'woman-owned';
export type AwardBadge = '2022-food-entrepreneur' | '';

export interface Badges {
  made?: MadeBadges;
  certifications: CertificationBadges[];
  specialDiets: SpecialDietsBadges[];
  more: MoreBadges[];
  award: AwardBadge;
}

export interface ArtisanCategory {
  name: string;
  subcategories: string[];
}

export interface EditModeConfig {
  productFilterBy?: string;
}

export interface ShopSummary {
  id: string;
  businessName: string;
  owner: Owner;
  websiteUrl: string;
  logoImageObj?: Asset;
  photoGalleryImages?: Asset[];
  profileImageObj?: Asset;
  artisanCategory?: ArtisanCategory;
  fulfillmentTypes?: FulfillmentType[];
  location?: Address;
}

export interface FriendBuyIntegration {
  referralCode: string;
}

export interface ShopIntegrations {
  friendBuy?: FriendBuyIntegration;
}

export interface SiteConfig extends BaseDocument<ShopConfig> {
  title?: string;
  description?: string;
  keywords?: string;
  head?: string;
  bodyEnd?: string;
}

export interface GalleryPhoto extends BaseDocument<GalleryPhoto> {
  photo: Asset;
  caption?: string;
  category?: string;
  position?: number;
}

export const fulfillmentTypeDisplayName = (ffType: FulfillmentType, variant: 'long' | 'short' = 'long'): string => {
  switch (ffType) {
    case 'pickup':
      return variant === 'long' ? 'Local Pickup' : 'Pickup';
    case 'delivery':
      return variant === 'long' ? 'Local Delivery' : 'Delivery';
    case 'shipping':
      return 'Shipping';
    case 'inperson':
      return 'In Person';
    default:
      return null;
  }
};

export interface ProcessingTime {
  increments: number;
  incrementType: 'hour' | 'day' | 'week';
}

export type FulfillmentStatus = 'active' | 'inactive' | 'archived';

export interface FulfillmentOption extends BaseDocument<FulfillmentOption> {
  status?: FulfillmentStatus;
  leadTime?: ProcessingTime;
  type: FulfillmentType;
  displayName: string;
  description: string;
  isExpired?: boolean;
  afterPurchaseDetails?: string;
  postalCode?: string;
  fee?: number;
  minimum?: number;
  date?: number; // legacy, needs deprecated
  fulfillmentNotes?: string;
  notes?: string;
  startDate?: number; // legacy, needs deprecated
  endDate?: number; // legacy, needs deprecated
  recipient?: string;
  address?: Address;
  processingTime?: ProcessingTime;
  schedule?: FulfillmentOptionSchedule;
  sendPickupReminderEmail?: boolean;
  useBusinessAddress?: boolean;
  source?: string;
}

export type ShopStatus = 'active' | 'inactive' | 'deleted' | 'prelaunch';

export type ShopFaq = {
  id: string;
  question: string;
  answer: string;
  position: number;
};

export type LayoutOption = {
  name: 'standard' | 'custom' | 'presales' | 'about' | 'gallery' | 'contact' | 'email' | 'events';
  position: number;
  checked: boolean;
};

export type GenericLayoutOption = {
  name: string;
  position: number;
  id?: string;
};

export type ProductCategoryLayout = {
  categoryName: string;
  layout: GenericLayoutOption[];
};

export type EventPage = {
  tag: string;
  enabled: boolean;
  headline: string;
  description: string;
  showPopup?: boolean;
};

export type ShopSubpageData = {
  isAboutPageEnabled: boolean;
  isContactPageEnabled: boolean;
  isFaqPageEnabled: boolean;
  isGalleryPageEnabled: boolean;
  contactPageTitle: string;
  contactPageDescription: string;
  isShopPageEnabled?: boolean;
  availability?: {
    enabled: boolean;
    headline: string;
    description: string;
  };
  custom?: {
    enabled: boolean;
    headline: string;
    description: string;
    title: string;
    imageObj?: Asset;
  };
  events?: EventPage[];
  home?: {
    enabled: boolean;
    headline: string;
    backgroundImageObj: Asset;
    description?: string;
    layout?: LayoutOption[];
  };
  presales?: {
    enabled: boolean;
    headline: string;
    description: string;
  };
  quotes?: {
    enabled: boolean;
    headline: string;
    description: string;
  };
  ticketedEvents?: {
    enabled: boolean;
    headline: string;
    description: string;
  };
};

export interface ServiceAreaLocation {
  postalCodes: string | string[];
  display: string;
}

export interface ServiceArea {
  postalCodes?: string[];
  locations?: ServiceAreaLocation[];
  shipping?: 'nationwide' | 'statewide' | 'none';
}

export interface Shop extends BaseSegmentedDocument<Shop> {
  status?: ShopStatus;
  owner?: Owner;
  websiteUrl: string;
  aboutTitle: string;
  allergens?: Allergens[];
  dietary?: string[];
  businessName: string;
  /* We don't appear to save a phoneNumber for shop, but leaving since this field appears in stripeSetup and we don't want to break anything */
  phoneNumber?: string;
  castIronCoverImage?: string;
  castIronCoverImageObj?: Asset;
  coverImage?: string;
  coverImageObj?: Asset;
  description?: string;
  email: string;
  socialMedia?: SocialMediaInfo;
  logo?: string;
  logoImageObj?: Asset;
  photoGalleryImages?: Asset[];
  profileImage?: string;
  profileImageObj?: Asset;
  useLogoAndName?: boolean;
  categories?: Category[];
  standardCategoryLayout?: GenericLayoutOption[];
  customCategoryLayout?: GenericLayoutOption[];
  eventCategoryLayout?: GenericLayoutOption[];
  productCategoryLayouts?: ProductCategoryLayout[];
  customProductCategoryLayouts?: ProductCategoryLayout[];
  eventProductCategoryLayouts?: ProductCategoryLayout[];
  physicalAddress?: Address;
  canAcceptPayments?: boolean;
  productTypes?: string[];
  checklistCompletions?: ChecklistValues[];
  hasEverLaunched?: boolean;
  paymentSettings: PaymentSettings;
  config?: ShopConfig;
  badges?: Badges;
  artisanCategory?: ArtisanCategory;
  tags?: string[];
  editModeConfig?: EditModeConfig;
  integrations?: ShopIntegrations;
  statusAtCancel?: ShopStatus;
  seoMetadata?: ShopSeoMetadata;
  shopSubpageData?: ShopSubpageData;
  taxonomy?: TaxonomyCategory[];
  serviceArea?: ServiceArea;
  eventTags?: string[];

  addToChecklist?: (checklistValue: ChecklistValues) => Promise<void>;

  setSiteConfig?: (siteConfig: SiteConfig) => Promise<void>;
  getSiteConfig?: () => Promise<SiteConfig>;

  getGalleryPhotos?: () => Promise<GalleryPhoto[]>;
  getGalleryPhotoById?: (id: string) => Promise<GalleryPhoto>;
  addGalleryPhoto?: (photo: GalleryPhoto) => Promise<GalleryPhoto>;
  updateGalleryPhoto?: (photoId: string, photoProps: Partial<GalleryPhoto> | Record<string, any>) => Promise<void>;
  deleteGalleryPhoto?: (id: string) => Promise<void>;

  getFulfillmentOptions?: () => Promise<FulfillmentOption[]>;
  getFulfillmentOptionById?: (id: string) => Promise<FulfillmentOption>;
  addFulfillmentOption?: (fulfillmentOption: FulfillmentOption) => Promise<FulfillmentOption>;
  updateFulfillmentOption?: (
    fulfillmentOptionId: string,
    fulfillmentOptionProps: Partial<FulfillmentOption>,
  ) => Promise<void>;
  deleteFulfillmentOptionProperties?: (fulfillmentOptionId: string, properties: string[]) => Promise<void>;
  deleteFulfillmentOption?: (id: string) => Promise<void>;

  findCalendarEvents?: (startTime: number, endTime: number) => Promise<CalendarEvent>;
  findCalendarEventsByType: (type: CalendarEventType, startTime: number, endTime: number) => Promise<void>;
  addCalendarAvailability: (subtype: AvailabilitySubtype, startTime: number, endTime: number) => Promise<void>;

  getShopFaqs?: () => Promise<ShopFaq[]>;
  addShopFaq?: (faq: ShopFaq) => Promise<ShopFaq>;
  updateShopFaqs?: (faqId: string, faqProps: Partial<ShopFaq>) => Promise<void>;
  deleteShopFaq?: (shopId: string, id: string) => Promise<void>;
}

export const toShopSummary = async (s: Shop) => {
  const fulfillmentOptions = await s.getFulfillmentOptions();
  return {
    id: s.id,
    businessName: s.businessName,
    websiteUrl: s.websiteUrl,
    owner: s.owner,
    logoImageObj: s.logoImageObj,
    photoGalleryImages: s.photoGalleryImages,
    profileImageObj: s.profileImageObj,
    artisanCategory: s.artisanCategory,
    fulfillmentTypes: _.uniq(fulfillmentOptions?.map(f => f.type)),
    location: s.physicalAddress,
  };
};

export const generateShopSeoMetadata = (shop: Shop): ShopSeoMetadata => {
  const shopCategory = shop.artisanCategory?.name;
  const shopLocation = shop.physicalAddress && {
    addressLocality: shop.physicalAddress.city,
    addressRegion: shop.physicalAddress.region,
    addressCountry: shop.physicalAddress.country,
    postalCode: shop.physicalAddress.postalCode,
  };

  let pageTitle = shop.businessName;
  if (shopCategory && shopLocation) {
    pageTitle = `${shopCategory} in ${shopLocation.addressLocality}, ${shopLocation.addressRegion} | ${shop.businessName}`;
  } else if (shopCategory) {
    pageTitle = `${shopCategory} | ${shop.businessName}`;
  } else if (shopLocation) {
    pageTitle = `${shop.businessName} | ${shopLocation.addressLocality}, ${shopLocation.addressRegion}`;
  }

  const sanitizedDesc = stripHtml(shop.description);

  return {
    address: shopLocation,
    shopTitle: pageTitle,
    shopDescription: sanitizedDesc,
    socialImage: shop.logoImageObj?.downloadUrl,
    socialPageTitle: pageTitle,
    socialPageDescription: sanitizedDesc,
  };
};

export interface ArtisanActiveShopsSearchLocation {
  region?: string;
  city?: string;
}

export interface ArtisanActiveShopsSearch {
  artisanCategories?: string[];
  fulfillment?: string[];
  region?: string[];
}

export class ShopRepository extends BaseSegmentedRepository<Shop> {
  private calendarEventRepositoryFactory: CalendarEventRepositoryFactory;

  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, marketplaceId: MarketplaceIdentifier | 'all', fieldFunctions?: FieldFunctions) {
    super(firestore, 'shops', marketplaceId, fieldFunctions,);
    this.calendarEventRepositoryFactory = new CalendarEventRepositoryFactory(firestore, fieldFunctions);
  }

  public async findAllActive(): Promise<Shop[]> {
    return await this.find({
      where: [{ field: 'status', operator: '==', value: 'active' }],
    });
  }

  public async findByShopId(shopId: string): Promise<Shop | null> {
    const result = await this.find({
      where: [
        { field: 'status', operator: 'in', value: ['active', 'prelaunch'] },
        { field: 'id', operator: '==', value: shopId },
      ],
    });
    return this.firstOrNull(result);
  }

  public async findByWebsiteUrl(websiteUrl: string): Promise<Shop | null> {
    const result = await this.find({
      where: [
        { field: 'status', operator: 'in', value: ['active', 'prelaunch'] },
        { field: 'websiteUrl', operator: '==', value: websiteUrl },
      ],
    });
    return this.firstOrNull(result);
  }

  public async findWebsiteUrlsStartsWith(startWith: string): Promise<Shop[]> {
    return this.findFieldStartsWith('websiteUrl', startWith, [
      { field: 'status', operator: 'in', value: ['active', 'inactive', 'prelaunch'] },
    ]);
  }

  public async findArtisanActiveShops(search: ArtisanActiveShopsSearch): Promise<Shop[]> {
    const where: Condition<Shop>[] = [{ field: 'status', operator: '==', value: 'active' }];

    if (search.region && search.region.length > 0) {
      where.push({ field: 'physicalAddress.region', operator: 'in', value: search.region });
    } else if (search.artisanCategories && search.artisanCategories.length > 0) {
      where.push({ field: 'artisanCategory.name', operator: 'in', value: search.artisanCategories });
    }

    let results = await this.find({
      where,
      orderBy: [{ field: 'businessName', direction: 'asc' }],
    });

    if (search.artisanCategories && search.artisanCategories.length > 0 && search.region && search.region.length > 0) {
      results = results.filter(s => search.artisanCategories.includes(s.artisanCategory?.name));
    }

    if (search.fulfillment && search.fulfillment.length > 0) {
      const resultPromises = results.filter(async s => {
        const fulfillmentOptions = await s.getFulfillmentOptions();
        return fulfillmentOptions.map(f => f.type).some(f => search.fulfillment.includes(f));
      });
      results = await Promise.all(resultPromises);
    }

    return results;
  }

  public async findByDomainName(domainName: string): Promise<Shop> {
    const shops = await this.find({
      where: [
        { field: 'config.domains', operator: 'array-contains', value: domainName },
        { field: 'status', operator: 'in', value: ['active', 'prelaunch'] },
      ],
    });

    return shops.length > 0 ? shops[0] : undefined;
  }

  public async findByDomainStatus(status: DomainVerificationStatus): Promise<Shop[]> {
    const shops = await this.find({
      where: [
        { field: 'config.domainStatus', operator: '==', value: status },
        { field: 'status', operator: 'in', value: ['active', 'prelaunch'] },
      ],
    });

    return shops.length > 0 ? shops : undefined;
  }

  public async addChecklistCompletion(shop: Shop, checklistCompletion: ChecklistValues): Promise<void> {
    if (!shop.checklistCompletions?.includes(checklistCompletion)) {
      await this.updateProps(shop.id, {
        checklistCompletions: firebase.firestore.FieldValue.arrayUnion(checklistCompletion),
      });
    }
  }

  public async addTags(shop: Shop, tags: string[]): Promise<Shop> {
    await this.updateProps(shop.id, {
      tags: this.fieldFunctions.arrayUnion(tags),
    });
    return {
      ...shop,
      tags: [...shop.tags, ...tags],
    };
  }

  public async removeTags(shop: Shop, tags: string[]): Promise<Shop> {
    await this.updateProps(shop.id, {
      tags: this.fieldFunctions.arrayRemove(tags),
    });
    return {
      ...shop,
      tags: shop.tags.filter(t => !tags.includes(t)),
    };
  }

  public async getSiteConfig(shopId: string): Promise<SiteConfig> {
    return this.collection()
      .doc(shopId)
      .collection(SITE_CONFIG_COLLECTION)
      .get()
      .then(c =>
        c.empty
          ? undefined
          : {
              ...c.docs[0].data(),
              id: c.docs[0].id,
            },
      );
  }

  public async setSiteConfig(shopId: string, siteConfig: SiteConfig): Promise<void> {
    const createdAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(SITE_CONFIG_COLLECTION)
      .add({ ...siteConfig, createdAt })
      .then(() => {});
  }

  public async getGalleryPhotos(shopId: string): Promise<GalleryPhoto[]> {
    return this.collection()
      .doc(shopId)
      .collection(GALLERY_PHOTOS_COLLECTION)
      .get()
      .then(c =>
        c.empty
          ? []
          : c.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            })),
      );
  }

  public async getGalleryPhotoById(shopId: string, photoId: string): Promise<GalleryPhoto> {
    return this.collection()
      .doc(shopId)
      .collection(GALLERY_PHOTOS_COLLECTION)
      .doc(photoId)
      .get()
      .then(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
  }

  public async addGalleryPhoto(shopId: string, photo: GalleryPhoto): Promise<GalleryPhoto> {
    const createdAt = moment().unix();
    const p = {
      ...photo,
      createdAt,
    };
    if (photo.id) {
      return this.collection()
        .doc(shopId)
        .collection(GALLERY_PHOTOS_COLLECTION)
        .doc(photo.id)
        .set(p)
        .then(() => ({
          ...p,
          id: photo.id,
        }));
    } else {
      return this.collection()
        .doc(shopId)
        .collection(GALLERY_PHOTOS_COLLECTION)
        .add(p)
        .then(doc => ({
          ...p,
          id: doc.id,
        }));
    }
  }

  public async updateGalleryPhoto(
    shopId: string,
    photoId: string,
    photoProps: Partial<GalleryPhoto> | Record<string, any>,
  ): Promise<void> {
    const updatedAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(GALLERY_PHOTOS_COLLECTION)
      .doc(photoId)
      .update({ ...photoProps, updatedAt })
      .then(() => {});
  }

  public async deleteGalleryPhoto(shopId: string, id: string): Promise<void> {
    return this.collection()
      .doc(shopId)
      .collection(GALLERY_PHOTOS_COLLECTION)
      .doc(id)
      .delete()
      .then(() => {});
  }

  public async getShopFaqs(shopId: string): Promise<ShopFaq[]> {
    return this.collection()
      .doc(shopId)
      .collection(SHOP_FAQ_COLLECTION)
      .get()
      .then(c =>
        c.empty
          ? []
          : c.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            })),
      );
  }

  public async addShopFaq(shopId: string, faq: ShopFaq): Promise<ShopFaq> {
    const createdAt = moment().unix();
    const f = {
      ...faq,
      createdAt,
    };
    if (faq.id) {
      return this.collection()
        .doc(shopId)
        .collection(SHOP_FAQ_COLLECTION)
        .doc(faq.id)
        .set(f)
        .then(() => ({
          ...f,
          id: faq.id,
        }));
    } else {
      return this.collection()
        .doc(shopId)
        .collection(SHOP_FAQ_COLLECTION)
        .add(f)
        .then(doc => ({
          ...f,
          id: doc.id,
        }));
    }
  }

  public async updateShopFaqs(
    shopId: string,
    faqId: string,
    faqProps: Partial<ShopFaq> | Record<string, any>,
  ): Promise<void> {
    const updatedAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(SHOP_FAQ_COLLECTION)
      .doc(faqId)
      .update({ ...faqProps, updatedAt })
      .then(() => {});
  }

  public async deleteShopFaq(shopId: string, id: string): Promise<void> {
    return this.collection()
      .doc(shopId)
      .collection(SHOP_FAQ_COLLECTION)
      .doc(id)
      .delete()
      .then(() => {});
  }

  public async getFulfillmentOptions(shopId: string): Promise<FulfillmentOption[]> {
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .get()
      .then(c =>
        c.empty
          ? []
          : c.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            })),
      );
  }

  public async getFulfillmentOptionById(shopId, fulfillmentId): Promise<FulfillmentOption> {
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .doc(fulfillmentId)
      .get()
      .then(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
  }

  public async addFulfillmentOption(shopId: string, fulfillmentOption: FulfillmentOption): Promise<FulfillmentOption> {
    const createdAt = moment().unix();
    const f = {
      ...fulfillmentOption,
      createdAt,
    };
    if (fulfillmentOption.id) {
      return this.collection()
        .doc(shopId)
        .collection(FULFILLMENT_OPTIONS_COLLECTION)
        .doc(fulfillmentOption.id)
        .set(f)
        .then(() => ({
          ...f,
          id: fulfillmentOption.id,
        }));
    } else {
      return this.collection()
        .doc(shopId)
        .collection(FULFILLMENT_OPTIONS_COLLECTION)
        .add(f)
        .then(doc => ({
          ...f,
          id: doc.id,
        }));
    }
  }

  public async updateFulfillmentOption(
    shopId: string,
    fulfillmentOptionId: string,
    fulfillmentOptionProps: Partial<FulfillmentOption> | Record<string, any>,
  ): Promise<void> {
    const updatedAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .doc(fulfillmentOptionId)
      .update({ ...fulfillmentOptionProps, updatedAt })
      .then(() => {});
  }

  public async deleteFulfillmentOptionProperties(
    shopId: string,
    fulfillmentOptionId: string,
    properties: string[],
  ): Promise<void> {
    const propDeletes = _.assign({}, ...properties.map(prop => ({ [prop]: firebase.firestore.FieldValue.delete() })));
    const updatedAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .doc(fulfillmentOptionId)
      .update({ ...propDeletes, updatedAt })
      .then(() => {});
  }

  public async deleteFulfillmentOption(shopId: string, id: string): Promise<void> {
    return this.collection()
      .doc(shopId)
      .collection(FULFILLMENT_OPTIONS_COLLECTION)
      .doc(id)
      .delete()
      .then(() => {});
  }

  public async findForEvent(event: string): Promise<Shop[]> {
    return this.find({
      where: [
        { field: 'status', operator: '==', value: 'active' },
        { field: 'eventTags', operator: 'array-contains', value: event },
      ],
      orderBy: [{ field: 'updatedAt', direction: 'desc' }],
    });
  }

  public async findByTag(tag: string): Promise<Shop[]> {
    return this.find({
      where: [
        { field: 'status', operator: '==', value: 'active' },
        { field: 'tags', operator: 'array-contains', value: tag },
      ],
      orderBy: [{ field: 'updatedAt', direction: 'desc' }],
    });
  }

  protected enrichWith(): any {
    return {
      _repository: this,
      addToChecklist(checklistValue: ChecklistValues) {
        return this._repository.addChecklistCompletion(this, checklistValue);
      },
      getSiteConfig() {
        return this._repository.getSiteConfig(this.id);
      },
      setSiteConfig(siteConfig: SiteConfig) {
        return this._repository.setSiteConfig(this.id, siteConfig);
      },
      getGalleryPhotos() {
        return this._repository.getGalleryPhotos(this.id);
      },
      getGalleryPhotoById(photoId: string): Promise<GalleryPhoto> {
        return this._repository.getGalleryPhotoById(this.id, photoId);
      },
      addGalleryPhoto(photo: GalleryPhoto): GalleryPhoto {
        return this._repository.addGalleryPhoto(this.id, photo);
      },
      updateGalleryPhoto(photoId: string, photoProps: Partial<GalleryPhoto> | Record<string, any>) {
        return this._repository.updateGalleryPhoto(this.id, photoId, photoProps);
      },
      deleteGalleryPhoto(id: string): Promise<void> {
        return this._repository.deleteGalleryPhoto(this.id, id);
      },
      addShopFaq(faq: ShopFaq): ShopFaq {
        return this._repository.addShopFaq(this.id, faq);
      },
      updateShopFaqs(faqId: string, faqProps: Partial<ShopFaq> | Record<string, any>) {
        return this._repository.updateShopFaqs(this.id, faqId, faqProps);
      },
      deleteShopFaq(shopId: string, id: string): Promise<void> {
        return this._repository.deleteShopFaq(this.id, id);
      },
      getShopFaqs() {
        return this._repository.getShopFaqs(this.id);
      },
      getFulfillmentOptions() {
        return this._repository.getFulfillmentOptions(this.id);
      },
      getFulfillmentOptionById(id: string) {
        return this._repository.getFulfillmentOptionById(this.id, id);
      },
      addFulfillmentOption(fulfillmentOption: FulfillmentOption): FulfillmentOption {
        return this._repository.addFulfillmentOption(this.id, fulfillmentOption);
      },
      updateFulfillmentOption(
        fulfillmentOptionId: string,
        fulfillmentOptionProps: Partial<FulfillmentOption> | Record<string, any>,
      ) {
        return this._repository.updateFulfillmentOption(this.id, fulfillmentOptionId, fulfillmentOptionProps);
      },
      deleteFulfillmentOptionProperties(fulfillmentOptionId: string, properties: string[]) {
        return this._repository.deleteFulfillmentOptionProperties(this.id, fulfillmentOptionId, properties);
      },
      deleteFulfillmentOption(id: string): Promise<void> {
        return this._repository.deleteFulfillmentOption(this.id, id);
      },
      findCalendarEvents(startTime: number, endTime: number): Promise<CalendarEvent> {
        return this._repository.calendarEventRepositoryFactory
          .getRepository(this.id)
          .findCalendarEvents(startTime, endTime);
      },
      findCalendarEventsByType(type: CalendarEventType, startTime: number, endTime: number): Promise<void> {
        return this._repository.calendarEventRepositoryFactory
          .getRepository(this.id)
          .findCalendarEventsByType(type, startTime, endTime);
      },
      addCalendarAvailability(subtype: AvailabilitySubtype, startTime: number, endTime: number): Promise<void> {
        return this._repository.calendarEventRepositoryFactory
          .getRepository(this.id)
          .addAvailability(subtype, startTime, endTime);
      },
      data() {
        return dataOnly(_.omit(this, ['_repository']));
      },
    };
  }
}

export interface ShopRelatedDocument<T> extends BaseDocument<T> {
  shopId: string;
}

export class ShopRelatedRepository<T extends ShopRelatedDocument<T>> extends BaseRepository<T> {
  constructor(
    firestore: firebase.firestore.Firestore | _firestore.Firestore,
    collectionName: string,
    fieldFunctions?: FieldFunctions,
  ) {
    super(firestore, collectionName, fieldFunctions);
  }

  public async findByShopId(shopId: string, orderBy: Order<T>[] = [], limit?: number): Promise<T[]> {
    return await this.find({
      where: [this.whereShopIs(shopId), { field: 'status', operator: '!=', value: 'deleted' }],
      orderBy,
      limit,
    });
  }

  protected whereShopIs(shopId: string): Condition<T> {
    return { field: 'shopId', operator: '==', value: shopId };
  }
}
