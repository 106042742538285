import { Transaction, TransactionTotals } from '.';
import { calculateTotalsForDepositOrder } from '../cart';
import { PaymentSettings, Shop } from '../shop';

export const getRefundTotal = ({
  transaction,
  subTransaction,
}: {
  transaction: Transaction;
  subTransaction?: Transaction;
}): number => {
  const refunds = [
    ...(transaction?.order?.payments?.filter(p => p?.type === 'refund' && p?.status === 'succeeded') || []),
    ...(subTransaction?.order?.payments?.filter(p => p?.type === 'refund' && p?.status === 'succeeded') || []),
  ];
  const refundTotal = refunds.reduce((sum, p) => sum + (p?.amount || 0), 0);
  return refundTotal;
};

export const getTransactionTotals = ({
  transaction,
  subTransaction,
  paymentSettings,
}: {
  transaction: Transaction;
  subTransaction?: Transaction;
  paymentSettings?: PaymentSettings;
}): TransactionTotals => {
  return transaction && subTransaction
    ? calculateTotalsForDepositOrder({
        order: transaction.order,
        paymentSettings,
        subTransactionTotal: subTransaction?.totals?.subtotal,
        tip: transaction?.totals?.tip || 0,
        tippingPresetPercentage: transaction?.totals?.tippingPresetPercentage,
      })
    : transaction?.totals;
};

export const getTotal = async (transaction: Transaction, shop: Shop) => {
  const subTransactions = await transaction.getSubTransactions();
  const subTransaction = subTransactions[0];
  const refundTotal = getRefundTotal({ transaction, subTransaction });
  const totals = getTransactionTotals({
    transaction,
    subTransaction,
    paymentSettings: shop?.paymentSettings,
  });
  const total = totals?.total - refundTotal;
  return total;
};
