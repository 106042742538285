import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Dinero from 'dinero.js';
import { Formik, FormikProps, getIn } from 'formik';
import Fuse from 'fuse.js';
import levenshtein from 'js-levenshtein';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as yup from 'yup';
import {
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {
  Banner,
  Button,
  CollapsableCard,
  DateInput,
  DeleteButton,
  ImageRadioOrCheckboxInput,
  Link,
  SaveButton,
  SearchInput,
  TextInput,
  TimeInput,
  Typography,
} from '@castiron/components';
import {
  BaseProduct,
  ChecklistValues,
  Customer,
  Presale,
  ScheduledMessage,
  TicketedEvent,
  getImageUrl,
  getPresaleImageUrl,
} from '@castiron/domain';
import { useTracking, defaultTimeZone } from '@castiron/utils';
import SmsCouponPromoPreview from '../../../assets/img/sms-coupon-promo-preview.png';
import SmsPreview from '../../../assets/img/sms-preview.png';
import { customerRepository, productRepository, scheduledMessagesRepository } from '../../../domain';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCouponAction } from '../../../store/reducers/coupons';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import AdminForm from '../../AdminForm';
import { DropDownOption } from '../../Dropdown';
import { LayoutPageProps } from '../../Layout';
import EllipsisMenu, { EllipsisMenuOption } from '../../Menus/EllipsisMenu';
import RequireStripe from '../../RequireStripe';
import GeneralModal from '../../RootModal/GeneralModal';
import { ModalType } from '../../RootModal/RootModal';
import Spinner from '../../Spinner';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import { SmsTemplate, TemplateType, smsTemplates } from './SingleSendSmsContent';
import { nanoid } from '@reduxjs/toolkit';

type PresaleOrProduct = (Presale | BaseProduct) & { itemType: 'product' | 'presale' };

const getAllPresalesHandler = getService('presales', 'getallpresalesv2');
const sendSmsAnnouncementService = getService('messaging', 'sendsmsannouncement');
const generateSmsService = getService('messaging', 'generatesms');

const useStyles = makeStyles((theme: Theme) => ({
  addContacts: {
    color: 'inherit',
    borderColor: theme.branding.v2.red[800],
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
    },
  },
  banner: {
    maxWidth: '280px',
  },
  cardContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: 'calc(100% - 32px)',
      minWidth: 0,
      paddingTop: '16px',
    },
    width: '100%',
  },
  emptySearch: {
    width: '100%',
    padding: '40px 0px 150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorBox: {
    marginBottom: 24,
    padding: '16px 18px',
    backgroundColor: theme.branding.v2.red[50],
    color: theme.branding.v2.red[500],
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 12,
  },
  errorText: {
    color: 'inherit',
    margin: '1px 0px 0px 10px',
  },
  infoSend: {
    fontWeight: 700,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      color: theme.branding.v2.blue[900],
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '24px',
    flexWrap: 'nowrap',
  },
  messageInput: {
    border: 'none',
    '& div': {
      border: 'none',
    },
  },
  modalPaperClass: {
    [theme.breakpoints.up('sm')]: {
      height: 'fit-content',
    },
  },
  noArrow: {
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  noContactsBanner: {
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      margin: '24px 16px 0px',
    },
  },
  previewImage: {
    minWidth: 0,
    maxWidth: '280px',
    minHeight: 0,
    maxHeight: 'fit-content',
    marginBottom: '24px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  previewModalContainer: {
    background: theme.branding.v2.gray[100], // match preview image background
  },
  previewModalHeader: {
    borderBottom: `1px solid ${theme.branding.v2.gray[300]}`,
  },
  radioLabel: {
    margin: 0,
    gap: '8px',
    '& .MuiRadio-root': {
      padding: 0,
    },
    '& .Mui-checked': {
      color: theme.branding.v2.blue[500],
    },
  },
  searchIcon: {
    height: 36,
    width: 36,
    marginBottom: 16,
    color: theme.branding.v2.gray[600],
  },
  sendText: {
    color: theme.branding.v2.blue[500],
    cursor: 'pointer',
  },
  sendTestSmsText: {
    border: `1px solid ${theme.branding.v2.gray[300]}`,
    padding: '12px 16px',
    color: theme.branding.v2.blue[500],
    backgroundColor: theme.branding.v2.gray[100],
    borderRadius: 12,
    position: 'fixed',
    bottom: 24,
    [theme.breakpoints.down('xs')]: {
      bottom: 124,
      border: 'none',
      boxShadow: `8px 8px 24px ${theme.branding.v2.gray[500]}`,
      color: theme.branding.v2.gray[100],
      backgroundColor: theme.branding.v2.gray[800],
    },
  },
  titleCutoff: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
  },
  select: {
    width: '100%',
    borderRadius: 12,
    marginTop: 4,
    cursor: 'not-allowed',
  },
  sendOptionsContainer: {
    gap: '24px',
  },
  textInputError: {
    border: `1px solid ${theme.branding.v2.red[500]}`,
  },
}));

const SingleSendSms: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setLongTitle, setOptFooterFormat, setHeaderCTAs, setFooterCTAs } = props;

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const formikRef = useRef<FormikProps<any>>();
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { id: scheduledMessageId } = useParams<{ id: string }>();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const itemId = urlParams.get('itemId');
  const templateType: TemplateType = urlParams.get('type') as TemplateType;
  const smsTemplate: SmsTemplate = templateType && smsTemplates[templateType];
  const showTicketedEventsOnly = !smsTemplate?.showCustomProducts && !smsTemplate?.showStandardProducts && smsTemplate?.showTicketedEvents && !smsTemplate?.showPresales;

  const { account, isCouponsLoading, shop, storeCoupons } = useAppSelector(state => ({
    account: state.shops.account,
    isCouponsLoading: state.coupons.loading,
    shop: state.shops.shop,
    storeCoupons: state.coupons.coupons,
  }));

  const fuseOptions = {
    keys: ['title'],
    threshold: 0.5,
    useExtendedSearch: true,
  };

  // matches empty body text <p><br></p> or <p><br></p><p><br></p><p><br></p>... etc if multiple empty lines
  const emptyTextRegex = /^\s*$/;
  const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

  const timeZone = shop?.config?.timeZone || defaultTimeZone;

  const [scheduledMessage, setScheduledMessage] = useState<ScheduledMessage<any>>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [sendingTest, setSendingTest] = useState(false);
  const [testSmsSent, setTestSmsSent] = useState(false);

  const [itemExpanded, setItemExpanded] = useState(!smsTemplate?.isCoupon);
  const [contentExpanded, setContentExpanded] = useState(smsTemplate?.isCoupon);
  const [sendDetailsExpanded, setSendDetailsExpanded] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [showPreSendModal, setShowPreSendModal] = useState(false);
  const [sendScheduleType, setSendScheduleType] = useState(scheduledMessageId ? 'schedule' : 'now');

  const [items, setItems] = useState<PresaleOrProduct[]>([]);
  const [formattedItems, setFormattedItems] = useState([]);
  const [emptySearch, setEmptySearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [chosenItem, setChosenItem] = useState<PresaleOrProduct>();
  const [itemError, setItemError] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [contentError, setContentError] = useState(false);
  const [customersLoaded, setCustomersLoaded] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const [isGeneratingMessage, setIsGeneratingMessage] = useState<boolean>(false);
  const [hasGeneratedMessage, setHasGeneratedMessage] = useState<boolean>(false);

  const getScheduledMesssage = async () => {
    if (scheduledMessageId) {
      const scheduledMessage = await scheduledMessagesRepository.get(scheduledMessageId);
      setScheduledMessage(scheduledMessage);
    }
  };

  useEffect(() => {
    dispatch(getCouponAction(shop.id));
  }, []);

  const formatItems = (items: PresaleOrProduct[]) => {
    const radioOptions = items.map(item => ({
      label: item.title,
      background:
        item.itemType === 'product'
          ? getImageUrl(item, 'mediumVersion')
          : getPresaleImageUrl(item as Presale, 'mediumVersion'),
      value: item,
    }));
    setFormattedItems(radioOptions);
  };

  const getItems = async () => {
    let presales = [];
    if (smsTemplate?.showPresales) {
      presales = (
        (await getAllPresalesHandler({
          shopId: shop.id,
          status: 'active',
          timeZone: shop?.config?.timeZone,
        })) || []
      )?.map(presale => ({ ...presale, itemType: 'presale' }));
      if (smsTemplate?.eventTagFilter) {
        presales = presales.filter(presale =>
          presale?.products?.some(p => p?.eventTags?.includes(smsTemplate?.eventTagFilter)),
        );
      }
    }

    let products = [];
    const getProducts = smsTemplate?.showStandardProducts || smsTemplate?.showCustomProducts || smsTemplate?.showTicketedEvents;
    if (
      shop &&
      shop.id &&
      getProducts
    ) {
      let productsToShow = (await productRepository.findActive(shop.id, shop?.config?.timeZone)) || [];
      if (smsTemplate?.eventTagFilter) {
        productsToShow = productsToShow?.filter(product => product?.eventTags?.includes(smsTemplate?.eventTagFilter));
      }
      if (!smsTemplate?.showStandardProducts) {
        productsToShow = productsToShow?.filter(product => product.type !== 'standard');
      }
      if (!smsTemplate?.showCustomProducts) {
        productsToShow = productsToShow?.filter(product => product.type !== 'custom');
      }
      if (!smsTemplate?.showTicketedEvents) {
        productsToShow = productsToShow?.filter(product => product.type !== 'event');
      }
      const orderedProducts = productsToShow
        .map(product => ({
          ...product,
          lastEdited: product.updatedAt ? product.updatedAt : product.createdAt,
          itemType: 'product',
        }))
        .sort((a, b) => {
          return b.lastEdited - a.lastEdited;
        });
      products = orderedProducts;
    }
    setItems([...presales, ...products]);
    setIsLoading(false);
  };

  const getCustomers = async () => {
    if (shop && shop.id) {
      const subscribers = await customerRepository.findSmsSubscribed(shop.id);
      setCustomers(subscribers);
      setCustomersLoaded(true);
    }
  };

  const getSingleItem = (id: string) => {
    const chosenItem = items.find(item => id === item.id);
    setChosenItem(chosenItem);
    return chosenItem;
  };

  useEffect(() => {
    getScheduledMesssage();
    getItems();
    getCustomers();
  }, [shop]);

  useEffect(() => {
    formatItems(items);
    if (itemId) {
      getSingleItem(itemId);
    } else if (scheduledMessage) {
      getSingleItem(scheduledMessage?.message?.content?.itemId);
    }
  }, [items, scheduledMessage]);

  const testLabels = () => {
    if (sendingTest) {
      return <CircularProgress style={{ height: 24, width: 24 }} />;
    } else if (testSmsSent) {
      return 'Test Text Sent!';
    } else if (!sendingTest && !testSmsSent) {
      return 'Send a Test Text';
    }
  };

  const mobileDropdown = () => {
    const options: DropDownOption[] = [
      {
        label: 'Preview Text',
        icon: <VisibilityOutlinedIcon />,
        onClick: previewModal,
      },
      {
        label: 'Send a Test Text',
        icon: <SendOutlinedIcon />,
        onClick: sendTest,
      },
    ].map(a => a as DropDownOption);
    const ellipsisOptions: EllipsisMenuOption[] = options.map(option => ({
      display: option.label,
      color: option.color,
      icon: option.icon,
      action: option.onClick,
    }));

    return <EllipsisMenu options={ellipsisOptions} />;
  };

  useEffect(() => {
    setBackLocation(true);
    setPageTitle(
      (isMobile && smsTemplate?.pageTitleMobile) ||
        smsTemplate?.pageTitle ||
        (scheduledMessage && 'Edit Text') ||
        'New Text',
    );
    setLongTitle(true);

    isMobile
      ? setHeaderCTAs([
          mobileDropdown(),
          <ButtonBase onClick={previewModal}>
            <VisibilityOutlinedIcon />
          </ButtonBase>,
        ])
      : setHeaderCTAs([
          <Button variant="outlined" onClick={sendTest} style={{ minWidth: 190 }}>
            {testLabels()}
          </Button>,
        ]);

    return () => {
      setLongTitle(false);
      setPageTitle('');
      setBackLocation(false);
      setHeaderCTAs([]);
    };
  }, [isMobile, sendingTest, testSmsSent, scheduledMessage]);

  useEffect(() => {
    setOptFooterFormat('two-space-between');
    setFooterCTAs([
      scheduledMessageId ? (
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(
              openModal({
                modalType: 'DELETE_MARKETING_MODAL',
                modalProps: {
                  id: scheduledMessageId,
                  status: 'scheduled',
                  context: 'sms',
                },
              }),
            );
          }}
        >
          Delete
        </Button>
      ) : (
        <DeleteButton backLocation="/marketing/sms" isSubmitting={submitting} />
      ),
      <RequireStripe>
        <SaveButton
          isSubmitting={submitting}
          handleSubmit={() => {
            setShowPreSendModal(true);
          }}
          customName={sendScheduleType === 'schedule' ? 'Schedule Text' : 'Send Text'}
        />
      </RequireStripe>,
    ]);
    return () => {
      setOptFooterFormat('');
      setFooterCTAs([]);
    };
  }, [submitting, isXsMobile, customers, sendScheduleType]);

  const sendTest = async () => {
    if (!account?.config?.messagingPreferences?.sms?.enabled) {
      dispatch(
        openModal({
          modalType: 'SMS_NOTIFICATION_OPTIN_MODAL',
          modalProps: {
            show: true,
            context: 'marketing',
          },
        }),
      );
      return;
    }
    // Form validation for the url regex doesn't work unless this log is included (?)
    console.log('test', {
      values: formikRef.current.values,
      errors: formikRef.current.errors,
      test: urlRegex.test(formikRef.current.values.message),
    });
    if (!formikRef.current.values.itemId) {
      setItemError(true);
      !itemExpanded && setItemExpanded(true);
      closePreviewModal();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (
      (smsTemplate?.isCoupon &&
        formikRef.current.errors.couponId) ||
      formikRef.current.errors.message ||
      urlRegex.test(formikRef.current.values.message)
    ) {
      setContentError(true);
      !contentExpanded && setContentExpanded(true);
      closePreviewModal();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setSendingTest(true);
      setTestSmsSent(true);

      try {
        const currentValues = formikRef.current.values;
        await sendSmsAnnouncementService({
          customerIds: [],
          isTest: true,
          itemId:
            smsTemplate?.isCoupon
              ? currentValues.couponId
              : currentValues.itemId,
          itemType: currentValues.itemType,
          text: currentValues.message,
          utmCampaign: smsTemplate?.utmCampaign,
        });
        trackEvent('Test Marketing SMS Sent', {
          smsType: smsTemplate?.pageTitle || 'Custom',
          itemId:
            smsTemplate?.isCoupon
              ? currentValues.couponId
              : currentValues.itemId,
          itemType: currentValues.itemType,
        });
      } catch (err) {
        console.error('Error in Send Test Text: ', err);
      }
      setSendingTest(false);
      setTimeout(() => {
        setTestSmsSent(false);
      }, 2000);
    }
  };

  const handleSearchChange = (event: any) => {
    setEmptySearch(false);
    const fuse = new Fuse(items, fuseOptions);

    if (event.target.value === '') {
      formatItems(items);
    } else {
      const result = fuse.search(event.target.value);

      if (_.isEmpty(result)) {
        setEmptySearch(true);
        setSearchValue(event.target.value);
      } else {
        //get rid of extra formatting so we can reuse function
        const formattedSearch = [];
        result.forEach(o => formattedSearch.push(o.item));
        formatItems(formattedSearch);
      }
    }
  };

  const scrollToTop = (containerId: string) => {
    const scrollTo = document.getElementById(`${containerId}Error`);

    const yOffset = -120;
    const y = scrollTo.getBoundingClientRect().top + window.pageYOffset + yOffset;

    // scrolls to the error within the scroll container, then scrolls to the element on the page
    scrollTo.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const onSubmit = async values => {
    // Form validation for the url regex doesn't work unless this log is included (?)
    console.log('submit', { values, errors: formikRef.current.errors, test: urlRegex.test(values.message) });
    setSubmitting(true);
    if (!values.itemId) {
      setItemError(true);
      !itemExpanded && setItemExpanded(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (
      (smsTemplate?.isCoupon &&
        formikRef.current.errors.couponId) ||
      formikRef.current.errors.message ||
      urlRegex.test(values.message)
    ) {
      setContentError(true);
      if (contentExpanded) {
        scrollToTop('content');
      } else {
        setContentExpanded(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else if (sendScheduleType === 'schedule' && values.sendTime <= moment().unix()) {
      !sendDetailsExpanded && document.getElementById('send').scrollIntoView({ behavior: 'smooth' });
      setSendDetailsExpanded(true);
      setTimeError(true);
    } else {
      try {
        const isScheduledSend =
          sendScheduleType === 'schedule' &&
          values?.sendTime &&
          values.sendTime >=
            moment()
              .tz(timeZone)
              .unix();
        if (scheduledMessage && !isScheduledSend) {
          await scheduledMessagesRepository.updateProps(scheduledMessageId, { status: 'sent' });
        }
        const subscribersArr = customers.map(s => s.id);
        const messageContent = {
          itemId:
            smsTemplate?.isCoupon
              ? values.couponId
              : values.itemId,
          itemType: values.itemType,
          text: values.message,
          utmCampaign: smsTemplate?.utmCampaign,
          ...(isScheduledSend && { sendAt: values.sendTime }),
          ...(isScheduledSend && { scheduledMessageId }),
          templateType,
        };

        await sendSmsAnnouncementService({ customerIds: subscribersArr, isTest: false, ...messageContent });

        trackEvent('Marketing SMS Sent', {
          smsType: smsTemplate?.pageTitle || 'Custom',
          itemId:
            smsTemplate?.isCoupon
              ? values.couponId
              : values.itemId,
          itemType: values.itemType,
          numberOfSubscribers: subscribersArr.length,
          sendTime: {
            type: isScheduledSend ? 'scheduled' : 'instant',
            time: isScheduledSend ? values.sendTime : moment().unix(),
          },
        });
        if (values.generationIdMessage) {
          const editDistance = levenshtein(values.rawGeneratedMessage, values.message);
          trackEvent('AI Generated Content Result', {
            generationId: values.generationIdMessage,
            editDistance,
            type: `sms-${values.itemType}`,
          });
        }
        openPostSendModal(isScheduledSend, values.sendTime);
        if (!shop?.checklistCompletions?.includes(ChecklistValues.SmsMarketingSingleSend)) {
          shop?.addToChecklist(ChecklistValues.SmsMarketingSingleSend);
        }
        formikRef.current.resetForm();
      } catch (err) {
        console.error('Error in Submit SMS Send: ', err);
      }
    }
    setSubmitting(false);
  };

  const formSchema = yup.object().shape({
    itemId: yup.string().required('Please select an item.'),
    itemType: yup.string().oneOf(['product', 'presale', 'coupon']),
    message: yup
      .string()
      .required('please enter a message')
      .test('Empty Message', 'please enter a message', value => !emptyTextRegex.test(value))
      .test('Message Limit', 'please limit your message to 160 characters', value => value?.length <= 160)
      .test('URL', 'please do not include a link', value => !urlRegex.test(value)),
    couponId: yup
      .string()
      .required('please select a coupon')
      .test('Empty Coupon', 'please select a coupon', value => !emptyTextRegex.test(value)),
    generationIdMessage: yup.string().nullable(),
    rawGeneratedMessage: yup.string().nullable(),
    sendTime: yup
      .number()
      .nullable()
      .moreThan(
        moment()
          .tz(timeZone)
          .subtract(5, 'minutes')
          .unix(),
        'Please select a time in the future.',
      ),
  });

  const initialValues = {
    itemId: scheduledMessage?.message?.content?.itemId
      ? scheduledMessage?.message?.content?.itemId
      : smsTemplate?.isCoupon
      ? 'coupon'
      : chosenItem?.id || '',
    itemType: scheduledMessage?.message?.content?.itemType
      ? scheduledMessage?.message?.content?.itemType
      : smsTemplate?.isCoupon
      ? 'coupon'
      : chosenItem?.itemType || '',
    message: scheduledMessage?.message?.content?.text
      ? scheduledMessage?.message?.content?.text
      : smsTemplate?.message || '',
    couponId: scheduledMessage
      ? scheduledMessage?.message?.content?.couponId || 'coupon'
      : !smsTemplate?.isCoupon
      ? 'coupon'
      : '',
    generationIdMessage: '',
    rawGeneratedMessage: '',
    sendTime: scheduledMessage
      ? scheduledMessage.sendAt
      : moment()
          .tz(timeZone)
          .unix(),
  };

  const previewModal = () => {
    setOpenPreviewModal(true);
  };

  const closePreviewModal = () => {
    setOpenPreviewModal(false);
  };

  const smsPreview = (
    <>
      <img
        src={
          smsTemplate?.isCoupon
            ? SmsCouponPromoPreview
            : SmsPreview
        }
        className={classes.previewImage}
      />
      <Banner variant="info-white" className={classes.banner}>
        <Typography variant="body2" style={{ color: 'inherit' }}>
          This preview is for your reference only. Curious what customers see?{isMobile ? <br /> : ' '}
          <span onClick={sendTest} className={classes.infoSend}>
            Send yourself a test text
          </span>
          .
        </Typography>
      </Banner>
    </>
  );

  const audienceOptions = [
    {
      label: `All SMS Subscribers (${customers.length})`,
      value: 'all',
    },
  ];

  const couponOptions = storeCoupons.map(coupon => ({
    label: `${coupon.code} - ${
      coupon.discount.type === 'amount'
        ? Dinero({ amount: coupon.discount.value }).toFormat('$0.00')
        : `${coupon.discount.value}%`
    } off${
      coupon?.minimum?.type === 'orderQuantity'
        ? `, ${coupon?.minimum?.value} item min`
        : coupon?.minimum?.type === 'orderValue'
        ? `, ${coupon?.minimum?.value} item min`
        : ''
    }`,
    value: coupon.id,
  }));

  const renderPreSendModal = values => {
    const isScheduledSend =
      sendScheduleType === 'schedule' &&
      values?.sendTime &&
      values.sendTime >=
        moment()
          .tz(timeZone)
          .unix();

    return (
      <GeneralModal
        paperClass={classes.modalPaperClass}
        icon="💬"
        title={
          isScheduledSend
            ? `Your text is scheduled to be delivered on ${moment
                .unix(values.sendTime)
                .tz(timeZone)
                .format('l [at] h:mm A')}`
            : `Ready to send this text to ${customers.length} subscriber${customers.length !== 1 ? 's' : ''}?`
        }
        content={
          <>
            <Typography variant="body1">
              Prefer to preview your text before sending it to your subscriber{customers.length !== 1 ? 's' : ''}?
              {isXsMobile ? <br /> : ' '}
              <span onClick={sendTest} className={classes.sendText}>
                Send a Test Text
              </span>
            </Typography>
            {testSmsSent && (
              <Typography variant="button" component="p" className={classes.sendTestSmsText}>
                {testLabels()}
              </Typography>
            )}
          </>
        }
        actions={[
          <Button
            variant="outlined"
            onClick={() => {
              setShowPreSendModal(false);
            }}
          >
            Continue Editing
          </Button>,
          <RequireStripe>
            <Button
              variant="contained"
              onClick={() => {
                setShowPreSendModal(false);
                onSubmit(formikRef.current.values);
              }}
            >
              {isScheduledSend ? 'Schedule Text' : 'Send Text'}
            </Button>
          </RequireStripe>,
        ]}
        onClose={() => {
          setShowPreSendModal(false);
        }}
        show={showPreSendModal}
      />
    );
  };

  const getPostSendModalContent = (isScheduledSend: boolean, sendTime: number) => {
    if (smsTemplate?.eventName) {
      return (
        <>
          You've {isScheduledSend ? 'scheduled your' : 'shared your'} {smsTemplate?.eventName} offerings{' '}
          {isScheduledSend && 'to be shared '} with{' '}
          <b>
            {customers.length} subscriber{customers.length !== 1 ? 's' : ''}
          </b>
          . Get your ingredients ready! It’s almost time to get cooking!
        </>
      );
    } else {
      return (
        <>
          <b>
            {customers.length} subscriber{customers.length !== 1 ? 's' : ''}
          </b>{' '}
          {isScheduledSend ? 'will be' : customers.length !== 1 ? 'have been' : 'has been'} notified about your{' '}
          {templateType === 'product-promo'
            ? 'product'
            : smsTemplate?.isCoupon
            ? 'coupon'
            : templateType === 'event-promo'
            ? 'event'
            : templateType === 'presale-promo'
            ? 'presale'
            : showTicketedEventsOnly
            ? 'event'
            : 'item'}
          .{showTicketedEventsOnly ? '' : ' Get your ingredients ready! It’s almost time to get cooking!'}
        </>
      );
    }
  };

  const openPostSendModal = (isScheduledSend: boolean, sendTime: number) =>
    dispatch(
      openModal({
        modalType: 'GENERAL_MODAL' as ModalType,
        modalProps: {
          paperClass: classes.modalPaperClass,
          icon: '🎉',
          title: `${smsTemplate?.postSendModalTitlePrefix || 'SMS'} ${isScheduledSend ? 'Scheduled!' : 'Sent!'}`,
          content: (
            <Grid container item direction="column" wrap="nowrap" justify="flex-start">
              <Typography variant="body1">{getPostSendModalContent(isScheduledSend, sendTime)}</Typography>
            </Grid>
          ),
          actions: [
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(closeModal());
                window.scrollTo(0, 0);
                setItemExpanded(true);
                setContentExpanded(false);
                setSendDetailsExpanded(false);
              }}
            >
              Create Another Text
            </Button>,
            <Button
              variant="contained"
              onClick={() => {
                dispatch(closeModal());
                history.push('/marketing/sms');
              }}
            >
              Close
            </Button>,
          ],
          show: true,
        },
      }),
    );

  const getEmptyItemsBanner = () => {
    let content;
    if (smsTemplate?.eventTagFilter) {
      content = (
        <>
          Please tag an active{' '}
          <span onClick={() => history.push('/products')} className={classes.infoSend}>
            product
          </span>{' '}
          with your event or create an active{' '}
          <span onClick={() => history.push('/presales')} className={classes.infoSend}>
            presale
          </span>{' '}
          to continue.
        </>
      );
    } else if (templateType === 'product-promo') {
      content = (
        <>
          Please create an active{' '}
          <span onClick={() => history.push('/products')} className={classes.infoSend}>
            product
          </span>{' '}
          to continue.
        </>
      );
    } else if (templateType === 'event-promo' || templateType === 'presale-promo') {
      content = (
        <>
          Please create an active{' '}
          <span onClick={() => history.push('/presales')} className={classes.infoSend}>
            presale
          </span>{' '}
          to continue.
        </>
      );
    } else if (showTicketedEventsOnly) {
      content = (
        <>
          Please create an active{' '}
          <span onClick={() => history.push('/events')} className={classes.infoSend}>
            event
          </span>{' '}
          to continue.
        </>
      );
    }else {
      content = <>No items available.</>;
    }
    return (
      <Banner variant="info-blue">
        <Typography variant="body2" style={{ color: 'inherit' }}>
          {content}
        </Typography>
      </Banner>
    );
  };

  const generateMessage = async () => {
    if (formikRef?.current?.values?.itemType) {
      setIsGeneratingMessage(true);
      const response = await generateSmsService({
        itemType: formikRef?.current?.values?.itemType,
        itemId: formikRef?.current?.values?.itemId,
        eventName: smsTemplate?.eventName,
      });
      setHasGeneratedMessage(true);
      await formikRef?.current?.setFieldValue('generationIdMessage', response.generationId);
      await formikRef?.current?.setFieldValue('rawGeneratedMessage', response.message);
      await formikRef?.current?.setFieldValue('message', response.message);
      if (response.message?.length > 160) {
        setContentError(true);
        scrollToTop('content');
      }
      setIsGeneratingMessage(false);
    } else {
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            buttonText: 'Close',
            content: (
              <Grid container justify="center" alignItems="center" style={{ margin: '15px 0px' }}>
                <Typography variant="body1">Select a Product to Write with AI.</Typography>
              </Grid>
            ),
            center: true,
          },
        }),
      );
    }
  };

  const writeItForMe = () => (
    <Button
      loading={isGeneratingMessage}
      disabled={isGeneratingMessage}
      variant="text"
      onClick={generateMessage}
      style={{ padding: 0 }}
    >
      ✨ {hasGeneratedMessage ? 'Try Writing Again' : 'Write with AI'}
    </Button>
  );

  const handleDayChange = async (day: moment.Moment | null, values, setFieldValue) => {
    if (day && day.isValid()) {
      const newTime = moment.unix(values?.sendTime);
      newTime.set({
        year: day.get('year'),
        month: day.get('month'),
        date: day.get('date'),
      });

      await setFieldValue('sendTime', newTime.tz(timeZone, true).unix());
    }
  };

  const handleTimeChange = async (time: moment.Moment | null, values, setFieldValue) => {
    if (time && time.isValid()) {
      setTimeError(false);
      const newTime = moment.unix(values?.sendTime);
      newTime.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: 0,
        millisecond: 0,
      });

      await setFieldValue('sendTime', newTime.tz(timeZone, true).unix());
    }
  };

  return isLoading || isCouponsLoading ? (
    <Spinner size="fullscreen" show={true} />
  ) : (
    <>
      <Formik onSubmit={onSubmit} validationSchema={formSchema} initialValues={initialValues} innerRef={formikRef}>
        {({ dirty, values, setFieldValue, errors }): ReactElement => {
          return (
            <AdminForm>
              <UnsavedChangesPrompt when={dirty} />
              <Grid container className={classes.innerContainer}>
                <Grid container item xs justify="center">
                  {customersLoaded && customers.length === 0 && (
                    <Banner
                      variant="error"
                      className={classes.noContactsBanner}
                      children={
                        <Grid container item justify="space-between" direction="row" alignItems="flex-start">
                          <Grid container item xs={8}>
                            <Typography variant="body2" style={{ color: 'inherit' }}>
                              You don't have any SMS subscribers yet. To continue, share your email and SMS sign up link
                              with your audience & start collecting subscribers to market to!
                            </Typography>
                          </Grid>
                          <Grid container item xs={4} justify="flex-end">
                            <Button
                              variant="outlined"
                              className={classes.addContacts}
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    modalType: 'SHARE_LINKS_MODAL',
                                    modalProps: {
                                      show: true,
                                    },
                                  }),
                                );
                              }}
                              color="inherit"
                            >
                              <Typography variant="button" style={{ color: 'inherit' }}>
                                Share Link
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      }
                    />
                  )}
                  <Grid container className={classes.cardContainer}>
                    {!smsTemplate?.isCoupon && (
                      <CollapsableCard
                        cta={
                          <Button
                            onClick={() => {
                              if (_.isEmpty(values.itemId)) {
                                scrollToTop('item');
                                setItemError(true);
                              } else {
                                setItemExpanded(false);
                                setContentExpanded(true);
                              }
                            }}
                            variant="contained"
                            fullWidth
                            disabled={_.isEmpty(items)}
                          >
                            Next Step: Content
                          </Button>
                        }
                        expanded={itemExpanded}
                        handleExpand={() => {
                          setItemExpanded(!itemExpanded);
                        }}
                        id="item"
                        supertitle="STEP 1/3"
                        title={
                          <Typography variant="subtitle1" className={classes.titleCutoff}>
                            {!_.isEmpty(values.itemId)
                              ? `${smsTemplate?.step1TitleSelectedPrefix || 'Selected'}: ${chosenItem?.title}`
                              : smsTemplate?.step1TitleUnselected || 'Featured Item'}
                          </Typography>
                        }
                      >
                        <div id="itemError">
                          {itemError && (
                            <Grid className={classes.errorBox}>
                              <ErrorOutlineIcon />
                              <Typography variant="body2" className={classes.errorText}>
                                Please select an item to continue.
                              </Typography>
                            </Grid>
                          )}
                        </div>
                        {_.isEmpty(items) ? (
                          getEmptyItemsBanner()
                        ) : (
                          <>
                            <Grid style={{ marginBottom: 8 }}>
                              <SearchInput onChange={handleSearchChange} />
                            </Grid>
                            {emptySearch ? (
                              <Grid container className={classes.emptySearch}>
                                <SearchOutlinedIcon className={classes.searchIcon} />
                                <Typography variant="body3">
                                  No results for <span style={{ fontWeight: 700 }}>"{searchValue}"</span>
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid container item>
                                <ImageRadioOrCheckboxInput
                                  options={formattedItems}
                                  onChange={value => {
                                    setItemError(false);
                                    const item = getSingleItem(value);
                                    setFieldValue('itemId', value);
                                    setFieldValue('itemType', item?.itemType);
                                  }}
                                  value={getIn(values, 'itemId')}
                                />
                              </Grid>
                            )}
                          </>
                        )}
                      </CollapsableCard>
                    )}

                    <CollapsableCard
                      cta={
                        <Button
                          onClick={() => {
                            if (
                              errors.message ||
                              (smsTemplate?.isCoupon &&
                                errors.couponId) ||
                              urlRegex.test(values.message)
                            ) {
                              scrollToTop('content');
                              setContentError(true);
                            } else {
                              setContentExpanded(false);
                              document.getElementById('send').scrollIntoView({ behavior: 'smooth', block: 'start' });
                              setSendDetailsExpanded(true);
                            }
                          }}
                          variant="contained"
                          fullWidth
                        >
                          Next Step: Send Details
                        </Button>
                      }
                      expanded={contentExpanded}
                      handleExpand={() => {
                        setContentExpanded(!contentExpanded);
                      }}
                      id="content"
                      noScroll={isMobile}
                      supertitle={
                        smsTemplate?.isCoupon
                          ? 'STEP 1/2'
                          : 'STEP 2/3'
                      }
                      title="Content"
                    >
                      <div id="contentError">
                        {contentError && (
                          <Grid className={classes.errorBox}>
                            <ErrorOutlineIcon />
                            <Typography variant="body2" className={classes.errorText}>
                              Please address the following to continue:{' '}
                              {_.uniq([
                                errors.message,
                                errors.couponId,
                                urlRegex.test(formikRef.current.values.message) && 'please do not include a link',
                              ])
                                .filter(error => error)
                                .join(', ')}
                              .
                            </Typography>
                          </Grid>
                        )}
                      </div>
                      <Grid style={{ marginBottom: '24px' }}>
                        <Banner variant="info-white" noIcon backgroundColor={theme.branding.v2.gray[50]}>
                          <Typography variant="body2">
                            💬 <b>We’ve got your back</b>: We add your shop name and opt out instructions for all texts
                            to comply with U.S. SMS marketing regulations.
                          </Typography>
                        </Banner>
                      </Grid>
                      <Grid>
                        <TextInput
                          className={contentError && errors.message ? classes.textInputError : undefined}
                          helpText={`A link to your${
                            showTicketedEventsOnly
                              ? ' event'
                              : `${!smsTemplate?.isCoupon ? ' product or ' : ' '}shop`
                          } is automatically
                          added to your message.`}
                          label={
                            <Grid container justify="space-between">
                              <Typography variant="subtitle2" style={{ width: 'fit-content' }}>
                                Message
                              </Typography>
                              {!smsTemplate?.isCoupon &&
                                writeItForMe()}
                            </Grid>
                          }
                          maxLength={
                            smsTemplate?.isCoupon ? 80 : 160
                          }
                          multiline
                          name="message"
                          onChange={e => {
                            setContentError(false);
                            setFieldValue('message', e.target.value);
                          }}
                          rows={
                            isMobile && !smsTemplate?.isCoupon
                              ? 4
                              : 2
                          }
                        />
                        {smsTemplate?.isCoupon && (
                          <>
                            <Typography variant="subtitle2" style={{ marginTop: 24 }}>
                              Coupon
                            </Typography>
                            <Select
                              className={classes.select}
                              disabled={!storeCoupons || storeCoupons?.length === 0}
                              displayEmpty
                              variant="outlined"
                              onChange={e => {
                                setContentError(false);
                                setFieldValue('couponId', e.target.value);
                              }}
                              value={getIn(values, 'couponId')}
                            >
                              <MenuItem value="">Select a Coupon</MenuItem>
                              {couponOptions.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography variant="caption" style={{ color: theme.branding.v2.gray[500] }}>
                              Can’t find what you’re looking for?{' '}
                              <Link href={`${process.env.REACT_APP_ADMIN_URL}store/coupons`} target="_blank">
                                Manage coupons
                              </Link>
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </CollapsableCard>

                    <CollapsableCard
                      expanded={sendDetailsExpanded}
                      handleExpand={() => {
                        !sendDetailsExpanded && document.getElementById('send').scrollIntoView({ behavior: 'smooth' });
                        setSendDetailsExpanded(!sendDetailsExpanded);
                      }}
                      id="send"
                      noScroll
                      supertitle={
                        smsTemplate?.isCoupon
                          ? 'STEP 2/2'
                          : 'STEP 3/3'
                      }
                      title="Send Details"
                    >
                      <Grid xs={12} container item direction="column" className={classes.sendOptionsContainer}>
                        <div id="timeError">
                          {timeError && (
                            <Grid className={classes.errorBox}>
                              <ErrorOutlineIcon />
                              <Typography variant="body2" className={classes.errorText}>
                                Please select a time in the future to schedule your text, or select "Send Now".
                              </Typography>
                            </Grid>
                          )}
                        </div>
                        <Grid container item direction="column">
                          <Typography variant="subtitle2">Audience</Typography>
                          <Select
                            className={`${classes.noArrow} ${classes.select}`}
                            disabled
                            variant="outlined"
                            defaultValue="all"
                          >
                            {audienceOptions.map((option, index) => (
                              <MenuItem value={option.value} key={index}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid container item direction="column" style={{ gap: '12px' }}>
                          <Typography variant="subtitle2">Send Time</Typography>
                          <FormControl component="fieldset" style={{ margin: 0 }}>
                            <RadioGroup
                              value={sendScheduleType}
                              onChange={e => setSendScheduleType(e.target.value)}
                              style={{ gap: '24px' }}
                            >
                              <FormControlLabel
                                value="now"
                                control={<Radio />}
                                label={<Typography variant="body1">Send Now</Typography>}
                                className={classes.radioLabel}
                              />
                              <FormControlLabel
                                value="schedule"
                                control={<Radio />}
                                label={<Typography variant="body1">Schedule Send</Typography>}
                                className={classes.radioLabel}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {sendScheduleType === 'schedule' && (
                          <Grid container direction={isMobile ? 'column' : 'row'} wrap="nowrap" style={{ gap: '16px' }}>
                            <DateInput
                              disablePast
                              label="Delivery Date"
                              selectedDate={moment.unix(values?.sendTime)}
                              onChange={date => handleDayChange(date, values, setFieldValue)}
                              required
                            />
                            <TimeInput
                              label="Delivery Time"
                              name="sendTime"
                              required
                              onChange={time => handleTimeChange(time, values, setFieldValue)}
                              timeZone={timeZone}
                              addErrorStyling={!!errors.sendTime}
                              error={!!errors.sendTime && errors.sendTime}
                            />
                          </Grid>
                        )}
                        <Banner variant="info-blue">
                          <Typography variant="body2" style={{ color: 'inherit' }}>
                            Curious what customers see? 
                            <span className={classes.infoSend} onClick={sendTest}>
                              Send yourself a test text.
                            </span>
                          </Typography>
                        </Banner>
                      </Grid>
                    </CollapsableCard>
                  </Grid>
                </Grid>
                {!isMobile && (
                  <Grid container item direction="column" wrap="nowrap" style={{ maxWidth: 'fit-content' }}>
                    {smsPreview}
                  </Grid>
                )}
              </Grid>
              {renderPreSendModal(values)}
            </AdminForm>
          );
        }}
      </Formik>
      <Dialog fullScreen={true} open={openPreviewModal} onClose={closePreviewModal}>
        <DialogTitle className={classes.previewModalHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h2">Text Preview</Typography>
            <IconButton onClick={closePreviewModal}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.previewModalContainer}>
          <Grid container item direction="column" alignItems="center">
            {smsPreview}
            {testSmsSent && (
              <Typography variant="button" component="p" className={classes.sendTestSmsText}>
                {testLabels()}
              </Typography>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SingleSendSms;
