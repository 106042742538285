import { Theme } from '@material-ui/core';
import { Shop } from '@castiron/domain';
import { defaultTimeZone } from '@castiron/utils';
import { specialRepository } from '../../../domain';

export const getSingleSendEmails = async (theme: Theme, isPresalesEnabled: boolean, activeEventTags: string[]) => {
  //in order that they should display in modal
  const templates = [
    {
      icon: '💬',
      iconBackgroundColor: theme.branding.v2.gray[50],
      pillText: 'New!',
      name: 'Sign Up for SMS Email',
      subheader: 'Encourage email subscribers to opt in to SMS.',
      route: '/marketing/single-send-email?type=sms-signup',
      event: 'SMS Signup Email Created',
      category: 'Basic',
    },
    {
      icon: '🍪',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Product Announcement Email',
      subheader: 'Notify customers about a new product.',
      route: '/marketing/single-send-email?type=new-product',
      category: 'Basic',
    },
    {
      icon: '🗓️',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Custom Order Availability Email',
      subheader: 'Notify customers about custom order availability.',
      route: '/marketing/single-send-email?type=custom-order-available',
      category: 'Basic',
    },
    {
      icon: '👩‍🏫',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Ticketed Event Announcement Email',
      subheader: 'Drive ticket sales for an upcoming in person or virtual event.',
      route: '/marketing/single-send-email?type=ticketed-event-announcement',
      category: 'Basic',
    },
    ...(isPresalesEnabled
      ? [
          {
            icon: '🛒',
            iconBackgroundColor: theme.branding.v2.gray[50],
            name: 'Presale Announcement Email',
            subheader: 'Notify customers about an active presale.',
            route: '/marketing/single-send-email?type=new-presale',
            category: 'Basic',
          },
        ]
      : []),
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 8',
      name: '4th of July Announcement Email',
      subheader: 'Introduce your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-announcement',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 11',
      name: '4th of July Customs Email',
      subheader: 'Share about your custom order offerings for 4th of July.',
      route: '/marketing/single-send-email?type=4th-of-july-customs',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 14',
      name: '4th of July Reminder Email',
      subheader: 'Remind customers of your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-reminder',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 21',
      name: '4th of July Engagement Email',
      subheader: 'Engage with customers about your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-engagement',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 28',
      name: '4th of July Shop Email',
      subheader: 'Remind customers of your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-shop',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 30',
      name: '4th of July Last Call Email',
      subheader: 'Engage with customers about your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-last-call',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Announcement Email',
      subheader: 'Introduce your graduation offerings.',
      route: '/marketing/single-send-email?type=graduation-announcement',
      event: 'Graduation Email Created',
      eventTag: 'Graduation',
      category: 'Graduation',
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Reminder Email',
      subheader: 'Remind customers of your graduation offerings.',
      route: '/marketing/single-send-email?type=graduation-reminder',
      event: 'Graduation Email Created',
      eventTag: 'Graduation',
      category: 'Graduation',
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Feature Email',
      subheader: 'Highlight your biggest seller for graduation.',
      route: '/marketing/single-send-email?type=graduation-feature',
      event: 'Graduation Email Created',
      eventTag: 'Graduation',
      category: 'Graduation',
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Last Call Email',
      subheader: 'Last call for graduation orders!',
      route: '/marketing/single-send-email?type=graduation-last-call',
      event: 'Graduation Email Created',
      eventTag: 'Graduation',
      category: 'Graduation',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Announcement Email',
      subheader: 'Introduce your back-to-school offerings.',
      route: '/marketing/single-send-email?type=back-to-school-announcement',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Reminder Email',
      subheader: 'Remind customers to shop your back-to-school offerings.',
      route: '/marketing/single-send-email?type=back-to-school-reminder',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Shop Email',
      subheader: 'Promote your back-to-school offerings.',
      route: '/marketing/single-send-email?type=back-to-school-shop',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Custom Order Email',
      subheader: 'Share your back-to-school custom orders.',
      route: '/marketing/single-send-email?type=back-to-school-customs',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Last Call Email',
      subheader: 'Last call for back-to-school orders!',
      route: '/marketing/single-send-email?type=back-to-school-last-call',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Fall Announcement',
      subheader: 'Introduce customers to your fall delicacies.',
      route: '/marketing/single-send-email?type=fall-announcement',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Fall Reminder',
      subheader: 'Notify customers about your fall delicacies.',
      route: '/marketing/single-send-email?type=fall-reminder',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Shop Fall Products',
      subheader: 'Engage customers with your autumn-filled offerings.',
      route: '/marketing/single-send-email?type=shop-fall',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Custom Order for Fall',
      subheader: 'Share your Fall custom orders.',
      route: '/marketing/single-send-email?type=fall-customs',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Last-Call for Fall Products',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-email?type=last-call-fall',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Halloween Announcement',
      subheader: "Introduce your spooky offerings.",
      route: '/marketing/single-send-email?type=halloween-announcement',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Halloween Reminder',
      subheader: "Remind customers to shop your Halloween offerings.",
      route: '/marketing/single-send-email?type=halloween-reminder',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Shop Halloween Products',
      subheader: "Encourage shoppers to check out your Halloween offerings.",
      route: '/marketing/single-send-email?type=shop-halloween',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Custom Halloween-Themed Order Email',
      subheader: "Offer personal treats for a better holiday.",
      route: '/marketing/single-send-email?type=halloween-customs',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Last-Call for Halloween Treats',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-email?type=last-call-halloween',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Thanksgiving Announcement',
      subheader: "Introduce customers to your Thanksgiving delicacies.",
      route: '/marketing/single-send-email?type=thanksgiving-announcement',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Thanksgiving Reminder',
      subheader: "Notify customers about your Thanksgiving delicacies.",
      route: '/marketing/single-send-email?type=thanksgiving-reminder',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Shop Thanksgiving Products',
      subheader: "Engage customers with your Thanksgiving offerings.",
      route: '/marketing/single-send-email?type=shop-thanksgiving',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Custom Order for Thanksgiving',
      subheader: "Share your Thanksgiving custom orders.",
      route: '/marketing/single-send-email?type=thanksgiving-customs',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Last-Call for Thanksgiving Products',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-email?type=last-call-thanksgiving',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Christmas Announcement',
      subheader: "Introduce your Christmas offerings.",
      route: '/marketing/single-send-email?type=christmas-announcement',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Christmas Reminder',
      subheader: "Remind customers to shop your Christmas offerings.",
      route: '/marketing/single-send-email?type=christmas-reminder',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Shop Christmas Products',
      subheader: "Encourage shoppers to check out your Christmas offerings.",
      route: '/marketing/single-send-email?type=shop-christmas',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Custom Christmas Order Email',
      subheader: "Offer personal treats for a better holiday.",
      route: '/marketing/single-send-email?type=christmas-customs',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Last-Call for Christmas Treats',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-email?type=last-call-christmas',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
  ];

  const filteredTemplates = templates.filter(template => {
    if (!template.eventTag || activeEventTags.includes(template.eventTag)) return template;
    else return;
  });

  return filteredTemplates;
};

export const emailCategoriesMap = [
  {
    icon: '📣',
    name: 'Basic',
    description: 'Product announcements, custom orders, and more.',
  },
  {
    icon: '🧢',
    name: "Father's Day",
    description: "Incite customers to interact with your father's day offerings.",
    eventTag: 'FathersDay',
  },
  {
    icon: '🎓',
    name: 'Graduation',
    description: 'Notify customers about graduation offerings.',
    eventTag: 'Graduation',
  },
  {
    icon: '🎇',
    name: '4th of July',
    description: 'Engage customers with all you are offering for this celebration.',
    eventTag: '4thOfJuly',
  },
  {
    icon: '📚',
    name: 'Back to School',
    description: 'Incite customers to interact with your back to school offerings.',
    eventTag: 'BackToSchool',
  },
  {
    icon: '🍁',
    iconBackground: '#F9F5EE',
    name: 'Fall',
    description: 'Share all about your Fall delicacies.',
    eventTag: 'Fall',
  },
  {
    icon: '🎃',
    iconBackground: '#EDE7F6',
    name: 'Halloween',
    description: 'Notify customers about your Halloween-themed offerings.',
    eventTag: 'Halloween',
  },
  {
    icon: '🦃',
    iconBackground: '#FFFDE7',
    name: 'Thanksgiving',
    description: 'Notify customers about your Thanksgiving offerings.',
    eventTag: 'Thanksgiving',
  },
  {
    icon: '🎄',
    iconBackground: '#FFEBEE',
    name: 'Christmas',
    description: 'Notify customers about your Christmas offerings.',
    eventTag: 'Christmas',
  },
];
