import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonBase, Typography, Grid, Popover } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { SpeechBubbleIcon } from '@castiron/components';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';

type Props = {
  attendee: any;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dropdownButton: {
    height: 50,
    width: 50,
    borderRadius: 12,
    '&:hover': {
      color: theme.branding.v2.blue[500],
    },
  },
  dropdownContainer: {
    borderRadius: '16px',
    padding: '16px 0px',
    '&.MuiPaper-elevation1': {
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.24) !important',
    },
  },
  menuIcon: {
    color: theme.branding.v2.gray[800],
    height: 24,
    width: 24,
    '&:hover': {
      color: theme.branding.v2.gray[900],
    },
  },
  menuOption: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '8px 24px',
    gap: '16px',
    '&:hover': {
      backgroundColor: `${theme.branding.v2.gray[100]}4D`,
    },
  },
}));

const AttendeeActionsDropdown: React.FC<Props> = (props: Props) => {
  const { attendee, onClose } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [attendeesAnchorEl, setAttendeesAnchorEl] = useState(null);

  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();

    setAttendeesAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();

    !!onClose && onClose(event);
    setAttendeesAnchorEl(null);
  };

  return (
    <>
      <ButtonBase onClick={handleDropdownClick} style={{ borderRadius: 12 }}>
        <Grid
          container
          item
          justify="center"
          alignContent="center"
          alignItems="center"
          className={classes.dropdownButton}
        >
          <MoreVertIcon className={classes.menuIcon} />
        </Grid>
      </ButtonBase>
      <Popover
        open={!!attendeesAnchorEl}
        onClose={handleDropdownClose}
        anchorEl={attendeesAnchorEl}
        classes={{ paper: classes.dropdownContainer }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Grid container direction="column" justify="center">
          <ButtonBase
            focusRipple
            className={classes.menuOption}
            onClick={event => {
              event.stopPropagation();

              setAttendeesAnchorEl(null);
              dispatch(
                openModal({
                  modalType: 'CONTACT_MODAL',
                  modalProps: {
                    show: true,
                    eventAttendee: {
                      name: attendee.name,
                      email: !!attendee.email ? attendee.email : attendee.customer.email,
                      mobileNumber: !!attendee.mobileNumber ? attendee.mobileNumber : attendee.customer.mobileNumber,
                    },
                  },
                }),
              );
            }}
          >
            <SpeechBubbleIcon />
            <Typography variant="subtitle2">Message</Typography>
          </ButtonBase>
          <ButtonBase
            focusRipple
            className={classes.menuOption}
            onClick={event => {
              history.push(`/orders/edit/${attendee.transactionId}`);
              handleDropdownClose(event);
            }}
          >
            <ListAltOutlinedIcon />
            <Typography variant="subtitle2">Open Order</Typography>
          </ButtonBase>
        </Grid>
      </Popover>
    </>
  );
};

export default AttendeeActionsDropdown;
