import * as yup from 'yup';

/* if there is anything more than a model here, I would like to move it to model.ts */
export interface Address {
  fullAddress?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  region?: string;
  regionName?: string;
  postalCode: string;
  country?: string;
  latitude?: number;
  longitude?: number;
}

export const addressSchema = (required: boolean, errorText?: string) =>
  yup.object({
    fullAddress: required ? yup.string().required(errorText || 'Please select an option from the dropdown.') : yup.string(),
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    city: yup.string(),
    region: yup.string(),
    regionName: yup.string(),
    postalCode: yup.string(),
    country: yup.string(),
    latitude: yup.number(),
    longitude: yup.number(),
  });

  export const getAddressText = (address: Address) => {
    return address?.addressLine1
      ? `${address?.addressLine1}${address?.addressLine2 ? ` ${address?.addressLine2}` : ''}, ${address?.city}, ${
          address?.region
        } ${address?.postalCode}`
      : address?.fullAddress || '';
  };
