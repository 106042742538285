import { ShopTheme } from '@castiron/domain';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import Typography from '../Typography';

interface Props {
  className?: string;
  content: ReactNode;
  themeProps?: ShopTheme;
  variant?: 'blue' | 'gray' | 'green' | 'white' | 'yellow' | 'deepPurple' | 'lightPurple';
}

const useStyles = makeStyles((theme: Theme) => ({
  blue: {
    backgroundColor: theme.branding.v2.blue[100],
    color: theme.branding.v2.blue[500],
  },
  gray: {
    backgroundColor: theme.branding.v2.gray[100],
    color: theme.branding.v2.gray[800],
  },
  green: {
    backgroundColor: theme.branding.v2.green[50],
    color: theme.branding.v2.green[500],
  },
  pill: {
    borderRadius: 100,
    padding: '4px 12px',
    width: 'fit-content',
  },
  white: {
    backgroundColor: theme.branding.v2.gray[0],
    color: theme.branding.v2.gray[900],
  },
  yellow: {
    backgroundColor: theme.branding.v2.yellow[50],
    color: `${theme.branding.v2.yellow[900]} !important`,
  },
  deepPurple: {
    backgroundColor: theme.branding.v2.deepPurple[900],
    color: `${theme.branding.v2.gray[0]} !important`,
  },
  lightPurple: {
    backgroundColor: theme.branding.v2.deepPurple[100],
    color: `${theme.branding.v2.gray[800]} !important`,
  },
}));

const Pill: React.FC<Props> = (props: Props) => {
  const { className, content, themeProps, variant } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Typography
      style={
        themeProps
          ? {
              backgroundColor: theme.branding.v2[themeProps?.shopButtonColor || 'gray'][50],
              color: theme.branding.v2[themeProps?.shopButtonColor || 'gray'][900],
            }
          : {}
      }
      variant="caption"
      className={clsx([classes.pill, variant && classes[variant], className])}
    >
      {content}
    </Typography>
  );
};

export default Pill;
