import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Appointments } from '@devexpress/dx-react-scheduler';
import _ from 'lodash';
import { TicketedEvent, Transaction } from '@castiron/domain';
import IndividualListing from './IndividualListing';
import AggregatedListing from './AggregatedListing';
import AvailabilityListing from './AvailabilityListing';
import EventListing from './EventListing';

type Props = Appointments.AppointmentProps;

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  timedListingWrapper: {
    marginTop: props => (props.data.viewType === 'Month' ? '-5px' : 0),
  },
}));

const Listing: React.FC<Props> = (props: Props) => {
  const { startDate, endDate, viewType, availabilityType, onSetAvailability } = props.data;
  const transactions = props.data.transactions as Transaction[];
  const event = props.data.event as TicketedEvent;

  const classes = useStyles(props);

  if (!!event) {
    return (
      <Box className={classes.timedListingWrapper}>
        <EventListing event={event} viewType={viewType} />
      </Box>
    );
  } else if (transactions?.length === 1) {
    return (
      <Box className={classes.timedListingWrapper}>
        <IndividualListing transaction={transactions[0]} viewType={viewType} />
      </Box>
    );
  } else if (transactions?.length > 1) {
    return (
      <Box className={classes.timedListingWrapper}>
        <AggregatedListing transactions={transactions} startDate={startDate} viewType={viewType} />
      </Box>
    );
  } else {
    // it's an availability event & shown on the all day panel
    return (
      <AvailabilityListing
        viewType={viewType}
        availabilityType={availabilityType}
        startDate={startDate}
        endDate={endDate}
        onSetAvailability={onSetAvailability}
      />
    );
  }
};

export default Listing;
