import { getService } from '../firebase';

const getZendeskTokenService = getService('accounts', 'createzendesktoken');

export const popChat = () => {
  const jwtCallback = async callback => {
    const tokenResponse = await getZendeskTokenService({});
    callback(tokenResponse.token);
    window.zE('messenger', 'show');
    window.zE('messenger', 'open');
  };
  const loginCallback = error => {
    // error will be null if authentication is successful
    console.log({ error });
    window.zE('messenger', 'show');
    window.zE('messenger', 'open');
  };
  window.zE('messenger', 'loginUser', jwtCallback, loginCallback);
};
