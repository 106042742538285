import { ButtonV2, CollapsableCard, Typography } from '@castiron/components';
import { defaultTimeZone, download, useTracking } from '@castiron/utils';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Dinero from 'dinero.js';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getService } from '../../../../firebase';
import { useAppSelector } from '../../../../hooks';
import Spinner from '../../../Spinner';
import { productRepository } from '../../../../domain';
import { TicketedEvent } from '@castiron/domain';

const searchService = getService('orders', 'search');
const exportOrdersByProductService = getService('orders', 'exportordersbyproduct', { type: 'request' });
const exportAttendeeListService = getService('products', 'exportattendeelist', { type: 'request' });

type Props = {
  context: 'product' | 'presale';
  id: string;
  isEvent?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
}));

const ProductInsightTile: React.FC<Props> = (props: Props) => {
  const { context, id, isEvent } = props;

  const classes = useStyles();
  const { trackEvent } = useTracking();
  const [unitsSold, setUnitsSold] = useState<number>(0);
  const [numberOfUniqueCustomers, setNumberOfUniqueCustomers] = useState<number>(0);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const getUnitsSold = (orders) => {
    return orders?.reduce((totalQuantity, order) => {
      return totalQuantity + order?.items?.reduce((orderQuantity, product) => {
        if (context === 'product' && product?.productId !== id) return orderQuantity + 0;
        return orderQuantity + (product?.quantity || 0);
      }, 0);
    }, 0);
  };

  const search = async () => {
    if (id) {
      const results = await searchService({
        stage: 'order',
        ...(context === 'presale' ? { presaleId: id } : { productId: id }),
        transactionStatus: 'succeeded',
        transactionType: 'transaction',
      });
      setUnitsSold(getUnitsSold(results.orders));
      setNumberOfUniqueCustomers(_.uniqBy(results.orders, 'customerId').length);
      setTotalSales(results.totalSales);
    }
  };

  useEffect(() => {
    search();
  }, []);

  const downloadAttendeeList = async () => {
    setIsDownloadingReport(true);
    const timeZone = shop?.config?.timeZone || defaultTimeZone;
    const csv = await exportAttendeeListService({
      eventId: id,
      timeZone,
    });

    const ticketedEvent = (await productRepository.get(id)) as TicketedEvent;

    const filename = `${ticketedEvent?.title} Attendees ${moment
      .unix(ticketedEvent?.eventDetails?.date?.startTime)
      .tz(timeZone)
      .format('M/D/YYYY')}.csv`;

    download(filename, 'text/csv', csv);

    trackEvent('Attendees List Downloaded');

    setIsDownloadingReport(false);
  };

  const downloadReport = async () => {
    setIsDownloadingReport(true);

    const csv = await exportOrdersByProductService({
      context,
      id,
      timeZone: shop?.config?.timeZone || defaultTimeZone,
    });

    const date = moment().format('MMDDYYYY');
    const filename = `${shop.websiteUrl}-orders-by-${context}-${date}.csv`;

    download(filename, 'text/csv', csv);

    trackEvent(`Orders by ${_.capitalize(context)} Report Downloaded`);

    setIsDownloadingReport(false);
  };

  return (
    <>
      <Spinner show={isDownloadingReport} size={'fullscreen'} label="Gathering your data…" />
      <CollapsableCard defaultExpanded noScroll title={isEvent ? 'Reports' : 'Insights'}>
        <Typography variant="body1">
          Sold {unitsSold} {isEvent ? 'ticket' : 'unit'}
          {unitsSold === 1 ? '' : 's'} to {numberOfUniqueCustomers} customer{numberOfUniqueCustomers === 1 ? '' : 's'}{' '}
          for {Dinero({ amount: totalSales || 0 }).toFormat('$0.00')} in revenue.
        </Typography>
        {isEvent && (
          <ButtonV2 onClick={downloadAttendeeList} style={{ padding: 0, display: 'block' }} variant="text">
            Download Attendee List
          </ButtonV2>
        )}
        <ButtonV2 onClick={downloadReport} style={{ padding: 0 }} variant="text">
          Download{isEvent ? ' Event ' : ' '}Report
        </ButtonV2>
      </CollapsableCard>
    </>
  );
};

export default ProductInsightTile;
