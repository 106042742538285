import * as yup from 'yup';
import { Address } from '../address';
import { FulfillmentType } from '../shop';
import { selectedInputFieldValueSchema } from '../inputfield/model';
import { eventDetailsSchema } from '../product/model';
import { fulfillmentOptionSchema } from '../shop/model';
import { couponSchema } from '../coupon/model';
import { assetSchema } from '../asset/model';
import { phoneRegExp } from '../base/model';

export interface RequestedFulfillment {
  type: FulfillmentType;
  date: number;
  address?: Address;
}

export const requestedFulfillmentSchema = yup
  .object({
    type: yup
      .string()
      .oneOf(['pickup', 'delivery', 'shipping', 'custom'])
      .required('Fulfillment Method is required'),
    date: yup
      .number()
      .moreThan(0, 'Fulfillment Date is required')
      .required('Fulfillment Date is required'),
    /* I went flat to simplify formik interactions
     * only requiring full address as that will be the trigger on the autocomplete
     * I really wanted to use a nested address component defined alongside the address interface,
     * but an optional address with required fields is just not doable with formik.  /sigh
     */
    fullAddress: yup.string().when('type', {
      is: value => value === 'delivery' || value === 'shipping',
      then: schema => schema.required('Address is required'),
    }),
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    city: yup.string(),
    region: yup.string(),
    regionName: yup.string().nullable(),
    postalCode: yup.string(),
    country: yup.string(),
  })
  .default(undefined);

export const subLineItemSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    title: yup.string().required(),
    price: yup.number().required(),
    quantity: yup.number().required(),
    total: yup.number().nullable(),
    notes: yup.string().nullable(),
  })
  .default(undefined);

export const eventAttendeeSchema = yup
  .object()
  .shape({
    name: yup.string().required('Please enter attendee full name'),
    email: yup.string().when('mobileNumber', ([mobileNumber], schema) => {
      return mobileNumber?.length === 0
        ? schema.email('Must be a valid email').required('Please enter attendee email')
        : schema.email('Must be a valid email').nullable();
    }),
    mobileNumber: yup
      .string()
      .matches(phoneRegExp, 'Must be a valid 10-digit phone number format')
      .nullable(),
    customerId: yup.string().nullable(),
    smsNotificationOptIn: yup.boolean(),
    subscribed: yup.boolean(),
    restrictions: yup
      .object()
      .shape({
        allergens: yup
          .array()
          .of(yup.string())
          .default(undefined)
          .nullable(),
        dietary: yup
          .array()
          .of(yup.string())
          .default(undefined)
          .nullable(),
      })
      .default(undefined)
      .nullable(),
    selections: yup
      .array()
      .of(selectedInputFieldValueSchema)
      .default(undefined)
      .nullable(),
  })
  .default(undefined);

export const lineItemSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    description: yup.string().nullable(),
    quantity: yup.number().required(),
    title: yup.string().required(),
    type: yup
      .string()
      .oneOf(['standard', 'custom', 'invoice', 'event'])
      .required(),
    attendees: yup.array().of(eventAttendeeSchema),
    eventDetails: eventDetailsSchema,
    category: yup
      .object({
        id: yup.string().required(),
        name: yup.string().required(),
      })
      .nullable()
      .default(undefined),
    notes: yup.string().nullable(),
    policies: yup.string().nullable(),
    price: yup.number().nullable(),
    selections: yup.array().of(selectedInputFieldValueSchema),
    subLineItems: yup
      .array()
      .of(subLineItemSchema)
      .nullable(),
    subLineItemTotal: yup.number().nullable(),
    subtotal: yup.number().nullable(),
    total: yup.number().nullable(),
    communityPackageId: yup.string().nullable(),
  })
  .default(undefined);

export const orderPaymentSchema = yup.object().shape({
  amount: yup.number().required(),
  coupon: couponSchema.nullable(),
  processor: yup
    .object({
      name: yup.string().oneOf(['stripe', 'venmo', 'paypal']),
      transactionIdentifier: yup.string(),
    })
    .nullable()
    .default(undefined),
  paidAt: yup.number().required(),
  type: yup
    .string()
    .oneOf(['credit-card', 'cash', 'check', 'venmo', 'paypal', 'square', 'other'])
    .required(),
  status: yup
    .string()
    .oneOf(['requested', 'pending', 'received', 'failed'])
    .required(),
});

export const orderSchema = yup
  .object()
  .shape({
    orderNumber: yup.string().nullable(),
    type: yup
      .string()
      .oneOf(['standard', 'custom', 'invoice', 'event'])
      .required(),
    stage: yup
      .string()
      .oneOf(['quote', 'order'])
      .required(),
    initiator: yup
      .string()
      .oneOf(['owner', 'customer'])
      .nullable(),
    items: yup
      .array()
      .of(lineItemSchema)
      .required(),
    requestedFulfillment: requestedFulfillmentSchema,
    fulfillmentOption: fulfillmentOptionSchema,
    orderTotal: yup.number().nullable(),
    origination: yup.string().nullable(),
    paymentSentDuePeriod: yup
      .string()
      .oneOf(['24hours', '7days', 'none'])
      .nullable(),
    paymentSentDueDate: yup.number().nullable(),
    payments: yup
      .array()
      .of(orderPaymentSchema)
      .nullable(),
    notes: yup.object({
      text: yup.string().nullable(),
      images: yup
        .array()
        .of(assetSchema)
        .nullable(),
    }),
    presaleId: yup.string().nullable(),
    fromPage: yup.string().nullable(),
  })
  .default(undefined);
