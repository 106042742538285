import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Grid, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import { Button, MultipleSelect, SearchInput } from '@castiron/components';
import { Transaction, backendStateToFrontendState } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import useVariant from '@castiron/components/src/Experiment/useVariant';
import { transactionRepository } from '../../../domain';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCustomersAction } from '../../../store/reducers/customers';
import DesktopContent from './DesktopContent';
import MobileContent from './MobileContent';
import { prepareQuoteSegmentData } from '../QuoteUtils';
import { LayoutPageProps } from '../../Layout';
import AvatarMenu from '../../Menus/AvatarMenu';
import Spinner from '../../Spinner';
import SubscriptionBanner from '../../SubscriptionBanner';
import { EmptyQuotesIcon } from '@castiron/components/src/Icons';
import EmptyPage, { TipsForYou } from '../../EmptyPage';
import { openModal } from '../../../store/reducers/modalConductor';
import { SEEN_SMS_OPTIN } from '../../SMSNotificationModal';

const createCustomOrderService = getService('orders', 'createcustomorderv2');
const orderSearchService = getService('orders', 'search');

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    paddingLeft: '8px',
    '& button': {
      padding: '8px',
    },
  },
  ctaContainer: {
    paddingBottom: '24px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
  },
  icon: {
    height: 104,
    width: 98,
  },
  separator: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '8px',
      /* was #EEEEEE, went with closest I could find */
      borderBottom: `1px solid ${theme.branding.gray[300]}`,
    },
  },
  subscriptionBanner: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
      marginBottom: 0,
    },
    marginTop: 0,
    marginBottom: 16,
  },
  viewContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
  },
}));

const QuotesView: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { trackEvent } = useTracking();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>(undefined);
  const [totalQuotes, setTotalQuotes] = useState<number>(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isQuotesEmpty, setIsQuotesEmpty] = useState(false);
  const [filters, setFilters] = useState(['Draft', 'New', 'Pending', 'Partially Paid', 'Paid', 'Canceled']);
  const [quotesFromES, setQuotesFromES] = useState<Transaction[]>([]);
  const [subOrdersFromES, setSubOrdersFromES] = useState<Transaction[]>([]);
  const [statusFilteredQuotesFromES, setStatusFilteredQuotesFromES] = useState<Transaction[]>([]);
  const [moreQuotesFromES, setMoreQuotesFromES] = useState(true);

  const filterOptions = ['Draft', 'New', 'Pending', 'Partially Paid', 'Paid', 'Canceled', 'Archived'];

  const { shop, account, isCustomerLoading, modal } = useAppSelector(state => ({
    shop: state.shops.shop,
    account: state.shops.account,
    isCustomerLoading: state.customers.loading,
    modal: state.modal,
  }));

  const getTransactions = async shopId => {
    setShowSpinner(true);
    const transactions = await transactionRepository.getCustomTransactions(shopId, 100);
    const validTransactios = transactions.filter(t => t.type === 'transaction');
    setTransactions(validTransactios);
    setShowSpinner(false);
  };

  const searchForQuotes = async (reset?: boolean, searchText?: string) => {
    const addUntilBatchComplete = async (currentQuotes: Transaction[], leftToAdd: number = 20) => {
      const searchResponse = await orderSearchService({
        start: currentQuotes.length,
        size: 20,
        type: 'custom',
        search: {
          text: searchText,
        },
        transactionType: 'transaction',
      });
      setTotalQuotes(searchResponse.total);
      const newFoundOrders = searchResponse.orders;
      /* hack to avoid rebuilding everything off of order search results right now */
      const orderIds: string[] = newFoundOrders.map(o => o.id);
      const transactions = await transactionRepository.getAll(orderIds);
      /* transactions aren't sorted, resort via orderIds */
      const newTransactions: Transaction[] = orderIds.map(oid => transactions.find(tx => tx.id === oid));
      /* I hate doing this post filter, but we don't have quote specific statuses in ES yet
       * TODO: get them in there
       */
      const lowerCaseFilters = filters.map(status => status.toLowerCase());
      const txFilterPredicate = tx =>
        lowerCaseFilters.includes(backendStateToFrontendState(tx, 'quote')?.toLowerCase());
      const newTransactionsFilteredCount = newTransactions.filter(txFilterPredicate).length;
      const newLeftToAdd = leftToAdd - newTransactionsFilteredCount;
      const newQuotesFromES = currentQuotes.concat(newTransactions);
      const newStatusFilteredQuotesFromES = newQuotesFromES.filter(txFilterPredicate);
      if (newQuotesFromES.length >= searchResponse.total) {
        setMoreQuotesFromES(false);
        setQuotesFromES(newQuotesFromES);
        setStatusFilteredQuotesFromES(newStatusFilteredQuotesFromES);
      } else if (leftToAdd <= 0) {
        setMoreQuotesFromES(true);
        setQuotesFromES(newQuotesFromES);
        setStatusFilteredQuotesFromES(newStatusFilteredQuotesFromES);
      } else {
        await addUntilBatchComplete(newQuotesFromES, newLeftToAdd);
      }
    };
    addUntilBatchComplete(reset ? [] : quotesFromES);
  };

  const searchForQuotesDesktop = async (page: number, searchText?: string) => {
    const searchValue = searchText == undefined && search ? search : searchText;
    const reset = searchValue != search;
    setSearch(searchValue);
    setPage(page);
    const addUntilBatchComplete = async (
      currentQuotes: Transaction[],
      currentSubTransactions: Transaction[],
      leftToAdd: number = 20,
    ) => {
      const searchResponse = await orderSearchService({
        start: currentQuotes.length,
        size: 20,
        type: 'custom',
        search: {
          text: searchValue,
        },
        transactionType: 'transaction',
      });
      const newFoundOrders = searchResponse.orders;
      setTotalQuotes(searchResponse.total);
      /* hack to avoid rebuilding everything off of order search results right now */
      const orderIds: string[] = newFoundOrders.map(o => o.id);
      const transactions = await transactionRepository.getAll(orderIds);
      const subTransactions = await transactionRepository.findSubTransactionsByParentIds(orderIds);
      /* transactions aren't sorted, resort via orderIds */
      const newTransactions: Transaction[] = orderIds.map(oid => transactions.find(tx => tx.id === oid));
      /* I hate doing this post filter, but we don't have quote specific statuses in ES yet
       * TODO: get them in there
       */
      const lowerCaseFilters = filters.map(status => status.toLowerCase());
      const txFilterPredicate = tx =>
        lowerCaseFilters.includes(backendStateToFrontendState(tx, 'quote')?.toLowerCase());
      const newTransactionsFilteredCount = newTransactions.filter(txFilterPredicate).length;
      const newLeftToAdd = leftToAdd - newTransactionsFilteredCount;
      const newQuotesFromES = currentQuotes.concat(newTransactions);
      const newStatusFilteredQuotesFromES = newQuotesFromES.filter(txFilterPredicate);
      const newSubTransactionList = currentSubTransactions.concat(subTransactions);
      if (newQuotesFromES.length >= searchResponse.total) {
        setMoreQuotesFromES(false);
        await setQuotesFromES(newQuotesFromES);
        await setSubOrdersFromES(newSubTransactionList);
        setStatusFilteredQuotesFromES(newStatusFilteredQuotesFromES);
      } else if (leftToAdd <= 0) {
        setMoreQuotesFromES(true);
        await setQuotesFromES(newQuotesFromES);
        await setSubOrdersFromES(newSubTransactionList);
        setStatusFilteredQuotesFromES(newStatusFilteredQuotesFromES);
      } else {
        await addUntilBatchComplete(newQuotesFromES, newSubTransactionList, newLeftToAdd);
      }
    };
    addUntilBatchComplete(page == 0 || reset ? [] : quotesFromES, subOrdersFromES);
  };

  const handleSearchTextChange = async event => {
    setPage(0);
    isMobile ? await searchForQuotes(true, event.target.value) : await searchForQuotesDesktop(0, event.target.value);
  };

  useEffect(() => {
    if (account && !account.config?.messagingPreferences?.sms) {
      const seemOptInModal = localStorage.getItem(SEEN_SMS_OPTIN) === 'true';
      if (!seemOptInModal) {
        dispatch(
          openModal({
            modalType: 'SMS_NOTIFICATION_OPTIN_MODAL',
            modalProps: {
              show: true,
            },
          }),
        );
      }
    }
  }, [account]);

  const filteredQuotes =
    transactions?.filter(tx => {
      const isCustom = tx.order.type === 'custom';
      const frontEndState = backendStateToFrontendState(tx, 'quote')?.toLowerCase();
      const isInFilteredStatus = filters.map(status => status.toLowerCase()).includes(frontEndState);
      return isCustom && isInFilteredStatus;
    }) || [];

  const onFilterChange = (event, value) => {
    setFilters(value);
    trackEvent('Filter Quote Clicked', { Filters: value });
  };

  const createNewQuote = async () => {
    setShowSpinner(true);
    const body = {
      id: shop.id,
      status: 'proposed',
      origination: 'artisan',
    };
    try {
      const createCustomOrderResponse = await createCustomOrderService(body);
      console.debug('Create custom product order response: ', createCustomOrderResponse);
      const { id } = createCustomOrderResponse;
      setShowSpinner(false);
      trackEvent('Create Quote Clicked', {
        ...prepareQuoteSegmentData(createCustomOrderResponse),
      });
      history.push('/quotes/edit/' + id);
    } catch (e) {
      console.error('Error creating custom product order: ', e);
      trackEvent('Create Quote Failed', { errorMsg: e });
      setShowSpinner(false);
    }
  };

  const newQuoteButton = (
    <Button variant="contained" fullWidth onClick={() => createNewQuote()}>
      New Invoice
    </Button>
  );

  const OverrideTooltip = withStyles({
    tooltip: {
      padding: 16,
      background: theme.branding.gray[800],
      borderRadius: 12,
      fontSize: 14,
      color: theme.branding.ghostWhite,
      width: 212,
    },
    arrow: {
      color: theme.branding.gray[800],
    },
  })(Tooltip);

  const subscriptionBannerExperiment = useVariant('Subscription Upgrade Banner Test');
  const isVariant = subscriptionBannerExperiment?.name === 'Quotes Page';

  useEffect(() => {
    getTransactions(shop.id);
    const filterString = urlParams.get('filter');
    if (filterString) {
      const filterChoices = filterString.split(',');
      const toFilter = filterChoices.filter(choice => filterOptions.includes(choice));
      setFilters(toFilter);
    }
    setPageTitle('Quotes & Invoices');
    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([<AvatarMenu />]);
    } else {
      setHeaderCTAs([newQuoteButton]);
    }
  }, [isMobile, shop]);

  useEffect(() => {
    if (isMobile) {
      setFooterCTAs([newQuoteButton]);
    } else {
      setFooterCTAs([]);
    }
  }, [isMobile, shop]);

  /* this allows a page refresh to work correctly */
  useEffect(() => {
    if (shop && shop.id) {
      getTransactions(shop.id);
      dispatch(getCustomersAction(shop.id));
    }
  }, [dispatch, shop]);

  useEffect(() => {
    if (transactions) {
      const customTransactions = transactions.filter(tx => tx?.order?.type === 'custom');
      setIsQuotesEmpty(customTransactions.length == 0);
    }
  }, [shop, transactions]);

  useEffect(() => {
    setPage(0);
    isMobile ? searchForQuotes(true) : searchForQuotesDesktop(0);
  }, [filters]);

  const tipsForYou: TipsForYou[] = [
    {
      icon: 'cookie',
      title: 'Create a Custom Order Form',
      description: 'Quotes are sent when order forms are submitted.',
      ctaAction: () => window.open('/order-forms/add/custom', '_self').focus(),
    },
    {
      icon: 'article',
      title: 'Read Our Quote Guide',
      description: 'Learn how you can start receiving quotes in Castiron!',
      ctaAction: () =>
        window
          .open('https://castiron.helpscoutdocs.com/article/97-the-custom-order-experience-in-castiron', '_blank')
          .focus(),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{`Quotes | ${shop ? shop.businessName : ''}`}</title>
      </Helmet>
      <Grid container direction="column" className={classes.viewContainer}>
        {modal.modalType !== 'CHANGE_PLANS_MODAL' && <Spinner show={isCustomerLoading || showSpinner} />}
        {isVariant && (
          <Grid className={classes.subscriptionBanner}>
            <SubscriptionBanner />
          </Grid>
        )}
        {isQuotesEmpty ? (
          <EmptyPage
            icon={<EmptyQuotesIcon className={classes.icon} />}
            title="No Quotes to Display"
            description="Get your first quote in Castiron in no time by doing one,
              or both, of the following:"
            tipsForYou={tipsForYou}
            page="Quotes"
          />
        ) : (
          <>
            <Grid item className={`${classes.separator} ${classes.ctaContainer}`}>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={8}>
                  <SearchInput onChange={handleSearchTextChange} placeholder="Search quotes & invoices..." />
                </Grid>
                <Grid item xs={12} sm={4} style={{ margin: '8px 0px 4px 0px' }}>
                  <MultipleSelect value={filters} onChange={onFilterChange} options={filterOptions} />
                </Grid>
              </Grid>
            </Grid>
            {isMobile ? (
              <MobileContent
                quotes={statusFilteredQuotesFromES}
                subTransactions={subOrdersFromES}
                retrieveMoreQuotes={searchForQuotes}
                moreQuotesLeft={moreQuotesFromES}
              />
            ) : (
              <DesktopContent
                quotes={statusFilteredQuotesFromES}
                subTransactions={subOrdersFromES}
                total={totalQuotes}
                retrieveQuotes={searchForQuotesDesktop}
                page={page}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default QuotesView;
