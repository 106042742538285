import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import { Ellipse, Typography } from '@castiron/components';
import { Transaction, transactionToEventModel } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { CalendarViewType } from '..';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';

interface Props {
  transaction: Transaction;
  viewType: CalendarViewType;
  context?: 'moreMenu' | 'directClick';
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  individualListing: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.branding.v2.blue[100],
    },
  },
  listingContent: {
    /* not sure why, but adding webclamp seems to push everything vertically */
    marginTop: '5px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': props => (props.viewType === 'Month' ? 1 : 2),
    '-webkit-box-orient': 'vertical',
  },
}));

const IndividualListing: React.FC<Props> = (props: Props) => {
  const { transaction, viewType, context = 'directClick' } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();

  /* I REALLY wish I had pattern matching for these two functions */
  const quoteEllipseColor = (tx: Transaction) => {
    switch (tx.frontendState('quote')) {
      case 'draft':
        return 'yellow';
      case 'new':
        return 'blue';
      case 'pending':
      case 'partially paid':
        return 'orange';
      default:
        return 'red';
    }
  };
  const orderEllipseColor = (tx: Transaction) => {
    switch (tx.frontendState('order')) {
      case 'open':
        return 'yellow';
      case 'completed':
        return 'green';
      case 'fulfilled':
        return 'blue';
      default:
        return 'red';
    }
  };

  let orderType;
  let ellipseColor;
  if (transaction.order.type === 'custom') {
    if (['paid', 'partially paid'].includes(transaction.frontendState('quote'))) {
      orderType = 'custom';
      ellipseColor = orderEllipseColor(transaction);
    } else {
      orderType = 'quote';
      ellipseColor = quoteEllipseColor(transaction);
    }
  } else {
    orderType = 'order';
    ellipseColor = orderEllipseColor(transaction);
  }

  const isQuote = orderType === 'quote';

  const customerName = `${transaction.customerObj?.firstName || ''} ${transaction.customerObj?.lastName || ''}`.trim();
  const orderNumber = `#${transaction.order?.orderNumber}`;
  const displayText = `${_.capitalize(orderType)} ${orderNumber}${customerName && `, ${customerName}`}`;

  const handleClick = () => {
    trackEvent('Calendar Item Clicked', {
      type: isQuote ? 'quote' : 'order',
      context: context,
      id: transaction.id,
      transaction: transactionToEventModel(transaction),
    });
    dispatch(
      openModal({
        modalType: 'INDIVIDUAL_LISTING_MODAL',
        modalProps: {
          transaction,
        },
      }),
    );
  };

  return (
    <Box className={`individualListing ${classes.individualListing}`} onClick={handleClick}>
      <Grid container wrap="nowrap">
        <Grid item xs={viewType === 'Day' ? undefined : 2}>
          <Ellipse color={ellipseColor} diameter={8} />
        </Grid>
        <Grid item xs={viewType === 'Day' ? undefined : 10}>
          <Typography variant="caption" className={`listingContent ${classes.listingContent}`}>
            {displayText}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IndividualListing;
