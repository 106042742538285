import React, { ReactElement, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Box, Grid, IconButton, makeStyles, MenuItem, Tab, Tabs, Theme } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Button, Forms, Input, Typography } from '@castiron/components';
import { EventAttendee } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { getService } from '../../firebase';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal, openModal } from '../../store/reducers/modalConductor';
import ModalWrapper from '../RootModal/ModalWrapper';
import Spinner from '../Spinner';
import AdminForm from '../AdminForm';

const sendEmailToCustomersService = getService('messaging', 'sendemailtocustomersv2');
const sendEmailToEventAttendeesService = getService('messaging', 'sendemailtoeventattendees');

export type Props = {
  show: boolean;
  email?: string;
  customerId?: string;
  eventAttendee?: EventAttendee;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 14,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      padding: '31px 48px 37px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px',
    },
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    marginBottom: 17,
  },
  tabPanel: {
    padding: 0,
  },
  row: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    justifyContent: 'flex-start',
  },
  rowItem: {
    flexGrow: 1,
  },
  inputRow: {
    [theme.breakpoints.up('md')]: {
      gap: 20,
      alignItems: 'flex-end',
    },
  },
  checkbox: {
    flexGrow: 0.5,
    [theme.breakpoints.up('md')]: {
      marginBottom: 24,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
    },
  },
  checkboxEmailContainer: {
    position: 'relative',
    flexGrow: 0.5,
  },
  checkboxInput: {
    marginTop: 12,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 20,
    marginTop: 17,
  },
  labelText: {
    fontWeight: 600,
    fontSize: 14,
  },
  secondaryLabel: {
    fontWeight: 400,
    color: theme.branding.v2.gray[600],
  },
  inputContainer: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 0.5,
    },
  },
  selectContainer: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
}));

const contactTemplates = ['Blank template'];

const ContactModal: React.FC<Props> = (props: Props) => {
  const { show, email, customerId, eventAttendee } = props;
  const [tab, setTab] = useState('email');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { shop, me } = useAppSelector(state => ({
    shop: state.shops.shop,
    me: state.users.me,
  }));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const onTabChange = (event, selectedTab: 'email' | 'sms'): void => {
    setTab(selectedTab);
  };

  const contactSchema = yup.object().shape({
    email: yup.string().email(),
    template: yup.string().required(),
    subject: yup.string().required(),
  });

  const onSubmit = async values => {
    setIsSubmitting(true);

    try {
      console.debug('Contact Modal Submitted', values);
      const { subject, message, sendToMe } = values;

      if (!!eventAttendee) {
        await Promise.resolve(
          sendEmailToEventAttendeesService({
            eventAttendees: [eventAttendee],
            subject,
            message,
          }),
        );

        if (!!values.sendToMe) {
          //send a separate version of the email triggering the isTest protocol to send to artisan, since event attendees are handled as a list
          await Promise.resolve(
            sendEmailToEventAttendeesService({
              eventAttendees: [eventAttendee],
              subject,
              message,
              sendToMe,
            }),
          );
        }

        trackEvent('Owner Sent Event Attendees Email', {
          message,
        });
      } else {
        const body = {
          customerId,
          subject,
          message,
          sendToMe,
        };
        await sendEmailToCustomersService(body);
        trackEvent('Owner Sent Customer Email', {
          message: body,
        });
      }

      handleClose();
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: <>Message was sent!</>,
          },
        }),
      );
    } catch (err) {
      console.error('Error: Contact Modal On Submit', err);
    }

    setIsSubmitting(false);
  };

  return (
    <ModalWrapper size="md" show={show}>
      <Grid style={{ position: 'relative' }}>
        <Spinner show={isSubmitting} size={'fullscreen'} />
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
        <Formik
          initialValues={{
            email: eventAttendee?.email || email || '',
            template: 'Blank template',
            subject: '',
            sendToMe: true,
          }}
          validationSchema={contactSchema}
          onSubmit={onSubmit}
        >
          {(): ReactElement => (
            <AdminForm>
              <Box className={classes.container}>
                <Typography variant="h2" className={classes.title}>
                  Message customer
                </Typography>
                <TabContext value={tab}>
                  <Tabs
                    variant="fullWidth"
                    indicatorColor="primary"
                    onChange={onTabChange}
                    value={tab}
                    aria-label="add-customer-mode"
                  >
                    <Tab role="button" tabIndex={0} id="email-tab" aria-pressed={false} value="email" label="Email" />
                    {/* <Tab role="button" tabIndex={0} id="sms-tab" aria-pressed={false} value="sms" label="SMS" /> */}
                  </Tabs>
                  <TabPanel className={classes.tabPanel} value="email">
                    <Box className={`${classes.row} ${classes.inputRow}`}>
                      <Box className={classes.inputContainer}>
                        <Input name="email" placeholder="johndoe@email.com" label="Email address" disabled={true} />
                      </Box>
                      <Box className={classes.selectContainer}>
                        <Input fullWidth name="template" select label="Email Template">
                          {contactTemplates.map(template => (
                            <MenuItem value={template} key={template}>
                              {template}
                            </MenuItem>
                          ))}
                        </Input>
                      </Box>
                    </Box>
                    <Box className={classes.checkboxEmailContainer}>
                      <Input fullWidth name="subject" placeholder="Email subject" label="Subject" />
                    </Box>
                    <Input name="message" placeholder="Customer message here" multiline rows={12} label="Message" />
                    <Forms.Checkbox
                      name="sendToMe"
                      label={
                        <Typography className={classes.labelText}>
                          Send me a copy <span className={classes.secondaryLabel}>{shop.email}</span>
                        </Typography>
                      }
                    />
                  </TabPanel>
                </TabContext>
                <Box className={classes.footer}>
                  <Button type="submit" variant="contained">{`Send ${tab}`}</Button>
                  <Button onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                </Box>
              </Box>
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </ModalWrapper>
  );
};

export default ContactModal;
