import React, { ReactElement, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Box, Grid, IconButton, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { Button, Chip, Forms, Input } from '@castiron/components';
import { SelectedCustomerProps } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { getService } from '../../firebase';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal, openModal } from '../../store/reducers/modalConductor';
import AdminForm from '../AdminForm';
import RichTextInput from '../RichTextEditor';
import ModalWrapper from '../RootModal/ModalWrapper';
import Spinner from '../Spinner';

const sendEmailToCustomersService = getService('messaging', 'sendemailtocustomersv2');
const sendEmailToEventAttendeesService = getService('messaging', 'sendemailtoeventattendees');

export type Props = {
  show: boolean;
  email?: string;
  recipientList?: SelectedCustomerProps[] | { email: string; name: string; mobileNumber?: string; id?: string }[];
  sendToEventAttendees?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    flexGrow: 0.5,
    [theme.breakpoints.up('md')]: {
      marginBottom: 24,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
    },
  },
  checkboxEmailContainer: {
    position: 'relative',
    flexGrow: 0.5,
  },
  checkboxInput: {
    marginTop: 12,
  },
  chip: {
    cursor: 'pointer',
    height: 25,
    margin: '8px 8px 0 0',
    padding: '0 6px 0 18px',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 14,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      padding: '31px 48px 37px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px',
    },
  },
  editor: {
    '& .ql-editor p': {
      marginBottom: 13,
    },
  },
  deleteCustomerButton: {
    '& >span>svg': {
      height: '.65em',
      width: '.65em',
    },
  },
  error: {
    color: theme.branding.v2.red[500],
    fontSize: 12,
    marginBottom: 24,
    marginTop: -8,
  },
  errorBorder: {
    '& .quill': {
      border: `1px solid ${theme.branding.v2.red[500]}`,
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 20,
    marginTop: 17,
  },
  formBox: {
    marginTop: 17,
  },
  inputContainer: {
    alignItems: 'center',
    border: `1px solid ${theme.branding.v2.gray[300]}`,
    borderRadius: 4,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 7,
  },
  input: {
    '& .MuiTextField-root': {
      margin: 0,
      padding: 0,
    },
  },
  labelText: {
    fontWeight: 600,
    fontSize: 14,
  },
  secondaryLabel: {
    fontWeight: 400,
    color: theme.branding.v2.gray[600],
  },
  selectContainer: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 17,
  },
}));

const BulkContactModal: React.FC<Props> = (props: Props) => {
  const { recipientList, show, email, sendToEventAttendees } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackEvent } = useTracking();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sendList, setSendList] = useState(recipientList || []);
  const [customerSearchList, setCustomerSearchList] = useState<SelectedCustomerProps[]>([]);
  const [customerToAdd, setCustomerToAdd] = useState(null);

  const { customers, shop, me } = useAppSelector(state => ({
    customers: state.customers,
    shop: state.shops.shop,
    me: state.users.me,
  }));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (customerToAdd) {
      setCustomerToAdd(null);
    }
  }, [customerToAdd]);

  useEffect(() => {
    const trimmedCustomers = customers.customers.map(customer => ({
      email: customer.email,
      id: customer.id,
      name: customer.firstName + ' ' + customer.lastName,
    }));
    // remove customers in sendList from customerSearchList
    const filteredCustomers = trimmedCustomers.filter(
      customer => !sendList.find(sendCustomer => sendCustomer?.id === customer.id),
    );

    setCustomerSearchList(filteredCustomers);
  }, [customers]);

  const handleCustomer = (customer): void => {
    // check if the customer is in the sendList, if not add it, if so remove it
    const customerIndex = sendList.findIndex(c => c?.id === customer.id);
    if (customerIndex === -1) {
      setSendList([...sendList, customer]);
    } else {
      setSendList(sendList.filter(c => c?.id !== customer.id));
    }

    // check if the customer is in the customerSearchList, if not add it, if so remove it so that it can't be added twice
    const fullCustomerIndex = customerSearchList.findIndex(c => c?.id === customer.id);
    if (fullCustomerIndex === -1) {
      setCustomerSearchList([...customerSearchList, customer]);
    } else {
      setCustomerSearchList(customerSearchList.filter(c => c?.id !== customer.id));
    }
  };

  const onSubmit = async values => {
    setIsSubmitting(true);

    try {
      console.debug('Bulk Contact Modal Submitted', values);
      if (sendToEventAttendees) {
        await Promise.resolve(
          sendEmailToEventAttendeesService({
            eventAttendees: recipientList,
            subject: values.subject,
            message: values.message,
          }),
        );

        if (!!values.sendToMe) {
          //send a separate version of the email triggering the isTest protocol to send to artisan, since event attendees are handled as a list
          await Promise.resolve(
            sendEmailToEventAttendeesService({
              eventAttendees: recipientList,
              subject: values.subject,
              message: values.message,
              sendToMe: true,
            }),
          );
        }

        trackEvent('Owner Sent Event Attendees Email', {
          message: values.message,
        });
      } else {
        // bundle the promises and try to send an email for each customer in the sendList
        const sendEmailPromises = sendList.map(customer => {
          return sendEmailToCustomersService({
            customerId: customer.id,
            subject: values.subject,
            message: values.message,
            sendToMe: values.sendToMe,
          });
        });
        await Promise.all(sendEmailPromises);

        trackEvent('Owner Sent Customer Email', {
          message: values.message,
        });
      }

      handleClose();
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: <>Message was sent!</>,
          },
        }),
      );
    } catch (err) {
      console.error('Error: Bulk Contact Modal On Submit', err);
    }

    setIsSubmitting(false);
  };

  const contactSchema = yup.object().shape({
    message: yup
      .string()
      .required('A message is required')
      .max(2500, 'Message must be less than 2500 characters'),
    subject: yup.string().required('A subject is required'),
  });

  const initialValues = {
    email: email || '',
    template: 'Blank template',
    subject: '',
    sendToMe: true,
  };

  return (
    <ModalWrapper size="md" show={show}>
      <Spinner show={isSubmitting} size={'fullscreen'} />
      <Grid container direction="row">
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
        <Formik initialValues={initialValues} validationSchema={contactSchema} onSubmit={onSubmit}>
          {({ isSubmitting, errors, touched }): ReactElement => (
            <AdminForm>
              <Box className={classes.container}>
                <Typography variant="h2" className={classes.title}>
                  Email customers about their orders
                </Typography>
                <Typography variant="subtitle2">
                  Messages will send individually to any selected customers from your email address. Your message must
                  be related to order status or order updates. Any marketing related messages can only be sent to
                  opted-in subscribers.
                </Typography>
                <Box className={classes.formBox}>
                  <Typography id="sendToLabel" variant="subtitle2">
                    Send to:
                  </Typography>
                  <Box className={classes.inputContainer}>
                    {sendList.map(customer => (
                      <Chip className={classes.chip} colorScheme="info" key={customer.id} title={customer.email}>
                        {customer.name}
                        <IconButton className={classes.deleteCustomerButton} onClick={() => handleCustomer(customer)}>
                          <Close />
                        </IconButton>
                      </Chip>
                    ))}
                    <Box className={classes.input}>
                      <Autocomplete
                        id="select-customer"
                        style={{
                          marginLeft: isMobile ? 0 : 24,
                          width: isMobile ? 265 : 350,
                        }}
                        options={customerSearchList}
                        autoHighlight
                        getOptionLabel={option => `${option.name} (${option.email})`}
                        renderInput={params => <TextField {...params} label="Choose a customer" />}
                        value={customerToAdd}
                        onChange={(event, custValue) => {
                          setCustomerToAdd(custValue);
                          handleCustomer(custValue);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.checkboxEmailContainer}>
                    <Input
                      error={touched.subject && errors.subject}
                      fullWidth
                      name="subject"
                      placeholder="Email subject"
                      label="Subject"
                    />
                  </Box>
                  <RichTextInput
                    label="Description"
                    name="message"
                    placeholder="Customer message here"
                    height={250}
                    className={touched.message && errors.message ? classes.errorBorder : ''}
                    editorClassName={classes.editor}
                  />
                  {touched.message && errors.message && (
                    <Typography className={classes.error}>{touched.message && errors.message}</Typography>
                  )}
                  <Forms.Checkbox
                    name="sendToMe"
                    label={
                      <Typography className={classes.labelText}>
                        Send me a copy <span className={classes.secondaryLabel}>{shop.email}</span>
                      </Typography>
                    }
                  />
                </Box>
                <Box className={classes.footer}>
                  <Button type="submit" variant="contained">
                    Send email
                  </Button>
                  <Button onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                </Box>
              </Box>
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </ModalWrapper>
  );
};

export default BulkContactModal;
