import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { ButtonV2, Pill, Typography } from '@castiron/components';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import CategoryContainer from '../Templates/CategoryContainer';
import { TemplateDisplay, getActiveCategories } from '../Templates/templatesUtils';
import { smsCategoriesMap } from './smsTemplates';
import MarketingStats from '../MarketingStats';

interface Props {
  activeShopEventTags: string[];
  smsTemplates: TemplateDisplay[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    gap: '40px',
  },
  templatesContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gap: '16px',
    },
  },
  viewAllButton: {
    padding: 0,
    color: theme.branding.v2.blue[500],
    width: 'fit-content',
  },
}));

const Sms: React.FC<Props> = (props: Props) => {
  const { activeShopEventTags, smsTemplates } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();

  const [templateCategories, setTemplateCategories] = useState([]);

  useEffect(() => {
    const cats = getActiveCategories(smsTemplates, smsCategoriesMap);
    const limitedCats = _.slice(cats, 0, isMobile ? 3 : 4);

    setTemplateCategories(limitedCats);
  }, [isMobile, smsTemplates]);

  const handleOpenTemplatesModal = (scrollTo?: string) => {
    dispatch(
      openModal({
        modalType: 'MESSAGING_TEMPLATES_DISPLAY_MODAL',
        modalProps: {
          show: true,
          context: 'sms',
          templates: smsTemplates,
          scrollTo,
        },
      }),
    );
  };

  return (
    <Grid container className={classes.container}>
      <Grid container item direction="column" style={{ gap: '24px' }}>
        <Typography variant="body1">
          SMS marketing is a powerful tool to connect with your audience and drive actionable results.
        </Typography>
        <Grid container item direction="column" style={{ gap: '16px' }}>
          <Grid container item justify="space-between">
            <Typography variant="subtitle1">SMS Templates</Typography>
            {!isMobile && (
              <ButtonV2
                variant="text"
                disableRipple
                onClick={() => {
                  handleOpenTemplatesModal();
                }}
                className={classes.viewAllButton}
              >
                View all
              </ButtonV2>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.templatesContainer}>
          {templateCategories.map((category, index) => (
            <React.Fragment key={`sms-${index}`}>
              <CategoryContainer
                {...category}
                index={index}
                activeShopEventTags={activeShopEventTags}
                handleOpenTemplatesModal={handleOpenTemplatesModal}
              />
            </React.Fragment>
          ))}
        </Grid>
        {isMobile && (
          <ButtonV2
            variant="text"
            disableRipple
            onClick={() => {
              handleOpenTemplatesModal();
            }}
            className={classes.viewAllButton}
          >
            View all templates
          </ButtonV2>
        )}
      </Grid>
      <MarketingStats context="sms" />
    </Grid>
  );
};

export default Sms;
