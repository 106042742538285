import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { BoxIcon, NoPhotoIcon } from '../Icons';
import Typography from '../Typography';
import {
  BaseProduct,
  CustomProduct,
  FulfillmentOption,
  Product,
  ShopTheme,
  TicketedEvent,
  getImageUrl,
} from '@castiron/domain';
import Dinero from 'dinero.js';
import { SoldOutBanner } from '../ProductModal';
import _ from 'lodash';
import { getProductFulfillmentTypes, getProductStatus } from '@castiron/utils';
import Pill from '../Pill';
import { Moment } from 'moment';
import momentTimezone from 'moment-timezone';

interface Props {
  onClick: () => void;
  product: BaseProduct;
  type: 'template' | 'product';
  isShop?: boolean;
  isCustom?: boolean;
  actionMenu?: ReactNode;
  disabled?: boolean;
  themeProps?: ShopTheme;
  timeZone: string;
  shopFulfillments?: FulfillmentOption[];
  sellerName?: string;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  boxIcon: {
    height: 12,
    marginTop: 4,
    width: 12,
  },
  container: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  featuredPill: {
    backgroundColor: theme.branding.v2.yellow[50],
    top: 8,
    borderRadius: 15,
    height: 28,
    padding: '4px 8px',
    position: 'absolute',
    left: 8,
    width: 'fit-content',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  image: {
    aspectRatio: '1/1',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  imageContainer: {
    borderRadius: props => (props?.themeProps?.shopButtonRoundness == '0px' ? 0 : 12),
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 16,
    border: `1px solid ${theme.branding.v2.gray[100]}`,
    backgroundColor: theme.branding.v2.gray[0],
  },
  noPhotoIcon: {
    color: theme.branding.v2.gray[400],
    height: 42,
    width: 42,
  },
  noPhotoText: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
  },
  productTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  statusPill: {
    backgroundColor: theme.branding.v2.gray[0],
    bottom: 8,
    borderRadius: 15,
    height: 28,
    padding: '4px 8px',
    position: 'absolute',
    right: 8,
    width: 'fit-content',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  statusPillDecorator: {
    fontSize: 16,
    marginRight: 4,
    color: theme.branding.v2.green[500],
  },
  text: {
    color: theme.branding.v2.gray[500],
  },
}));

const ProductCard: React.FC<Props> = (props: Props) => {
  const {
    onClick,
    product,
    type,
    isShop,
    actionMenu,
    isCustom = product?.type === 'custom',
    disabled,
    themeProps,
    timeZone,
    shopFulfillments = [],
    sellerName,
  } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isXsProductCard, setIsXsProductCard] = useState(isXsMobile);
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (!isShop && ref?.current) {
      setIsXsProductCard(ref?.current?.offsetWidth < 150);

      // if window is resized, update
      const handleResize = () => {
        if (!isShop && ref?.current) {
          setIsXsProductCard(ref?.current?.offsetWidth < 150);
        }
      };

      if (!isShop) {
        window.addEventListener('resize', handleResize);
      }
    }
  }, [ref]);

  const productImageUrl = getImageUrl(product, 'mediumVersion');

  const image = productImageUrl ? (
    <img src={productImageUrl} className={classes.image} alt={product.title} />
  ) : (
    <Grid
      className={classes.image}
      container
      justify="center"
      alignItems="center"
      style={
        isShop
          ? {
              padding: 8,
              backgroundColor: themeProps?.themeColors?.accent?.secondary || theme.branding.v2.gray[200],
            }
          : {}
      }
    >
      {isShop ? (
        <Typography className={classes.noPhotoText} variant="body2">
          {product.title}
        </Typography>
      ) : (
        <NoPhotoIcon className={classes.noPhotoIcon} />
      )}
    </Grid>
  );

  const statusPill = () => {
    const status = getProductStatus(product, timeZone);
    return (
      <Grid container className={classes.statusPill} justify="center" alignItems="center">
        <Typography variant="caption">
          <span
            className={classes.statusPillDecorator}
            style={
              status === 'inactive'
                ? { color: theme.branding.v2.red[500] }
                : status === 'archived'
                ? { color: theme.branding.v2.gray[500] }
                : {}
            }
          >
            {status === 'scheduled' ? '◦' : '•'}
          </span>
          {_.capitalize(status)}
        </Typography>
      </Grid>
    );
  };

  const eventTypePill = product?.type === 'event' && (
    <Pill
      variant="gray"
      content={!_.isEmpty((product as TicketedEvent)?.eventDetails?.location?.address) ? 'In Person' : 'Virtual'}
    />
  );

  const eventTypeIndicator = product => {
    if (product?.type === 'event') {
      const eventAddress = (product as TicketedEvent)?.eventDetails?.location?.address;

      return (
        <Typography variant="body2" style={{ color: theme.branding.v2.gray[500] }}>
          {!_.isEmpty(eventAddress) ? `${eventAddress.city}, ${eventAddress.region}` : 'Online'}
        </Typography>
      );
    }
  };

  const infoOverlayPill = isCustom ? (
    <Grid container className={classes.statusPill} justify="center" alignItems="center">
      <Typography variant="caption">Submit a Request</Typography>
    </Grid>
  ) : product?.type === 'event' ? (
    <Grid container className={classes.statusPill} justify="center" alignItems="center">
      <Typography variant="caption">
        {!!(product as TicketedEvent)?.eventDetails?.location?.address ? 'In Person' : 'Virtual'}
      </Typography>
    </Grid>
  ) : (
    <></>
  );

  const featuredPill = !isShop && product?.isFeatured && (
    <Grid
      container
      className={classes.featuredPill}
      justify="center"
      alignItems="center"
      style={isXsProductCard ? { height: 28, width: 28 } : {}}
    >
      <Typography variant="caption">⭐️{isXsProductCard ? '' : ' Featured'}</Typography>
    </Grid>
  );

  const getPriceAndQuantity = () => {
    if (isCustom) {
      if ((product as CustomProduct)?.startingPrice) {
        return `Starting at ${Dinero({ amount: (product as CustomProduct)?.startingPrice }).toFormat('$0.00')}`;
      } else {
        return 'Pricing Upon Request';
      }
    } else if (!isCustom) {
      const price = Dinero({ amount: (product as Product)?.price }).toFormat('$0.00');
      let inventory;
      if (product.unlimitedInventory) {
        inventory = 'Unlimited';
      } else if ((product as Product)?.inventory) {
        inventory = `${(product as Product)?.inventory} ${
          product?.type === 'standard' ? 'in stock' : `ticket${(product as Product)?.inventory === 1 ? '' : 's'} left`
        }`;
      } else {
        <span style={{ color: theme.branding.red.primary }}>Sold Out</span>;
      }
      if (type === 'product' && !product.unlimitedInventory && (product as Product)?.inventory === 0) return inventory;
      return isShop ? (
        price
      ) : (
        <>
          {price} • {inventory}
        </>
      );
    }
  };

  const formatDate = (date: Moment, dateFormat) => {
    return date.tz(timeZone).format(dateFormat);
  };
  const getEventDateText = () => {
    const dateFormat = 'M/D/YY [at] h:mma';
    const startDateMoment = momentTimezone.unix((product as TicketedEvent)?.eventDetails?.date?.startTime);
    return formatDate(startDateMoment, dateFormat);
  };

  const eventDate = product?.type === 'event' && (
    <Grid item>
      <Typography
        style={{
          color: !!themeProps?.shopButtonColor
            ? theme.branding.v2[themeProps.shopButtonColor][900]
            : theme.branding.v2.gray[500],
        }}
        variant="body2"
      >
        {getEventDateText()}
      </Typography>
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={`${!disabled && classes.container}`}
      onClick={onClick}
      ref={ref}
    >
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        className={classes.imageContainer}
        style={!product?.imageObj ? { backgroundColor: theme.branding.v2.gray[100] } : {}}
      >
        {featuredPill}
        {actionMenu}
        {image}
        {isShop ? infoOverlayPill : type === 'product' && statusPill()}
        <SoldOutBanner product={product} timeZone={timeZone} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.productTitle}>
          {product.title}
        </Typography>
      </Grid>
      {sellerName && (
        <Grid item>
          <Typography className={classes.text} variant="body2">
            {sellerName}
          </Typography>
        </Grid>
      )}
      {type === 'product' && (
        <>
          {!isShop && eventTypePill}
          {eventDate}
          {isShop && eventTypeIndicator(product)}
          <Grid item>
            <Typography className={classes.text} variant="body2">
              {getPriceAndQuantity()}
            </Typography>
          </Grid>
          {!isShop && product?.type !== 'event' && (
            <Grid alignItems="flex-start" container item wrap="nowrap" style={{ gap: 8 }}>
              <BoxIcon className={classes.boxIcon} />
              <Typography className={classes.text} variant="body2">
                {getProductFulfillmentTypes(product, shopFulfillments, false, 'productCard')}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default ProductCard;
