import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { Ellipse, Typography } from '@castiron/components';
import { TicketedEvent, Transaction, transactionToEventModel } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { CalendarViewType } from '..';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';

interface Props {
  event: TicketedEvent;
  viewType: CalendarViewType;
  context?: 'moreMenu' | 'directClick';
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  eventListing: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.branding.v2.blue[100],
    },
  },
  listingContent: {
    /* not sure why, but adding webclamp seems to push everything vertically */
    marginTop: '5px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': props => (props.viewType === 'Month' ? 1 : 2),
    '-webkit-box-orient': 'vertical',
  },
}));

const EventListing: React.FC<Props> = (props: Props) => {
  const { event, viewType, context = 'directClick' } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();

  const theme = useTheme();

  let ellipseColor;
  if (event.eventDetails?.location?.meetingUrl) {
    ellipseColor = 'lightPurple';
  } else {
    ellipseColor = 'deepPurple';
  }

  const handleClick = () => {
    trackEvent('Calendar Item Clicked', {
      type: 'event',
      context: context,
      id: event.id,
    });
    dispatch(
      openModal({
        modalType: 'CALENDAR_EVENT_LISTING_MODAL',
        modalProps: {
          event,
        },
      }),
    );
  };

  return (
    <Box className={classes.eventListing} onClick={handleClick}>
      <Grid container wrap="nowrap">
        <Grid item xs={viewType === 'Day' ? undefined : 2}>
          <Ellipse color={ellipseColor} diameter={8} />
        </Grid>
        <Grid item xs={viewType === 'Day' ? undefined : 10}>
          <Typography
            variant="caption"
            className={classes.listingContent}
            style={event.status === 'inactive' && !event.schedule ? { color: theme.branding.v2.gray[500] } : {}}
          >
            {event.title}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventListing;
