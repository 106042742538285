import React, { useEffect, useState } from 'react';
import { Grid, IconButton, TextField } from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '../../InputLabel';

interface Props {
  autoFocus?: boolean;
  label?: React.ReactNode;
  name?: string;
  required?: boolean;
  placeholder?: string;
  initialValue?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  containerClass: {
    marginBottom: 4,
  },
  input: {
    width: '100%',
    fontSize: 16,
    fontWeight: 400,
    border: 'none',
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.branding.gray[100],
      margin: 4,
      borderRadius: 12,
      '&:hover fieldset': {
        border: `4px solid ${theme.branding.blue.light} !important`,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.branding.blue.primary} !important`,
      },
    },
  },
}));

const SearchInput: React.FC<Props> = ({ label, name, placeholder, initialValue, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState(initialValue || '');

  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event);
  };

  return (
    <Grid container>
      {label && (
        <Grid item xs={12}>
          <InputLabel containerClass={classes.containerClass} primaryLabel={label} />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          className={classes.input}
          name={name}
          value={value}
          onChange={handleChange}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchOutlinedIcon />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SearchInput;
