import { CollapsableCard } from '@castiron/components';
import MomentUtils from '@date-io/moment';
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';

type Props = {
  isPaid: boolean;
  defaultExpanded?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
  },
}));

const QuoteExpire: React.FC<Props> = (props: Props) => {
  const { isPaid, defaultExpanded = false } = props;
  const { values, setFieldValue, errors, touched }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const onPaymentChange = event => {
    const selection = event.target.value;
    switch (selection) {
      case 'none':
        setFieldValue('order.expirationPeriod', 'none');
        setFieldValue('order.expirationDate', null);
        break;
      case 'date':
        setFieldValue('order.expirationPeriod', 'date');
    }
  };

  const onDateChange = value => {
    setFieldValue('order.expirationPeriod', 'date');
    setFieldValue(
      'order.expirationDate',
      value ? value.set({ hour: 12, minute: 0, second: 0, milliseconds: 0 }).unix() : null,
    );
  };

  return (
    <CollapsableCard title="⚡️ Auto Quote Management" noScroll handleExpand={toggleExpand} expanded={expanded}>
      <Typography>Simplify your workflow by setting an automatic expiration date.</Typography>
      <Typography style={{ marginTop: 16 }}>
        If your customer does not pay before the expiration date, we will automatically cancel this quote for you.
        Cancelation emails <b>do not</b> send to customers for automatically expired quotes.
      </Typography>
      <Typography style={{ marginTop: 24 }}>Expiration Date</Typography>
      <FormControl className={classes.formControl} variant="outlined">
        <RadioGroup
          aria-label="expiration period"
          name="order.expirationPeriod"
          value={values.order.expirationPeriod}
          onChange={onPaymentChange}
        >
          <FormControlLabel value="none" control={<Radio color="primary" />} label="None" disabled={isPaid} />
          <FormControlLabel value="date" control={<Radio color="primary" />} label="Custom" disabled={isPaid} />
          {values.order.expirationPeriod === 'date' && (
            <>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  minDateMessage={null}
                  disablePast={true}
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="MM/DD/yyyy"
                  margin="normal"
                  id="date-picker-inline-expire"
                  inputVariant="outlined"
                  value={values.order.expirationDate ? moment.unix(values.order.expirationDate) : null}
                  onChange={onDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disabled={isPaid}
                  error={touched?.order?.expirationDate && errors?.order?.expirationDate}
                />
              </MuiPickersUtilsProvider>
              {errors?.order?.expirationDate && touched?.order?.expirationDate && (
                <Typography variant="caption" style={{ color: theme.branding.v2.red[400] }}>
                  Must select a date
                </Typography>
              )}
            </>
          )}
        </RadioGroup>
      </FormControl>
    </CollapsableCard>
  );
};

export default QuoteExpire;
