import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

interface Props {
  color: 'blue' | 'green' | 'orange' | 'red' | 'yellow' | 'deepPurple' | 'lightPurple';
  diameter: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  ellipse: {
    display: 'inline-block',
    borderRadius: '50%',
    margin: '0 4px',
  },
}));

const Ellipse: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { color, diameter } = props;
  let colorToUse;
  switch (color) {
    case 'blue':
      colorToUse = theme.branding.v2.blue[500];
      break;
    case 'green':
      colorToUse = theme.branding.v2.green[800];
      break;
    case 'orange':
      colorToUse = theme.branding.v2.deepOrange[400];
      break;
    case 'red':
      colorToUse = theme.branding.v2.red[800];
      break;
    case 'yellow':
      colorToUse = theme.branding.v2.yellow[600];
      break;
    case 'deepPurple':
      colorToUse = theme.branding.v2.deepPurple[900];
      break;
    case 'lightPurple':
      colorToUse = theme.branding.v2.deepPurple[100];
      break;
    default:
      colorToUse = theme.branding.v2.gray[900];
  }
  const diameterPx = `${diameter}px`;

  return (
    <div className={classes.ellipse} style={{ height: diameterPx, width: diameterPx, backgroundColor: colorToUse }} />
  );
};

export default Ellipse;
