import { TicketedEvent, Transaction } from '@castiron/domain';
import _ from 'lodash';
import moment from 'moment-timezone';

interface CalendarTransactionEvent {
  title: string;
  startDate: string;
  endDate: string;
  transactions: Transaction[];
}

interface CuratedTransactions {
  calendarTransactionEvents: CalendarTransactionEvent[];
  missingQuotes: Transaction[];
  missingOrders: Transaction[];
}

const earliestRequiredQuoteFulfillmentDate = moment('2022-08-17').unix();
const earliestRequiredOrderFulfillmentDate = moment('2023-01-11').unix();

const CALENDAR_EVENT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
const DISPLAY_QUOTE_STATUSES = ['draft', 'new', 'pending', 'partially paid'];
const DISPLAY_ORDER_STATUSES = ['open', 'completed', 'fulfilled'];

const whichDate = (tx: any, timeFrame?: 'start' | 'end'): number => {
  if (timeFrame === 'end') {
    return _.get(tx, 'order.fulfillmentOption.schedule.dates[0].endTime');
  } else {
    return (
      _.get(tx, 'order.fulfillmentOption.schedule.dates[0].startTime') ||
      tx.order?.fulfillmentOption?.date ||
      tx.order?.requestedFulfillment?.date
    );
  }
};

const curateCalendarDay = (
  txes: Transaction[],
  maxDailyGroup: number,
  startHour: number,
): CalendarTransactionEvent[] => {
  const [quotes, orders] = _.partition(txes, tx => tx.order.type === 'custom');
  const [viewableQuotes, otherQuotes] = _.partition(quotes, tx =>
    DISPLAY_QUOTE_STATUSES.includes(tx.frontendState('quote')),
  );
  const customOrders = otherQuotes.filter(tx => tx.frontendState('quote') === 'paid');
  const viewableOrders = orders.filter(tx => DISPLAY_ORDER_STATUSES.includes(tx.frontendState('order')));

  const sortByOrderNumber = (txes: Transaction[]): Transaction[] => _.sortBy(txes, tx => tx.order.orderNumber);
  const calendarEvents = [
    ...sortByOrderNumber(viewableQuotes),
    ...sortByOrderNumber(customOrders),
    ...sortByOrderNumber(viewableOrders),
  ];

  const curatedCalendarEvents = calendarEvents.reduce((accum, tx, index) => {
    if (accum.length >= maxDailyGroup) {
      const lastElement = accum[accum.length - 1];
      return [
        ...accum.slice(0, accum.length - 1),
        { ...lastElement, title: 'rest', transactions: [...lastElement.transactions, tx] },
      ];
    } else {
      const hasDateAndTime = tx?.order?.fulfillmentOption?.schedule?.type === 'fixed';
      /* want them every half hour for day and week views if there is no specific fulfillment time selected */
      const fulfillmentTime = hasDateAndTime
        ? moment.unix(whichDate(tx, 'start'))
        : moment
            .unix(whichDate(tx, 'start'))
            .hour(index / 2 + startHour)
            .minute((index % 2) * 30)
            .second(0)
            .millisecond(0);
      const fulfillmentStart = fulfillmentTime.format(CALENDAR_EVENT_DATE_FORMAT);
      const fulfillmentEnd = hasDateAndTime
        ? moment.unix(whichDate(tx, 'end')).format(CALENDAR_EVENT_DATE_FORMAT)
        : fulfillmentTime
            .clone()
            .add(30, 'minutes')
            .format(CALENDAR_EVENT_DATE_FORMAT);
      return [
        ...accum,
        {
          title: tx?.order?.orderNumber,
          startDate: fulfillmentStart,
          endDate: fulfillmentEnd,
          allDay: !hasDateAndTime,
          endOfGroup: !hasDateAndTime,
          transactions: [tx],
        },
      ];
    }
  }, []);

  return curatedCalendarEvents;
};

export const curateTransactions = (
  txes: Transaction[],
  maxDailyGroup: number,
  startHour: number,
): CuratedTransactions => {
  //filter out transactions that only have event tickets
  const validTransactions = txes?.filter(
    tx => !tx.order?.items?.map(item => item.type).every(item => item === 'event'),
  );

  const [withDates, withoutDates] = _.partition(validTransactions, tx => whichDate(tx));
  const byDate = _.groupBy(withDates, tx => moment.unix(whichDate(tx)).format('YYYYMMDD'));
  const calendarTransactionEvents = _.flatMap(byDate, txes => curateCalendarDay(txes, maxDailyGroup, startHour));

  const [missingQuotes, ordersWithoutDates] = _.partition(
    withoutDates.filter(tx => earliestRequiredQuoteFulfillmentDate <= tx.createdAt),
    tx => tx.order.type === 'custom' && DISPLAY_QUOTE_STATUSES.includes(tx.frontendState('quote')),
  );
  const missingOrders = ordersWithoutDates.filter(
    order =>
      earliestRequiredOrderFulfillmentDate <= order?.createdAt &&
      DISPLAY_ORDER_STATUSES.includes(order?.frontendState('order')),
  );

  return {
    calendarTransactionEvents,
    missingQuotes,
    missingOrders,
  };
};

export const curateEventProducts = (products, maxDailyGroup: number, startHour: number) => {
  const events: TicketedEvent[] = products.filter(p => p.type === 'event' && ['active', 'inactive'].includes(p.status));
  const byDate = _.groupBy(events, event => moment.unix(event?.eventDetails?.date?.startTime).format('YYYYMMDD'));

  const curatedEventProducts = _.flatMap(byDate, events => {
    const calendarEvents = _.sortBy(events, e => e.eventDetails.date.startTime);

    const curatedCalendarEvents = calendarEvents.reduce((accum, event, index) => {
      const startDate = moment.unix(event?.eventDetails?.date?.startTime).format(CALENDAR_EVENT_DATE_FORMAT);
      const endDate = moment.unix(event?.eventDetails?.date?.endTime).format(CALENDAR_EVENT_DATE_FORMAT);

      return [
        ...accum,
        {
          title: event?.title,
          startDate,
          endDate,
          event,
        },
      ];
    }, []);

    return curatedCalendarEvents;
  });

  return curatedEventProducts;
};
