import _ from 'lodash';
import { Transaction } from '@castiron/domain';

const getRefundsTotal = (transaction: Transaction): number => {
  return (
    transaction?.order?.payments
      ?.filter(payment => payment.type === 'refund' && !['canceled', 'failed'].includes(payment.status))
      ?.reduce((total, currentRefund) => currentRefund.amount + total, 0) || 0
  );
};

const getPaymentsTotal = (transaction: Transaction): number => {
  return (
    (transaction?.processor?.name === 'stripe' &&
      transaction?.order?.payments
        ?.filter(payment => payment?.type === 'credit-card' && payment.status === 'received')
        ?.reduce((total, currentPayment) => currentPayment?.amount + total, 0)) ||
    0
  );
};

export const getRefundProps = (transaction: Transaction, subTransactions?: Transaction[]) => {
  const payments = transaction?.order?.payments;

  const hasSubTransactions = !_.isEmpty(subTransactions);
  const subTransactionPayments = subTransactions?.[0]?.order?.payments;

  const existingDepositPayments = hasSubTransactions ? getPaymentsTotal(subTransactions[0]) : 0;
  const existingDepositRefunds = hasSubTransactions ? getRefundsTotal(subTransactions[0]) : 0;
  const existingBalancePayments = getPaymentsTotal(transaction);
  const existingBalanceRefunds = getRefundsTotal(transaction);

  const refunds = [
    ...(!_.isEmpty(payments) ? payments?.filter(p => p?.type === 'refund' && p?.status === 'succeeded') : []),
    ...(!_.isEmpty(subTransactionPayments)
      ? subTransactionPayments?.filter(p => p?.type === 'refund' && p?.status === 'succeeded')
      : []),
  ];

  const refundTotal = refunds.reduce((sum, p) => sum + (p?.amount || 0), 0);

  return {
    refunds,
    refundTotal,
    refundInfo: {
      deposit: {
        available: existingDepositPayments - existingDepositRefunds,
        transactionIdentifier: subTransactions?.length > 0 ? subTransactions[0]?.processor?.transactionIdentifier : '',
        transactionId: subTransactions?.length > 0 ? subTransactions[0]?.id : '',
      },
      balance: {
        available: existingBalancePayments - existingBalanceRefunds,
        transactionIdentifier: transaction?.processor?.transactionIdentifier,
        transactionId: transaction?.id,
      },
      refundableTotal:
        existingBalancePayments + existingDepositPayments - (existingBalanceRefunds + existingDepositRefunds),
    },
  };
};
