import React, { useEffect, useState, ReactElement } from 'react';
import { Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GridCellParams, GridColumns } from '@material-ui/data-grid';
import Dinero from 'dinero.js';
import DataGrid from '../../DataGrid/DataGrid';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Chip } from '@castiron/components';
import { getService } from '../../../firebase';
import _ from 'lodash';
import Spinner from '../../Spinner';

const searchService = getService('orders', 'search');

type Props = {
  data: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: 20,
    fontWeight: 800,
    marginTop: 80,
    marginBottom: 22,
  },
}));

const OrdersTable: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [subTransactions, setSubTransactions] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const history = useHistory();

  const getTotal = (params: GridCellParams) => {
    const subTransaction = subTransactions.find(tx => tx?.parentId === params.row.id);
    const parentTotal = params.row.totalMinusRefund || 0;
    const depositTotal = subTransaction?.totalMinusRefund || 0;
    return parentTotal + depositTotal;
  };

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Order #',
      flex: 0.8,
      renderCell: (params: GridCellParams): ReactElement => {
        return params.row.orderNumber;
      }
    },
    {
      field: 'status',
      flex: 1,
      renderCell: (params: GridCellParams): ReactElement => {
        let status;
        if (params.value === 'succeeded') status = 'success';
        if (params.value === 'open') status = 'warning';
        if (params.value === 'canceled') status = 'error';
        return (
          <Chip colorScheme={status} uppercase bold>
            {params.value === 'soldOut' ? 'Sold Out' : params.value.toString()}
          </Chip>
        );
      },
    },
    {
      field: 'date',
      flex: 1,
      valueFormatter: (params: GridCellParams): string => {
        const date = params.row.createdAt;
        return date ? moment.unix(date).format('MM/DD/YYYY') : 'Unknown';
      },
    },
    {
      field: 'total',
      flex: 1,
      align: isMobile ? 'right' : 'left',
      valueFormatter: (params: GridCellParams): string => {
        const total = getTotal(params) || 0;
        return Dinero({ amount: total }).toFormat('$0.00');
      },
    },
    {
      field: 'actions',
      renderHeader: (): ReactElement => <div />,
      flex: 0.4,
    },
  ];

  const columnsMobile = columns.filter(column => column.field === 'id' || column.field === 'total');

  useEffect(() => {
    const getCustomerOrders = async () => {
      setIsTransactionsLoading(true);
      const customerId = data.id;
      const response = await searchService({
        stage: 'order',
        transactionStatus: 'succeeded',
        transactionTypes: ['transaction', 'sub-transaction'],
        customerId,
      });
      let [customerOrders, subTransactions] = _.partition(response?.orders, tx => tx.transactionType === 'transaction');
      setCustomerOrders(customerOrders);
      setSubTransactions(subTransactions);
      setIsTransactionsLoading(false);
    };
    getCustomerOrders();
  }, [data]);

  return (
    <>
      <Spinner size='fullscreen' show={isTransactionsLoading} />
      <Typography variant="h3" className={classes.subtitle}>
        Orders placed
      </Typography>
      <DataGrid
        onCellClick={params => history.push(`/orders/edit/${params.row.id}`)}
        sortModel={[{ field: 'id', sort: 'desc' }]}
        columns={isMobile ? columnsMobile : columns}
        rows={customerOrders}
        rowHeight={72}
      />
    </>
  );
};

export default OrdersTable;
