import { Theme } from '@material-ui/core';
import { getActiveMarketingTemplates } from '@castiron/domain';

export const getSingleSendEmails = async ({
  theme,
  isPresalesEnabled,
  activeEventTags,
}: {
  theme: Theme;
  isPresalesEnabled: boolean;
  activeEventTags: string[];
}) => {
  //in order that they should display in modal
  const templates = [
    {
      icon: '💬',
      iconBackgroundColor: theme.branding.v2.gray[50],
      pillText: 'New!',
      name: 'Sign Up for SMS Email',
      subheader: 'Encourage email subscribers to opt in to SMS.',
      route: '/marketing/single-send-email?type=sms-signup',
      event: 'SMS Signup Email Created',
      category: 'Basic',
    },
    {
      icon: '🍪',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Product Announcement Email',
      subheader: 'Notify customers about a new product.',
      route: '/marketing/single-send-email?type=new-product',
      category: 'Basic',
    },
    {
      icon: '🗓️',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Custom Order Availability Email',
      subheader: 'Notify customers about custom order availability.',
      route: '/marketing/single-send-email?type=custom-order-available',
      category: 'Basic',
    },
    {
      icon: '👩‍🏫',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Ticketed Event Announcement Email',
      subheader: 'Drive ticket sales for an upcoming in person or virtual event.',
      route: '/marketing/single-send-email?type=ticketed-event-announcement',
      category: 'Basic',
    },
    ...(isPresalesEnabled
      ? [
          {
            icon: '🛒',
            iconBackgroundColor: theme.branding.v2.gray[50],
            name: 'Presale Announcement Email',
            subheader: 'Notify customers about an active presale.',
            route: '/marketing/single-send-email?type=new-presale',
            category: 'Basic',
          },
        ]
      : []),
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 8',
      name: '4th of July Announcement Email',
      subheader: 'Introduce your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-announcement',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 11',
      name: '4th of July Customs Email',
      subheader: 'Share about your custom order offerings for 4th of July.',
      route: '/marketing/single-send-email?type=4th-of-july-customs',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 14',
      name: '4th of July Reminder Email',
      subheader: 'Remind customers of your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-reminder',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 21',
      name: '4th of July Engagement Email',
      subheader: 'Engage with customers about your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-engagement',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 28',
      name: '4th of July Shop Email',
      subheader: 'Remind customers of your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-shop',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 30',
      name: '4th of July Last Call Email',
      subheader: 'Engage with customers about your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-last-call',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Announcement',
      subheader: 'Introduce your Graduation offerings.',
      route: '/marketing/single-send-email?type=graduation-announcement',
      event: 'Graduation Email Created',
      category: 'Graduation',
      schedule: {
        startDate: '2/3',
        endDate: '8/1',
        crossYear: false,
      },
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Pre-Sale',
      subheader: 'Let customers know to place their pre-sale orders.',
      route: '/marketing/single-send-email?type=graduation-presale',
      event: 'Graduation Email Created',
      category: 'Graduation',
      schedule: {
        startDate: '2/3',
        endDate: '8/1',
        crossYear: false,
      },
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Custom Order',
      subheader: 'Offer customers the option to shop custom orders for Graduation.',
      route: '/marketing/single-send-email?type=graduation-custom',
      event: 'Graduation Email Created',
      category: 'Graduation',
      schedule: {
        startDate: '2/3',
        endDate: '8/1',
        crossYear: false,
      },
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Reminder',
      subheader: 'Remind customers of your Graduation offerings.',
      route: '/marketing/single-send-email?type=graduation-reminder',
      event: 'Graduation Email Created',
      category: 'Graduation',
      schedule: {
        startDate: '2/3',
        endDate: '8/1',
        crossYear: false,
      },
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Last-Call',
      subheader: 'Remind customers to get last-minute orders in.',
      route: '/marketing/single-send-email?type=graduation-last-call',
      event: 'Graduation Email Created',
      category: 'Graduation',
      schedule: {
        startDate: '2/3',
        endDate: '8/1',
        crossYear: false,
      },
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Announcement Email',
      subheader: 'Introduce your back-to-school offerings.',
      route: '/marketing/single-send-email?type=back-to-school-announcement',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Reminder Email',
      subheader: 'Remind customers to shop your back-to-school offerings.',
      route: '/marketing/single-send-email?type=back-to-school-reminder',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Shop Email',
      subheader: 'Promote your back-to-school offerings.',
      route: '/marketing/single-send-email?type=back-to-school-shop',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Custom Order Email',
      subheader: 'Share your back-to-school custom orders.',
      route: '/marketing/single-send-email?type=back-to-school-customs',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Last Call Email',
      subheader: 'Last call for back-to-school orders!',
      route: '/marketing/single-send-email?type=back-to-school-last-call',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Fall Announcement',
      subheader: 'Introduce customers to your fall delicacies.',
      route: '/marketing/single-send-email?type=fall-announcement',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Fall Reminder',
      subheader: 'Notify customers about your fall delicacies.',
      route: '/marketing/single-send-email?type=fall-reminder',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Shop Fall Products',
      subheader: 'Engage customers with your autumn-filled offerings.',
      route: '/marketing/single-send-email?type=shop-fall',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Custom Order for Fall',
      subheader: 'Share your Fall custom orders.',
      route: '/marketing/single-send-email?type=fall-customs',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Last-Call for Fall Products',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-email?type=last-call-fall',
      event: 'Fall Email Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Halloween Announcement',
      subheader: 'Introduce your spooky offerings.',
      route: '/marketing/single-send-email?type=halloween-announcement',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Halloween Reminder',
      subheader: 'Remind customers to shop your Halloween offerings.',
      route: '/marketing/single-send-email?type=halloween-reminder',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Shop Halloween Products',
      subheader: 'Encourage shoppers to check out your Halloween offerings.',
      route: '/marketing/single-send-email?type=shop-halloween',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Custom Halloween-Themed Order Email',
      subheader: 'Offer personal treats for a better holiday.',
      route: '/marketing/single-send-email?type=halloween-customs',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Last-Call for Halloween Treats',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-email?type=last-call-halloween',
      event: 'Halloween Email Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Thanksgiving Announcement',
      subheader: 'Introduce customers to your Thanksgiving delicacies.',
      route: '/marketing/single-send-email?type=thanksgiving-announcement',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Thanksgiving Reminder',
      subheader: 'Notify customers about your Thanksgiving delicacies.',
      route: '/marketing/single-send-email?type=thanksgiving-reminder',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Shop Thanksgiving Products',
      subheader: 'Engage customers with your Thanksgiving offerings.',
      route: '/marketing/single-send-email?type=shop-thanksgiving',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Custom Order for Thanksgiving',
      subheader: 'Share your Thanksgiving custom orders.',
      route: '/marketing/single-send-email?type=thanksgiving-customs',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Last-Call for Thanksgiving Products',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-email?type=last-call-thanksgiving',
      event: 'Thanksgiving Email Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    //black friday
    // {
    //   icon: '🏷️',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Black Friday Announcement 1',
    //   subheader: 'Get Ready for Black Friday Shopping!',
    //   route: '/marketing/single-send-email?type=black-friday-announcement-1',
    //   event: 'Black Friday Email Created',
    //   category: 'Black Friday',
    // },
    // {
    //   icon: '🏷️',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Black Friday Announcement 2',
    //   subheader: 'Shop Black Friday Early!',
    //   route: '/marketing/single-send-email?type=black-friday-announcement-2',
    //   event: 'Black Friday Email Created',
    //   category: 'Black Friday',
    // },
    // {
    //   icon: '🏷️',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Black Friday Announcement 3',
    //   subheader: 'Get Ahead on Black Friday Shopping!',
    //   route: '/marketing/single-send-email?type=black-friday-announcement-3',
    //   event: 'Black Friday Email Created',
    //   category: 'Black Friday',
    // },
    //small business saturday
    // {
    //   icon: '🏪',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Small Business Saturday Announcement 1',
    //   subheader: 'Shop Here for Small Business Saturday!',
    //   route: '/marketing/single-send-email?type=small-business-saturday-1',
    //   event: 'Small Business Saturday Email Created',
    //   category: 'Small Business Saturday',
    // },
    // {
    //   icon: '🏪',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Small Business Saturday Announcement 2',
    //   subheader: 'Small Business Saturday Inspo!',
    //   route: '/marketing/single-send-email?type=small-business-saturday-2',
    //   event: 'Small Business Saturday Email Created',
    //   category: 'Small Business Saturday',
    // },
    // {
    //   icon: '🏪',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Small Business Saturday Announcement 3',
    //   subheader: 'Remember - Small Business Saturday!',
    //   route: '/marketing/single-send-email?type=small-business-saturday-3',
    //   event: 'Small Business Saturday Email Created',
    //   category: 'Small Business Saturday',
    // },
    //cyber monday
    // {
    //   icon: '💻',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Cyber Monday Announcement 1',
    //   subheader: 'Go Unique this Cyber Monday!',
    //   route: '/marketing/single-send-email?type=cyber-monday-announcement-1',
    //   event: 'Cyber Monday Email Created',
    //   category: 'Cyber Monday',
    // },
    // {
    //   icon: '💻',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Cyber Monday Announcement 2',
    //   subheader: 'Shop Outside the Box on Cyber Monday!',
    //   route: '/marketing/single-send-email?type=cyber-monday-announcement-2',
    //   event: 'Cyber Monday Email Created',
    //   category: 'Cyber Monday',
    // },
    // {
    //   icon: '💻',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Cyber Monday Announcement 3',
    //   subheader: 'Break the Mold on Cyber Monday!',
    //   route: '/marketing/single-send-email?type=cyber-monday-announcement-3',
    //   event: 'Cyber Monday Email Created',
    //   category: 'Cyber Monday',
    // },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Christmas Announcement',
      subheader: 'Introduce your Christmas offerings.',
      route: '/marketing/single-send-email?type=christmas-announcement',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Christmas Reminder',
      subheader: 'Remind customers to shop your Christmas offerings.',
      route: '/marketing/single-send-email?type=christmas-reminder',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Shop Christmas Products',
      subheader: 'Encourage shoppers to check out your Christmas offerings.',
      route: '/marketing/single-send-email?type=shop-christmas',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Custom Christmas Order Email',
      subheader: 'Offer personal treats for a better holiday.',
      route: '/marketing/single-send-email?type=christmas-customs',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Last-Call for Christmas Treats',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-email?type=last-call-christmas',
      event: 'Christmas Email Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🕎',
      iconBackgroundColor: theme.branding.v2.blue[50],
      name: 'Hanukkah Announcement',
      subheader: 'Introduce your Hanukkah offerings.',
      route: '/marketing/single-send-email?type=hanukkah-announcement',
      event: 'Hanukkah Email Created',
      eventTag: 'Hanukkah',
      category: 'Hanukkah',
    },
    {
      icon: '🕎',
      iconBackgroundColor: theme.branding.v2.blue[50],
      name: 'Hanukkah Reminder',
      subheader: 'Remind customers to shop your Hanukkah offerings.',
      route: '/marketing/single-send-email?type=hanukkah-reminder',
      event: 'Hanukkah Email Created',
      eventTag: 'Hanukkah',
      category: 'Hanukkah',
    },
    {
      icon: '🕯️',
      iconBackgroundColor: theme.branding.v2.green[50],
      name: 'Kwanzaa Announcement',
      subheader: 'Introduce your Kwanzaa offerings.',
      route: '/marketing/single-send-email?type=kwanzaa-announcement',
      event: 'Kwanzaa Email Created',
      eventTag: 'Kwanzaa',
      category: 'Kwanzaa',
    },
    {
      icon: '🕯️',
      iconBackgroundColor: theme.branding.v2.green[50],
      name: 'Kwanzaa Reminder',
      subheader: 'Remind shoppers to check out your Kwanzaa offerings.',
      route: '/marketing/single-send-email?type=kwanzaa-reminder',
      event: 'Kwanzaa Email Created',
      eventTag: 'Kwanzaa',
      category: 'Kwanzaa',
    },
    {
      icon: '🎊',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: "New Year's Eve 1",
      subheader: 'Promote your NYE offerings.',
      route: '/marketing/single-send-email?type=nye-announcement',
      event: "New Year's Eve Email Created",
      eventTag: 'NewYears',
      category: "New Year's Eve",
    },
    {
      icon: '🎊',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: "New Year's Eve 2",
      subheader: 'Promote your NYE offerings.',
      route: '/marketing/single-send-email?type=shop-nye',
      event: "New Year's Eve Email Created",
      eventTag: 'NewYears',
      category: "New Year's Eve",
    },
    {
      icon: '🎊',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: "New Year's Eve 3",
      subheader: 'Drive urgency for your NYE offerings.',
      route: '/marketing/single-send-email?type=last-call-nye',
      event: "New Year's Eve Email Created",
      eventTag: 'NewYears',
      category: "New Year's Eve",
    },
    {
      icon: '♥️',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: "Valentine’s Day Announcement",
      subheader: 'Introduce your Valentine’s Day offerings.',
      route: '/marketing/single-send-email?type=valentines-announcement',
      event: "Valentine's Day Email Created",
      category: "Valentine's Day",
      schedule: {
        startDate: '1/15',
        endDate: '2/14',
        crossYear: false,
      },
    },
    {
      icon: '♥️',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: "Valentine’s Day New Product",
      subheader: 'Introduce new products for the holiday.',
      route: '/marketing/single-send-email?type=shop-valentines',
      event: "Valentine's Day Email Created",
      category: "Valentine's Day",
      schedule: {
        startDate: '1/15',
        endDate: '2/14',
        crossYear: false,
      },
    },
    {
      icon: '♥️',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: "Valentine’s Day Last-Call",
      subheader: 'Remind customers to shop your Valentine’s Day offerings.',
      route: '/marketing/single-send-email?type=last-call-valentines',
      event: "Valentine's Day Email Created",
      category: "Valentine's Day",
      schedule: {
        startDate: '1/15',
        endDate: '2/14',
        crossYear: false,
      },
    },
    {
      icon: '🏈️',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: "Big Game Announcement",
      subheader: 'Introduce your Big Game Day offerings.',
      route: '/marketing/single-send-email?type=big-game-announcement',
      event: "The Big Game Email Created",
      category: "The Big Game",
      schedule: {
        startDate: '1/15',
        endDate: '2/9',
        crossYear: false,
      },
    },
    {
      icon: '🏈️',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: "Big Game New Products",
      subheader: 'Introduce new products for the Big Game.',
      route: '/marketing/single-send-email?type=big-game-new-products',
      event: "The Big Game Email Created",
      category: "The Big Game",
      schedule: {
        startDate: '1/15',
        endDate: '2/9',
        crossYear: false,
      },
    },
    {
      icon: '🏈️',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: "Big Game Last-Call",
      subheader: 'Remind customers to shop your Big Game offerings.',
      route: '/marketing/single-send-email?type=big-game-last-call',
      event: "The Big Game Email Created",
      category: "The Big Game",
      schedule: {
        startDate: '1/15',
        endDate: '2/9',
        crossYear: false,
      },
    },
    {
      icon: '🐣',
      iconBackgroundColor: theme.branding.v2.cyan[50],
      name: "Easter Announcement",
      subheader: 'Introduce your Easter offerings.',
      route: '/marketing/single-send-email?type=easter-announcement',
      event: "Easter Email Created",
      category: "Easter",
      schedule: {
        startDate: '2/17',
        endDate: '4/21',
        crossYear: false,
      },
    },
    {
      icon: '🐣',
      iconBackgroundColor: theme.branding.v2.cyan[50],
      name: "Easter Reminder",
      subheader: 'Remind customers to shop your Easter offerings.',
      route: '/marketing/single-send-email?type=easter-reminder',
      event: "Easter Email Created",
      category: "Easter",
      schedule: {
        startDate: '2/17',
        endDate: '4/21',
        crossYear: false,
      },
    },
    {
      icon: '🐣',
      iconBackgroundColor: theme.branding.v2.cyan[50],
      name: "Last-Call for Easter Orders",
      subheader: 'Get customers to shop before it’s too late.',
      route: '/marketing/single-send-email?type=easter-last-call',
      event: "Easter Email Created",
      category: "Easter",
      schedule: {
        startDate: '2/17',
        endDate: '4/21',
        crossYear: false,
      },
    },
    {
      icon: '☘️',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: "St. Patrick’s Day Announcement",
      subheader: 'Introduce your St. Patrick’s Day offerings.',
      route: '/marketing/single-send-email?type=stpatricks-announcement',
      event: "St. Patrick's Email Created",
      category: "St. Patrick’s",
      schedule: {
        startDate: '2/17',
        endDate: '3/18',
        crossYear: false,
      },
    },
    {
      icon: '☘️',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: "Last-Call for St. Patrick’s Day Orders",
      subheader: 'Get customers to shop before it’s too late.',
      route: '/marketing/single-send-email?type=stpatricks-last-call',
      event: "St. Patrick's Email Created",
      category: "St. Patrick’s",
      schedule: {
        startDate: '2/17',
        endDate: '3/18',
        crossYear: false,
      },
    },
  ];

  return getActiveMarketingTemplates({ templates, activeEventTags });
};

export const emailCategoriesMap = [
  {
    icon: '📣',
    name: 'Basic',
    description: 'Product announcements, custom orders, and more.',
  },
  {
    icon: '🧢',
    name: "Father's Day",
    description: "Incite customers to interact with your father's day offerings.",
    eventTag: 'FathersDay',
  },
  {
    icon: '🐣',
    iconBackground: '#E0F7FA',
    name: 'Easter',
    description: 'Introduce your Easter offerings to your customers.',
  },
  {
    icon: '🎓',
    iconBackground: '#F1F8E9',
    name: 'Graduation',
    description: 'Notify customers about your Graduation offerings.',
  },
  {
    icon: '☘️',
    iconBackground: '#F9F5EE',
    name: "St. Patrick’s",
    description: 'Notify customers about your St. Patrick’s offerings.',
  },
  {
    icon: '🎇',
    name: '4th of July',
    description: 'Engage customers with all you are offering for this celebration.',
    eventTag: '4thOfJuly',
  },
  {
    icon: '📚',
    name: 'Back to School',
    description: 'Incite customers to interact with your back to school offerings.',
    eventTag: 'BackToSchool',
  },
  {
    icon: '🍁',
    iconBackground: '#F9F5EE',
    name: 'Fall',
    description: 'Share all about your Fall delicacies.',
    eventTag: 'Fall',
  },
  {
    icon: '🎃',
    iconBackground: '#EDE7F6',
    name: 'Halloween',
    description: 'Notify customers about your Halloween-themed offerings.',
    eventTag: 'Halloween',
  },
  {
    icon: '🦃',
    iconBackground: '#FFFDE7',
    name: 'Thanksgiving',
    description: 'Notify customers about your Thanksgiving offerings.',
    eventTag: 'Thanksgiving',
  },
  {
    icon: '🏷️',
    iconBackground: '#FAF7EC',
    name: 'Black Friday',
    description: 'Notify customers about your Black Friday offerings.',
  },
  {
    icon: '🏪',
    iconBackground: '#FAF7EC',
    name: 'Small Business Saturday',
    description: 'Notify customers about your Small Business Saturday offerings.',
  },
  {
    icon: '💻',
    iconBackground: '#FAF7EC',
    name: 'Cyber Monday',
    description: 'Notify customers about your Cyber Monday offerings.',
  },
  {
    icon: '🎄',
    iconBackground: '#FFEBEE',
    name: 'Christmas',
    description: 'Notify customers about your Christmas offerings.',
    eventTag: 'Christmas',
  },
  {
    icon: '🕎',
    iconBackground: '#F5F9FF',
    name: 'Hanukkah',
    description: 'Notify customers about your Hanukkah offerings.',
    eventTag: 'Hanukkah',
  },
  {
    icon: '🕯️',
    iconBackground: '#E8F5E9',
    name: 'Kwanzaa',
    description: 'Notify customers about your Kwanzaa offerings.',
    eventTag: 'Kwanzaa',
  },
  {
    icon: '🎊',
    iconBackground: '#FFFDE7',
    name: "New Year's Eve",
    description: "Notify customers about your New Year's Eve offerings.",
    eventTag: 'NewYears',
  },
  {
    icon: '♥️',
    iconBackground: '#FFEBEE',
    name: "Valentine's Day",
    description: "Notify customers about your Valentine’s Day offerings.",
  },
  {
    icon: '🏈️',
    iconBackground: '#F1F8E9',
    name: "The Big Game",
    description: "Notify customers about your Big Game Day offerings.",
  },
];
