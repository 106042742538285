import React from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Banner, Typography } from '@castiron/components';
import { Transaction } from '@castiron/domain';

type Props = {
  refundTotal: number;
  refundableAmount: number;
  transaction: Transaction;
  onLinkClick?: () => void;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  bannerMargin: {
    marginBottom: '16px',
  },
  viewOrderLink: {
    color: 'inherit',
    fontWeight: 700,
    '&:hover': {
      color: 'inherit',
      cursor: 'pointer',
    },
  },
}));

const RefundBanner: React.FC<Props> = (props: Props) => {
  const { refundTotal, refundableAmount, transaction, onLinkClick } = props;
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Banner variant="info-yellow" className={classes.bannerMargin}>
      <Typography variant="body2" style={{ color: 'inherit' }}>
        This order has been {refundableAmount === 0 ? 'fully' : 'partially'} refunded.{' '}
        <Link
          onClick={() => {
            onLinkClick && onLinkClick();
            history.push(`/orders/edit/${transaction?.id}`);
          }}
          className={classes.viewOrderLink}
        >
          View Order
        </Link>
      </Typography>
    </Banner>
  );
};

export default RefundBanner;
