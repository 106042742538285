import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dinero from 'dinero.js';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import { DialogContent, Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Banner, ButtonV2, Checkbox, MoneyInput, Select, Typography } from '@castiron/components';
import { calculateTotalsForDepositOrder, Customer, Transaction } from '@castiron/domain';
import { defaultTimeZone } from '@castiron/utils';
import { transactionRepository } from '../../domain';
import { getService } from '../../firebase';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal, closeModal } from '../../store/reducers/modalConductor';
import { listTransactionsAction } from '../../store/reducers/transactions';
import AdminForm from '../AdminForm';
import ModalActions from '../RootModal/ModalActions';
import ModalHeader from '../RootModal/ModalHeader';
import ModalWrapper from '../RootModal/ModalWrapper';
import { getRefundProps } from './refundUtils';

const createRefundIntentService = getService('stripe', 'createrefund', { version: 2 });

export type Props = {
  show: boolean;
  transaction: Transaction;
  subTransactions: Transaction[];
  customer: Customer;
};

export type Step = 'issue' | 'review';

interface FormValues {
  reason: string;
  amount: number;
  cancel: boolean;
}

const refundReasonOptions = [
  { label: 'Customer had an issue with the order', value: 'issue' },
  { label: 'Order arrived damaged', value: 'damaged' },
  { label: 'Seller declines the sale', value: 'declined' },
  { label: 'Seller refunding accidental overages', value: 'overages' },
  { label: 'Other', value: 'other' },
];

const formatCurrency = amount => {
  return Dinero({ amount: amount || 0 }).toFormat('$0.00');
};

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiFormControlLabel-label': {
        paddingRight: 0,
      },
    },
  },
  content: {
    gap: '24px',
    margin: '32px 40px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '32px 16px',
    },
  },
  paperClass: {
    borderRadius: 32,
    width: 531,
    overflow: 'hidden',
    [theme.breakpoints.up('xs')]: {
      height: 'fit-content',
    },
  },
  scroll: {
    padding: 0,
    overflowY: 'scroll',
    'scrollbar-width': 'thin',
    'scrollbar-color': theme.branding.v2.gray[400],
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.branding.v2.gray[600],
      '-webkit-border-radius': '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.branding.v2.gray[0],
    },
    '&::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
    },
    '&-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
    },
  },
  select: {
    margin: '4px 0',
    width: '100%',
  },
}));

const RefundOrderModal: React.FC<Props> = (props: Props) => {
  const { show, transaction, subTransactions, customer } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const formRef = useRef<FormikProps<any>>();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const timeZone = shop.config?.timeZone || defaultTimeZone;

  const [step, setStep] = useState<Step>('issue');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const totals =
    subTransactions?.length > 0 && transaction
      ? calculateTotalsForDepositOrder({
          order: transaction.order,
          paymentSettings: shop.paymentSettings,
          subTransactionTotal: subTransactions[0]?.totals?.subtotal,
          tip: transaction?.totals?.tip || 0,
          tippingPresetPercentage: transaction?.totals?.tippingPresetPercentage,
        })
      : transaction?.totals;

  const paymentDate = transaction?.order?.payments?.[0]?.paidAt
    ? moment
        .unix(transaction?.order?.payments?.[0]?.paidAt)
        .tz(timeZone)
        .format('M/D/YYYY')
    : subTransactions?.[0]?.order?.payments?.[0]?.paidAt
    ? moment
        .unix(subTransactions?.[0]?.order?.payments?.[0]?.paidAt)
        .tz(timeZone)
        .format('M/D/YYYY')
    : '';

  const { refundInfo } = getRefundProps(transaction, subTransactions);
  const formattedAmountAvailableToRefund = formatCurrency(refundInfo?.refundableTotal);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleReviewClick = async values => {
    await formRef?.current?.validateField('amount');
    await formRef?.current?.setFieldTouched('amount', true);
    if (!formRef?.current?.errors?.amount) {
      setStep('review');
    }
  };

  const handleSubmit = async values => {
    try {
      setIsSubmitting(true);

      const note = refundReasonOptions.find(option => option.value === values?.reason)?.label;
      let refundAmountRequested = values.amount;
      if (refundInfo?.deposit?.available > 0) {
        const depositRefundToRequest =
          values.amount > refundInfo?.deposit?.available ? refundInfo?.deposit?.available : values.amount;
        const depositRefundIntent = await createRefundIntentService({
          amount: depositRefundToRequest,
          metadata: {
            transactionId: refundInfo?.deposit?.transactionId,
            customerId: customer.id,
            marketplace: 'castiron',
            shopId: shop.id,
          },
          marketplace: 'castiron',
          transactionIdentifier: refundInfo?.deposit?.transactionIdentifier,
          note,
        });
        if (!depositRefundIntent.success) {
          throw new Error('Deposit Refund Failed');
        }
        refundAmountRequested = refundAmountRequested - depositRefundToRequest;
      }
      if (refundAmountRequested > 0) {
        const balanceRefundIntent = await createRefundIntentService({
          amount: refundAmountRequested,
          metadata: {
            transactionId: refundInfo?.balance?.transactionId,
            customerId: customer.id,
            marketplace: 'castiron',
            shopId: shop.id,
          },
          marketplace: 'castiron',
          transactionIdentifier: refundInfo?.balance?.transactionIdentifier,
          note,
        });
        if (!balanceRefundIntent.success) {
          throw new Error('Balance Refund Failed');
        }
      }

      if (values.cancel) {
        await transactionRepository.updateProps(transaction.id, {
          status: 'canceled',
        });
        if (subTransactions?.length > 0) {
          await transactionRepository.updateProps(subTransactions[0].id, {
            status: 'canceled',
          });
        }
      }

      dispatch(listTransactionsAction(transaction.shopId));
      setIsSubmitting(false);
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT_V2',
          modalProps: {
            show: true,
            icon: '🎉',
            title: 'Refund Completed!',
            content: (
              <Typography variant="body1" style={{ textAlign: 'center' }}>
                Email confirmation for this refund has been sent to you and your customer for your records.
              </Typography>
            ),
            buttonText: 'Done',
            handleAction: () => history.push('/orders'),
          },
        }),
      );
    } catch (err) {
      setIsSubmitting(false);
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT_V2',
          modalProps: {
            show: true,
            content: 'There was an error submitting the refund.',
            buttonText: 'Close',
          },
        }),
      );
    }
  };

  const onReasonChange = async e => {
    formRef.current.setFieldValue('reason', e.target.value);
  };

  const reviewRefundContent = [
    {
      label: 'Reason for refund',
      text: refundReasonOptions.find(option => option.value === formRef?.current?.values?.reason)?.label,
    },
    { label: 'Refund Total', text: formatCurrency(formRef?.current?.values?.amount) },
    {
      label: 'How the refund is covered',
      text: `${formatCurrency(formRef?.current?.values?.amount)} will be charged to your connected account.`,
    },
    { label: 'Note', text: formRef?.current?.values?.cancel ? 'This order will be marked as canceled.' : '' },
  ].filter(content => !!content.text);

  const refundOrderSchema = yup.object().shape({
    reason: yup.string(),
    amount: yup
      .number()
      .required('Please enter an amount.')
      .test(
        'Is valid amount?',
        `Refund must be greater than $0 and less than ${formattedAmountAvailableToRefund}`,
        value => value > 0 && value <= refundInfo?.refundableTotal,
      ),
    cancel: yup.boolean(),
  });

  return (
    <Formik
      validationSchema={refundOrderSchema}
      initialValues={{
        reason: '',
        amount: 0.0,
        cancel: false,
      }}
      innerRef={formRef}
      onSubmit={handleSubmit}
    >
      {({ errors, setFieldValue, touched, values }: FormikProps<FormValues>) => (
        <AdminForm style={{ width: '100%' }}>
          <ModalWrapper
            paperClass={!isXsMobile && classes.paperClass}
            fullScreen={isXsMobile}
            show={show}
            onClose={handleClose}
          >
            <ModalHeader title={`${_.capitalize(step)} Refund`} handleClose={handleClose} />
            <DialogContent className={classes.scroll}>
              <Grid container item className={classes.content} wrap="nowrap" direction="column">
                <Banner variant="info-blue" noIcon>
                  <Grid container item wrap="nowrap" justify="space-between">
                    <Typography variant="body1">
                      {customer?.fullName()} | {paymentDate}
                    </Typography>
                    <Typography variant="body1">{formatCurrency(totals?.total)}</Typography>
                  </Grid>
                </Banner>
                {step === 'issue' ? (
                  <>
                    <Grid container item>
                      <Typography variant="subtitle2">Reason for Refund (For internal use only)</Typography>
                      <Select
                        options={refundReasonOptions}
                        value={values.reason}
                        onChange={onReasonChange}
                        selectClass={classes.select}
                      />
                    </Grid>
                    <Grid container item>
                      <MoneyInput
                        label="Amount to Refund"
                        name="amount"
                        required
                        error={touched.amount && errors.amount}
                        variant="small"
                        helpText={`${formattedAmountAvailableToRefund} available for refund`}
                        handleOnChange
                      />
                    </Grid>
                    {values.amount === refundInfo?.refundableTotal && (
                      <Grid container item>
                        <Checkbox
                          checked={!!values?.cancel}
                          onChange={() => setFieldValue('cancel', !values?.cancel)}
                          name="cancel"
                          checkboxClass={classes.checkbox}
                          label={
                            <Typography variant="body2">
                              Mark order as canceled. This will automatically change the status of this order to
                              “canceled”.
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  reviewRefundContent.map((content, index) => (
                    <Grid key={`content-${index}`} container item direction="column" wrap="nowrap" style={{ gap: 8 }}>
                      <Typography variant="subtitle1">{content.label}</Typography>
                      <Typography variant="body1">{content.text}</Typography>
                    </Grid>
                  ))
                )}
              </Grid>
            </DialogContent>
            <ModalActions>
              <ButtonV2
                onClick={handleClose}
                variant="outlined"
                style={step === 'review' ? { marginRight: 'auto' } : {}}
              >
                Cancel
              </ButtonV2>
              {step === 'review' && (
                <ButtonV2 onClick={() => setStep('issue')} variant="outlined">
                  Back
                </ButtonV2>
              )}
              <ButtonV2
                disabled={isSubmitting}
                onClick={() => (step === 'issue' ? handleReviewClick(values) : handleSubmit(values))}
                type="submit"
                variant="contained"
              >
                {step === 'issue' ? 'Review Refund' : `Refund ${formatCurrency(values?.amount)}`}
              </ButtonV2>
            </ModalActions>
          </ModalWrapper>
        </AdminForm>
      )}
    </Formik>
  );
};

export default RefundOrderModal;
